.PlaybooksHighlight {
  position: relative;
  display: block;
  overflow: hidden;
}

.PlaybooksHighlight-inner {
  @include xy-grid-container();

  position: relative;
}

.PlaybooksHighlight-header {
  padding: 70px 0;
  @include breakpoint(medium) {
    padding: 90px 0;
  }
  @include breakpoint(large) {
    padding: 110px 0;
  }
}

.PlaybooksHighlight-footer {
  display: flex;
  flex-direction: column;
  @include breakpoint(medium) {
    margin: 60px 0;
  }

  @include breakpoint(large) {
    margin: 100px 0;
  }

  & > * {
    margin: 0 auto;
  }
}

.PlaybooksHighlight-title {
  max-width: 400px;
  margin: 0 auto 15px;
  font-size: rem-calc(30);
  line-height: unitless-calc(30, 30);
  text-align: center;
  color: $mt-deep-purple;

  p {
    @include title-line-breaks();
  }

  @include breakpoint(medium) {
    max-width: 620px;
    margin: 0 auto 20px;
    font-size: rem-calc(34);
  }

  @include breakpoint(large) {
    margin: 0 auto 25px;
    font-size: rem-calc(38);
  }
}

.PlaybooksHighlight-description {
  max-width: 400px;
  margin: 0 auto;
  font-size: rem-calc(18);
  line-height: unitless-calc(24, 18);
  text-align: center;
  color: $mt-deep-purple;

  @include breakpoint(medium) {
    max-width: 620px;
    font-size: rem-calc(20);
  }

  @include breakpoint(large) {
    font-size: rem-calc(24);
    line-height: unitless-calc(30, 24);
  }
}

.PlaybooksHighlight-grid {
  @include breakpoint(medium) {
    display: grid;
    grid-template-rows: repeat(2, auto);
    grid-template-columns: repeat(12, 1fr);
    grid-row-gap: 30px;
    grid-column-gap: 30px;
  }

  @include breakpoint(large) {
    grid-row-gap: 40px;
    grid-column-gap: 40px;
  }
}

.PlaybooksHighlight-card {
  position: relative;
  z-index: 8;
  display: flex;
  justify-content: flex-end;
  @include breakpoint(small down) {
    margin-bottom: 45px;
  }
}

.PlaybooksHighlight-card:nth-child(1) {
  @include breakpoint(medium) {
    grid-area: 1 / 1 / 2 / 7;
  }
  @include breakpoint(large) {
    grid-area: 1 / 1 / 2 / 7;
  }
}

.PlaybooksHighlight-card:nth-child(1)::after {
  position: absolute;
  z-index: 5;
  right: 20px;
  bottom: -10px;
  left: 20px;
  height: 0;
  padding-bottom: 5%;
  content: '';
  transform: perspective(200px) rotateX(40deg);
  background-color: rgba($black, .9);
  filter: blur(20px);
  @include breakpoint(medium down) {
    display: none;
  }
}

.PlaybooksHighlight-card:nth-child(1) .PlaybookCard-title {
  @include breakpoint(large) {
    font-size: rem-calc(38);
  }
}

.PlaybooksHighlight-card:nth-child(1) .PlaybookCard-figure {
  @include breakpoint(large) {
    max-width: 440px;
  }
}

.PlaybooksHighlight-card:nth-child(2) {
  grid-area: 1 / 7 / 2 / 13;
  @include breakpoint(large) {
    grid-area: 1 / 7 / 2 / 13;
  }
}

.PlaybooksHighlight-card:nth-child(2) .PlaybookCard-figure {
  @include breakpoint(large) {
    max-width: 400px;
  }
}

.PlaybooksHighlight-card:nth-child(3) {
  grid-area: 2 / 1 / 3 / 7;
  @include breakpoint(large) {
    grid-area: 2 / 1 / 3 / 5;
  }
}

.PlaybooksHighlight-card:nth-child(4) {
  grid-area: 2 / 7 / 3 / 13;
  @include breakpoint(large) {
    grid-area: 2 / 5 / 3 / 9;
  }
}

.PlaybooksHighlight-card:nth-child(5) {
  grid-area: 3 / 4 / 4 / 10;
  @include breakpoint(large) {
    grid-area: 2 / 9 / 3 / 13;
  }
}

.PlaybooksHighlight-card .PlaybookCard {
  border-radius: 10px;

  @include breakpoint(small down) {
    max-width: 400px;
    margin: 0 auto;
  }
  @include breakpoint(large) {
    z-index: 10;
    margin-top: auto;
  }
}

.PlaybooksHighlight-card .PlaybookCard-inner {
  // padding: 30px;
  @include breakpoint(medium) {
    padding-top: 35px;
    padding-bottom: 35px;
  }
  @include breakpoint(large) {
    padding: 50px;
  }
}

.PlaybooksHighlight-card .PlaybookCard-header {
  @include breakpoint(large) {
    margin: 0 0 30px;
  }
}

.PlaybooksHighlight-card .PlaybookCard-tag {
  @include breakpoint(large) {
    margin: 0 10px auto 0;
    font-size: rem-calc(16);
  }
}

.PlaybooksHighlight-card .PlaybookExcerpt {
  @include breakpoint(large) {
    padding: 4px 0 0;
    font-size: rem-calc(18);
  }
}

.PlaybooksHighlight-card .PlaybookCard-title {
  @include breakpoint(large) {
    font-size: rem-calc(28);
  }
}

.PlaybooksHighlight-card .PlaybookCard-author {
  @include breakpoint(large) {
    margin-bottom: 50px;
  }
}

.PlaybooksHighlight-card .PlaybookCard-authorName {
  @include breakpoint(large) {
    font-size: rem-calc(16);
  }
}

.PlaybooksHighlight-card .PlaybookCard-authorInfo {
  @include breakpoint(large) {
    font-size: rem-calc(16);
  }
}

.PlaybooksHighlight-card .PlaybookCard-authorAvatar,
.PlaybooksHighlight-card .PlaybookCard-authorAvatar .Avatar {
  @include breakpoint(large) {
    width: 60px;
    height: 60px;
  }
}
