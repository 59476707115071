.InputCheckbox {
  @include font-futura-book();

  display: flex;
  align-items: center;
  margin: 20px 0 15px;
  font-size: rem-calc(18);
  line-height: unitless-calc(26, 18);
  text-align: left;
  color: $gray-alpha-dark;
  border-bottom: none;
  transition: color 150ms ease-in-out;

  &:hover {
    cursor: pointer;
    color: $mt-electric-purple;

    span {
      &::after {
        @include font-futura-demi();

        position: absolute;
        top: -4px;
        left: 5px;
        content: 'L';
        transform: matrix(-.71, -.71, -.71, .71, 0, 0);
        font-size: rem-calc(16);
        color: $mt-celeste;
      }
    }
  }

  input {
    display: none;
    width: auto;
  }

  span {
    position: relative;
    overflow: hidden;
    width: 16px;
    min-width: 16px;
    height: 16px;
    margin-right: 15px;
    border: solid 2px $mt-celeste;
    border-radius: 3px;
    background-color: $white;
  }

  input:checked + span {
    &::after {
      @include font-futura-demi();

      position: absolute;
      top: -4px;
      left: 5px;
      content: 'L';
      transform: matrix(-.71, -.71, -.71, .71, 0, 0);
      font-size: rem-calc(16);
      color: $mt-electric-purple;
    }
  }
}
