.Button {
  --Button-color: #{$white};
  --Button-label-color: #{$white};
  --Button-hover-color: #{$mt-electric-purple};

  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  padding: 0 30px; // will there be cases when the button should fill out width of container?
  cursor: pointer;
  text-decoration: none;

  &:hover {
    .Button-label,
    .Button-labelIcon {
      color: var(--Button-hover-color);
    }

    .Button-outline {
      stroke: var(--Button-hover-color);
    }
  }
}

.Button-shape {
  position: absolute;
}

.Button-bg {
  fill: none;
  stroke: none;
}

.Button-outline {
  visibility: hidden;
  fill: none;
  stroke: none;
  stroke-width: 4px;
  transition: stroke .15s;

  &.is-visible {
    display: block;
    stroke: var(--Button-color);
  }
}

.Button-label {
  @include font-futura-demi();

  z-index: 1;
  display: inline-block;
  user-select: none;
  font-size: rem-calc(18);
  text-align: center;
  pointer-events: none;
  color: var(--Button-label-color);
  transition: color .15s;

  &.Button-label--withIcon {
    margin-right: -5px;
  }
}

.Button-labelIcon {
  width: 7px;
  height: 13px;
  padding-left: 8px;
  transform: translateY(2px);
  color: var(--Button-label-color);
  transition: color .15s;
}

//
// Color Modifiers
//
.Button.Button--white {
  --Button-color: #{$white};
  --Button-label-color: #{$white};
  --Button-hover-color: #{$mt-electric-purple};
}

.Button.Button--semiBlack {
  --Button-color: #{rgba($black, .5)};
  --Button-label-color: #{rgba($black, .5)};
  --Button-hover-color: #{$mt-deep-purple};
}

.Button.Button--deepPurple {
  --Button-color: #{$mt-deep-purple};
  --Button-label-color: #{$mt-deep-purple};
  --Button-hover-color: #{$mt-electric-purple};
}

.Button.Button--electricPurple {
  --Button-color: #{$mt-electric-purple};
  --Button-label-color: #{$mt-electric-purple};
  --Button-hover-color: #{$mt-deep-purple};
}

.Button.Button--frostBlue {
  --Button-color: #{$mt-frost-blue};
  --Button-label-color: #{$mt-frost-blue};
  --Button-hover-color: #{$mt-electric-purple};
}

.Button.Button--whiteFill {
  --Button-color: #{$white};
  --Button-label-color: #{$mt-deep-purple};
  --Button-hover-color: #{$mt-electric-purple};

  .Button-bg {
    fill: var(--Button-color);
  }

  .Button-outline {
    stroke: none;
  }
}

.Button.Button--electricPurpleFill {
  --Button-color: #{$mt-electric-purple};
  --Button-label-color: #{$white};
  --Button-hover-color: #{$mt-deep-purple};

  .Button-bg {
    fill: var(--Button-color);
  }

  .Button-outline {
    stroke: none;
  }
}

.Button.Button--deepPurpleFill {
  --Button-color: #{$mt-deep-purple};
  --Button-label-color: #{$white};
  --Button-hover-color: #{$mt-electric-purple};

  .Button-bg {
    fill: var(--Button-color);
  }

  .Button-outline {
    stroke: none;
  }
}
