.Form {
  position: relative;
  display: flex;
  align-items: center;
  width: 100vw;
  min-height: 100vh;
  margin-top: -120px;
  background-color: $mt-frost-blue;

  @include breakpoint(medium) {
    height: 100vh;
  }
  @include breakpoint(large) {
    margin-top: 0;
  }

  h2 {
    margin-bottom: 50px;
    font-family: $font-colfax-bold;
    font-size: rem-calc(28);
    line-height: unitless-calc(30, 28);
    color: $mt-deep-purple;

    @include breakpoint(large) {
      font-size: rem-calc(36);
      line-height: unitless-calc(40, 36);
    }

    span {
      color: $mt-electric-purple;
    }
  }
}

.Form-logo {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  height: 60px;
  color: $mt-electric-purple;
  transition: color 150ms ease-in-out;

  @include breakpoint(large) {
    height: 120px;
  }

  svg {
    width: 54px;
    height: 26px;
    padding-left: 30px;

    @include breakpoint(large) {
      width: 80px;
      height: 40px;
      padding-left: 2.5rem;
    }
  }

  &:hover {
    color: $mt-deep-purple;
  }
}

.Form-inner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 1280px;
  height: 100%;
  margin: 0 30px;

  @include breakpoint(large) {
    margin: 0 auto;
  }
}

.Form-content {
  width: 100%;

  @include breakpoint(375px down) {
    margin-top: 100px;
  }
  @include breakpoint(medium) {
    max-width: 60%;
  }
  @include breakpoint(large) {
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: repeat(12, 1fr);
    grid-row-gap: 0;
    grid-column-gap: 40px;
    max-width: none;
    margin: 0 auto 0;
  }
}

.Form-footer {
  position: relative;
  z-index: 10;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: calc(100%);
  max-width: $global-width;
  min-height: 60px;
  padding-top: 30px;

  @include breakpoint(medium) {
    position: absolute;
    width: calc(100% - 80px);
    margin: 0 40px;
  }
  @include breakpoint(large) {
    position: absolute;
    left: 50%;
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: repeat(12, 1fr);
    grid-row-gap: 0;
    grid-column-gap: 40px;
    width: 100%;
    margin: 0 auto;
    transform: translateX(-50%);
  }

  li {
    display: flex;
    align-items: center;
    margin-bottom: 30px;

    @include breakpoint(large) {
      justify-content: flex-start;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  li:nth-child(1) {
    grid-area: 1 / 1 / 2 / 4;

    @include breakpoint(large) {
      padding-left: 40px;
    }
  }

  li:nth-child(2) {
    grid-area: 1 / 4 / 2 / 10;
  }

  li:nth-child(3) {
    grid-area: 1 / 10 / 2 / 13;
    justify-content: flex-end;
  }
}

.Form-step {
  grid-area: 1 / 4 / 2 / 10;
}

.Form-navigation {
  @include font-futura-demi();

  margin-top: 20px;
  transform: translateY(10px);
  font-size: rem-calc(16);
  opacity: 0;
  color: $mt-half-baked;
  transition: opacity 150ms ease-in-out, transform 300ms ease-in-out;

  @include breakpoint(medium) {
    margin-top: 50px;
  }
  @include breakpoint(large) {
    font-size: rem-calc(18);
  }
}

.Form-navigationEnter {
  margin-left: 20px;

  @include breakpoint(small only) {
    display: none;
  }
}

.Form-next,
.Form-previous {
  @include font-futura-demi();

  font-size: rem-calc(16);
  transition: background-color 150ms ease-in-out, color 150ms ease-in-out;

  @include breakpoint(large) {
    font-size: rem-calc(18);
  }

  svg {
    width: 9px;
    height: 14px;
  }
}

.Form-previous {
  display: flex;
  align-items: center;
  color: $mt-half-baked;

  svg {
    margin-right: 10px;
  }

  &:hover {
    cursor: pointer;
    color: $mt-deep-purple;
  }
}

.Form-next {
  display: inline-flex;
  align-items: center;
  padding: 15px;
  color: $white;
  background-color: $mt-electric-purple;

  @include breakpoint(large) {
    padding: 20px 25px;
  }

  svg {
    margin-left: 10px;
  }

  &:hover {
    cursor: pointer;
    background-color: $mt-deep-purple;
  }
}

.Form.is-complete {
  height: auto;
  min-height: 100vh;
  background-color: $white;

  .Form-step {
    padding-top: 140px;
  }

  @include breakpoint(medium) {
    .Form-step {
      max-width: 60vw;
    }
  }

  .Form-navigation {
    display: none;
  }
}

.Form-calendar {
  display: none;
  width: 100%;
  height: 850px;

  @include breakpoint(medium) {
    height: 950px;
  }
  @include breakpoint(xlarge) {
    height: 750px;
  }

  &.is-visible {
    display: block;
  }
}

.Form-navigation.is-visible {
  transform: translateY(0);
  opacity: 1;
}
