.HomeLogos {
  @include xy-grid-container();

  color: $mt-electric-purple;
}

.HomeLogos-inner {
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: repeat(12, 1fr);
  grid-column-gap: 0;
  padding: 40px 0;
  background-color: $mt-cararra;

  @include breakpoint(large) {
    grid-column-gap: 40px;
    padding: 100px 0;
  }
}

.HomeLogos-container {
  grid-area: 1 / 1 / 1 / -1;
  padding: 0 30px;

  @include breakpoint(large) {
    grid-area: 1 / 2 / 1 / 11;
    padding: 0;
  }
}

// generic spacing between elements
.HomeLogos-title,
.HomeLogos-description,
.HomeLogos-caseLogos {
  margin-bottom: 30px;

  @include breakpoint(large) {
    margin-bottom: 40px;
  }
}

.HomeLogos-title {
  max-width: 450px;
  font-size: rem-calc(28);
  line-height: unitless-calc(24, 28);
  text-transform: uppercase;
  color: $mt-electric-purple;

  @include breakpoint(large) {
    font-size: rem-calc(30);
    line-height: unitless-calc(26, 30);
  }

  @include breakpoint(xlarge) {
    max-width: 500px;
    font-size: rem-calc(36);
    line-height: unitless-calc(30, 36);
  }
}

.HomeLogos-description {
  @include font-futura-demi();

  margin-bottom: 0;
  font-size: rem-calc(18);
}

.HomeLogos-caseLogos {
  display: inline-flex;
  flex-wrap: wrap;
  width: 100%;

  @include breakpoint(small) {
    max-width: 400px;
  }

  @include breakpoint(medium) {
    max-width: 400px;
    margin-right: 300px;
  }

  @include breakpoint(large) {
    max-width: 600px;
    margin-right: 20px;
    margin-bottom: 60px;
  }

  @include breakpoint(xlarge) {
    max-width: 800px;
  }

  @include breakpoint(xxlarge) {
    max-width: 800px;
  }

  &::after {
    clear: both;
    content: ' ';
  }
}

.HomeLogos-logo {
  margin-top: 30px;
  margin-right: 20px;
  opacity: 1;
  transition: opacity 150ms ease-in-out;

  @include breakpoint(large) {
    margin-top: 30px;
    margin-right: 30px;
  }

  @include breakpoint(xlarge) {
    margin-top: 40px;
    margin-right: 40px;
  }
}

.HomeLogos-logoImage {
  height: 20px;

  @include breakpoint(large) {
    height: 26px;
  }

  @include breakpoint(xlarge) {
    height: 40px;
  }
}

.HomeLogos-buttonContainer {
  display: inline-flex;
}
