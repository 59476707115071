.HomeHeader {
  position: relative;
  display: flex;
  overflow: hidden;
  align-items: center;
  width: 100vw;
  height: 75vh;
  margin-bottom: -60px;
  transform: translateY(-60px);
  background: $mt-deep-purple;

  // small devices only
  @include breakpoint(320px down) {
    height: 100vh;
  }

  @include breakpoint(small down) {
    min-height: calc(100vh - 132px);
  }

  @include breakpoint(medium only) {
    min-height: 515px;
  }

  @include breakpoint(large) {
    height: 100vh;
    margin-bottom: -120px;
    transform: translateY(-120px);
  }
}

.HomeHeader-inner {
  @include xy-grid-container();

  position: relative;
  display: block;
  width: 100%;
}

.HomeHeader-titleContainer {
  margin-top: 20px;
  margin-bottom: 20px;

  @include breakpoint(medium) {
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: repeat(12, 1fr);
    grid-row-gap: 0;
    grid-column-gap: 40px;
    margin: 30px 0 0;
  }

  @include breakpoint(large) {
    margin: 0;
  }
}

.HomeHeader-titleWrapper {
  @include breakpoint(medium) {
    grid-area: 1 / 1 / 2 / 13;
    margin-bottom: 35px;
  }

  @include breakpoint(large) {
    grid-area: 1 / 1 / 2 / 11;
    margin-bottom: 35px;
  }
  @include breakpoint(xlarge) {
    grid-area: 1 / 2 / 2 / 10;
    margin-bottom: 40px;
  }
}

.HomeHeader-title {
  margin: 0;
  font-size: rem-calc(40);
  line-height: unitless-calc(34, 40);
  text-transform: uppercase;
  color: $mt-deep-purple;

  &.HomeHeader-title--electricPurple {
    color: $mt-electric-purple;
  }

  @include breakpoint(medium only) {
    max-width: 600px;
  }

  @include breakpoint(medium) {
    font-size: rem-calc(50);
    line-height: unitless-calc(46, 50);
  }

  @include breakpoint(large) {
    max-width: 740px;
    font-size: rem-calc(60);
    line-height: unitless-calc(56, 60);
  }
  @include breakpoint(xlarge) {
    font-size: rem-calc(68);
    line-height: unitless-calc(62, 68);
  }
}

.HomeHeader-title.HomeHeader-title--abmCampaign {
  line-height: unitless-calc(85, 90);
  color: $white;
}

.HomeHeader-num {
  color: $mt-deep-purple;
}

.HomeHeader-requestInviteContainer {
  position: relative;

  @include breakpoint(medium) {
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: repeat(12, 1fr);
    grid-row-gap: 0;
    grid-column-gap: 30px;
    margin-bottom: 100px;

    > *:nth-child(1) {
      grid-area: 1 / 1 / 2 / 11;
    }
  }

  @include breakpoint(large) {
    grid-column-gap: 40px;
    margin-bottom: 40px;

    > *:nth-child(1) {
      grid-area: 1 / 1 / 2 / 10;
      max-width: none;
    }
  }

  @include breakpoint(xlarge) {
    margin-bottom: 80px;

    > *:nth-child(1) {
      grid-area: 1 / 2 / 2 / 10;
    }
  }
}

.HomeHeader-scheduleDemoContainer {
  margin-top: 30px;

  & > a {
    display: inline-flex;
    max-width: 200px;
  }

  @include breakpoint(xlarge) {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-row-gap: 0;
    grid-column-gap: 40px;

    & > a {
      grid-area: 1 / 2 / 2 / 5;
    }
  }
}

.HomeHeader-patternContainer {
  position: absolute;
  z-index: -1;
  bottom: 0;
  left: 30px;
  width: 100vw;
  height: 10vh;
  opacity: 0;

  @include breakpoint(small only) {
    max-height: 70px;
  }

  @include breakpoint(medium) {
    top: 0;
    right: 0;
    left: auto;
    width: 33vw;
    height: 100vh;
  }

  @include breakpoint(large) {
    width: 25vw;
  }
}

.HomeHeader.HomeHeader--campaign {
  height: auto;
  padding: 60px 0;

  @include breakpoint(large) {
    padding: 180px 0 100px 0;
  }
}
