
// Foundation for Sites
// https://get.foundation
// Licensed under MIT Open Source

@import './breakpoint';
@import './xy-grid';

@mixin title-line-breaks {
  margin-bottom: 0;

  @include breakpoint(small down) {
    display: inline;

    &::before {
      content: "\00a0 ";
    }

    &:first-child {
      content: "\00a0 ";

      &::before {
        display: none;
      }
    }
  }
}
