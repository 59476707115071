.BookingWidgetWrapper {
  position: relative;
  overflow: hidden;
  height: 750px;
  padding: 40px 0 0;

  @include breakpoint(small) {
    padding: 40px 0 40px;
  }

  @include breakpoint(medium) {
    height: 950px;
    padding: 80px 0 50px;
  }

  @include breakpoint(xlarge) {
    height: 750px;
    padding: 80px 0 100px;
  }
}

.BookingWidgetWrapper--abmPage {
  height: auto;
  background-color: $mt-cararra;
  padding-top: 50px;

  @include breakpoint(medium) {
    padding-top: 65px;
  }

  @include breakpoint(xlarge) {
    padding-top: 80px;
  }

  .BookingWidgetWrapper-inner {
    padding-left: 0px;
    padding-right: 0px;
  }

  .BookingWidgetWrapper-title {
    margin-bottom: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 50px;

    @include breakpoint(medium) {
      width: 100%;
      margin-bottom: 30px;
    }

    b {
      color: $mt-electric-purple;
    }
  }    

  .BookingWidget {
    iframe {
      height: 100%;
      width: 100%;
    }

    position: relative;
    height: 1100px;
    width: 85vw;
    margin: auto;

    @include breakpoint(medium) {
      width: 100vw;
    }

    @include breakpoint(large) {
      height: 750px;
    }

    @include breakpoint(1280px) {
      margin-left: calc((100vw - 1280px) / 2 * -1);
    }
  }
}

.BookingWidgetWrapper-inner {
  @include xy-grid-container();

  height: 100%;
}

.BookingWidgetWrapper-title {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 50px;
  font-size: rem-calc(26);
  color: $mt-deep-purple;
  text-align: center;

  @include breakpoint(medium) {
    width: 100%;
    margin-bottom: 30px;
  }

  @include breakpoint(large) {
    grid-area: 1 / 3 / 2 / 11;
    font-size: rem-calc(36);
  }

  b {
    color: $mt-electric-purple;
  }
}

.BookingWidgetWrapper-text {
  @include breakpoint(large) {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-row-gap: 0;
    grid-column-gap: 40px;
  }
}
