.StoriesBlock {
  position: relative;
  display: block;
  overflow: hidden;
  padding-top: 45px;
  background-color: $mt-pampas-beige;

  @include breakpoint(medium) {
    padding-top: 30px;
  }

  @include breakpoint(large) {
    padding-top: 45px;
  }

  @include breakpoint(xlarge) {
    padding-top: 70px;
  }
}

.StoriesBlock-inner {
  @include xy-grid-container();

  position: relative;
}

.StoriesBlock-grid {
  display: grid;
  grid-template-rows: repeat(2, auto);
  grid-template-columns: repeat(12, 1fr);
  grid-row-gap: 0;
  grid-column-gap: 9px;

  @include breakpoint(medium) {
    grid-column-gap: 30px;
  }

  @include breakpoint(large) {
    // grid-row-gap: 40px;
    grid-column-gap: 40px;
  }
}

.StoriesBlock-header {
  z-index: 2;
  grid-area: 1 / 1 / 2 / 13;
  padding-bottom: 30px;
  color: $mt-electric-purple;

  @include breakpoint(medium) {
    grid-area: 1 / 1 / 2 / 7;
    padding-bottom: 30px;
  }

  @include breakpoint(large) {
    grid-area: 1 / 2 / 2 / 7;
    padding-bottom: 70px;
  }
}

.StoriesBlock-pattern {
  position: relative;
  z-index: 1;
  grid-area: 2 / 6 / 4 / 13;
  width: calc(100% + 30px);
  height: 100%;

  @include breakpoint(medium) {
    grid-area: 1 / 7 / 4 / 13;
    width: calc(100% + 40px);
  }

  @include breakpoint(1360px) {
    width: calc(100% + ((100vw - 1280px) / 2));
  }
  // margin-right: calc((((100vw - 1280px) / 2) * -1) + 0px);
}

.StoriesBlock-title {
  max-width: 400px;
  margin: 0 auto 15px;
  font-size: rem-calc(30);
  line-height: unitless-calc(30, 30);
  text-align: left;
  color: $mt-deep-purple;

  @include breakpoint(medium) {
    max-width: 620px;
    margin: 0 auto 20px;
    font-size: rem-calc(28);
  }

  @include breakpoint(large) {
    margin: 0 auto 25px;
    font-size: rem-calc(38);
  }

  p {
    margin: 0;
  }
}

.StoriesBlock-description {
  max-width: 400px;
  margin: 0 auto;
  font-size: rem-calc(18);
  line-height: unitless-calc(24, 18);
  text-align: left;
  color: $mt-deep-purple;

  @include breakpoint(medium) {
    max-width: 250px;
    margin: 0 auto 0 0;
    font-size: rem-calc(16);
  }

  @include breakpoint(large) {
    max-width: 400px;
    font-size: rem-calc(18);
    line-height: unitless-calc(26, 18);
  }
}

.StoriesBlock-story {
  display: contents;
  background-color: $white;

  img {
    max-width: 100%;
    height: auto;
  }
}

.StoriesBlock-storyLink {
  width: 100%;
  max-width: 460px;
  margin: 0 auto;
  margin-bottom: 50px;
  color: $mt-electric-purple;
  transition: color 150ms ease-in-out;

  @include breakpoint(medium) {
    width: auto;
    max-width: none;
    margin-right: 0;
    margin-bottom: 40px;
    margin-left: 0;
  }

  @include breakpoint(800px) {
    margin-bottom: 20px;
  }
}

.StoriesBlock-storyLink:hover {
  color: $mt-deep-purple;
}

.StoriesBlock-story:nth-child(3) .StoriesBlock-storyLink {
  position: relative;
  z-index: 5;
  grid-area: 2 / 1 / 3 / 13;

  @include breakpoint(medium) {
    grid-area: 1 / 7 / 3 / 13;
  }
}

.StoriesBlock-story:nth-child(2) .StoriesBlock-storyLink {
  position: relative;
  z-index: 5;
  grid-area: 3 / 1 / 4 / 13;
  @include breakpoint(medium) {
    grid-area: 2 / 1 / 4 / 7;
  }
}

.StoriesBlock-storyHeader {
  padding: 50px 30px;
  background-color: $white;

  @include breakpoint(medium) {
    padding: 40px calc(((#{100vw} - (13 * 30px)) / 12)) 30px;
  }
  @include breakpoint(large) {
    padding: 65px calc(((#{100vw} - (13 * 40px)) / 12)) 55px;
  }

  @include breakpoint($global-width) {
    padding: 65px calc(((#{$global-width} - (11 * 40px)) / 12)) 55px;
  }
}

.StoriesBlock-storyImage img {
  display: block;
}

.StoriesBlock-storyTitle {
  max-width: 300px;
  margin: 0 auto 15px 0;
  font-size: rem-calc(28);
  line-height: unitless-calc(30, 28);
  text-align: left;

  @include breakpoint(medium) {
    margin: 0 auto 15px 0;
    font-size: rem-calc(18);
  }

  @include breakpoint(800px) {
    margin: 0 auto 15px 0;
    font-size: rem-calc(24);
  }

  @include breakpoint(large) {
    margin: 0 auto 20px 0;
    font-size: rem-calc(28);
  }

  @include breakpoint(xlarge) {
    margin: 0 auto 30px 0;
  }
}

.StoriesBlock-storyDescription {
  @include font-futura-demi();

  display: block;
  margin: 0;
  font-size: rem-calc(16);
  line-height: unitless-calc(25, 16);

  @include breakpoint(800px) {
    font-size: rem-calc(18);
    line-height: unitless-calc(25, 18);
  }
}

.StoriesBlock-arrow {
  width: 7px;
  height: 13px;
  padding-left: 8px;
  transform: translateY(2px);
  @include breakpoint(medium) {
    padding-left: 3px;
    transform: translateY(2px);
  }

  @include breakpoint(800px) {
    padding-left: 8px;
    transform: translateY(2px);
  }
}
