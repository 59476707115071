// Imported trough _Document.js -> <Head>
// Add Futura from Momkai Adobe Cloud subscription
// @import url("https://use.typekit.net/cvr8orq.css");

// Not used in current components, can be enabled if relevant, disabled for now
// /* Colfax Web Thin */
// @font-face {
//   font-family: 'ColfaxWeb Thin';
//   font-weight: 200;
//   font-style: normal;
//   font-display: block;
//   src: url('/fonts/colfax/ColfaxWebThin/ColfaxWebThin.eot');
//   src:
//     url('/fonts/colfax/ColfaxWebThin/ColfaxWebThin.eot?#iefix') format('embedded-opentype'),
//     url('/fonts/colfax/ColfaxWebThin/ColfaxWebThin.woff2') format('woff2'),
//     url('/fonts/colfax/ColfaxWebThin/ColfaxWebThin.woff') format('woff');
// }

// /* Colfax Web Thin Italic */
// @font-face {
//   font-family: 'ColfaxWeb Thin Italic';
//   font-weight: 200;
//   font-style: italic;
//   font-display: block;
//   src: url('/fonts/colfax/ColfaxWebThinItalic/ColfaxWebThinItalic.eot');
//   src:
//     url('/fonts/colfax/ColfaxWebThinItalic/ColfaxWebThinItalic.eot?#iefix') format('embedded-opentype'),
//     url('/fonts/colfax/ColfaxWebThinItalic/ColfaxWebThinItalic.woff2') format('woff2'),
//     url('/fonts/colfax/ColfaxWebThinItalic/ColfaxWebThinItalic.woff') format('woff');
// }

// Colfax Web Regular
@font-face {
  font-family: 'ColfaxWeb Regular';
  font-weight: 400;
  font-style: normal;
  font-display: block;
  src: url('/fonts/colfax/ColfaxWebRegular/ColfaxWebRegular.eot');
  src:
    url('/fonts/colfax/ColfaxWebRegular/ColfaxWebRegular.eot?#iefix') format('embedded-opentype'),
    url('/fonts/colfax/ColfaxWebRegular/ColfaxWebRegular.woff2') format('woff2'),
    url('/fonts/colfax/ColfaxWebRegular/ColfaxWebRegular.woff') format('woff');
}

// Colfax Web Regular Italic
@font-face {
  font-family: 'ColfaxWeb Regular Italic';
  font-weight: 400;
  font-style: italic;
  font-display: block;
  src: url('/fonts/colfax/ColfaxWebRegularItalic/ColfaxWebRegularItalic.eot');
  src:
    url('/fonts/colfax/ColfaxWebRegularItalic/ColfaxWebRegularItalic.eot?#iefix') format('embedded-opentype'),
    url('/fonts/colfax/ColfaxWebRegularItalic/ColfaxWebRegularItalic.woff2') format('woff2'),
    url('/fonts/colfax/ColfaxWebRegularItalic/ColfaxWebRegularItalic.woff') format('woff');
}

// Colfax Web Bold
@font-face {
  font-family: 'ColfaxWeb Bold';
  font-weight: 700;
  font-style: normal;
  font-display: block;
  src: url('/fonts/colfax/ColfaxWebBold/ColfaxWebBold.eot');
  src:
    url('/fonts/colfax/ColfaxWebBold/ColfaxWebBold.eot?#iefix') format('embedded-opentype'),
    url('/fonts/colfax/ColfaxWebBold/ColfaxWebBold.woff2') format('woff2'),
    url('/fonts/colfax/ColfaxWebBold/ColfaxWebBold.woff') format('woff');
}

// Colfax Web Bold Italic
@font-face {
  font-family: 'ColfaxWeb Bold Italic';
  font-weight: 700;
  font-style: italic;
  font-display: block;
  src: url('/fonts/colfax/ColfaxWebBoldItalic/ColfaxWebBoldItalic.eot');
  src:
    url('/fonts/colfax/ColfaxWebBoldItalic/ColfaxWebBoldItalic.eot?#iefix') format('embedded-opentype'),
    url('/fonts/colfax/ColfaxWebBoldItalic/ColfaxWebBoldItalic.woff2') format('woff2'),
    url('/fonts/colfax/ColfaxWebBoldItalic/ColfaxWebBoldItalic.woff') format('woff');
}
