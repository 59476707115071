@use "sass:math";
// Variables

// Webfonts variables
$global-font-size: 16px;
$font-futura: 'futura-pt', 'Arial', sans-serif;
$font-colfax-bold: 'ColfaxWeb Bold', 'Arial', sans-serif;
$font-colfax-bold-italic: 'ColfaxWeb Bold Italic', 'Proxima Nova Bold', 'Helvetica', 'Arial', sans-serif;
$font-colfax-regular: 'ColfaxWeb Regular', 'Arial', sans-serif;
$font-colfax-regular-italic: 'ColfaxWeb Regular Italic', 'Arial', sans-serif;
$font-colfax-thin: 'ColfaxWeb Thin', 'Arial', sans-serif;
$font-colfax-thin-italic: 'ColfaxWeb Thin Italic', 'Arial', sans-serif;
$font-proxima-bold: 'Proxima Nova Bold', 'Helvetica', 'Arial', sans-serif;
$font-weight-bold: 700;
$font-weight-regular: 400;
// $font-weight-thin: 200;
$font-headings: $font-colfax-bold;
$font-body-text: $font-colfax-regular;

// Needed to add has mixins since we need to set different weight and styles for Futura Demi & Futura Demi Oblique
@mixin font-futura-book {
  font-family: $font-futura;
  font-weight: 400;
  font-style: normal;
}

@mixin font-futura-book-oblique {
  font-family: $font-futura;
  font-weight: 400;
  font-style: italic;
}

@mixin font-futura-demi {
  font-family: $font-futura;
  font-weight: 600;
  font-style: normal;
}

@mixin font-futura-demi-oblique {
  font-family: $font-futura;
  font-weight: 600;
  font-style: italic;
}

// Colors
$black: #000000;
$white: #FFFFFF;
$gray-alpha-light: #B2B2B2;
$gray-alpha-dark: #2A2F3A;
$red-error: #CE0000;

// Brand colors
$mt-deep-purple: #3A0068;
$mt-pigment-purple: #4B007D;
$mt-electric-purple: #AA00FF;
$mt-frost-blue: #CFF6FF;

// Other colous used in design
// To add new colors get your names from http://chir.ag/projects/name-that-color/
$mt-indigo-purple: #4D0081;
$mt-violet-purple: #8500CD;
$mt-seance-purple: #B92CFF;
$mt-muave-purple: #B82CFF;
$mt-heart-purple: #9701E2;
$mt-cararra: #E7E6DF;
$mt-lily-white: #E7FAFF;
$mt-alto: #D8D8D8;
$mt-celeste: #D0CFC6;
$mt-periwinkle-gray: #B1C4E1;
$mt-powder-blue: #B1D9E2;
$mt-twilight-blue: #EAFBFF;
$mt-half-baked: #81C3D2;
$mt-blizzard-blue: #ABE1EE;
$mt-tolopea-purple: #250042;
$mt-iceberg-blue: #D8ECF1;
$mt-pampas-beige: #F4F4EE;

// Grid values
$global-width: 1280px;
$grid-container: $global-width;
$grid-column-gutter: (
  small: 60px,
  large: 80px,
);
$grid-margin-gutters: (
  small: 60px,
  large: 80px
);
$grid-padding-gutters: $grid-margin-gutters;
$grid-container-padding: $grid-padding-gutters;
$grid-container-max: $global-width;

$gutter-small: math.div(map-get($grid-column-gutter, small), 2);
$gutter-large: math.div(map-get($grid-column-gutter, large), 2);

// Internal variables used for text direction
$global-text-direction: ltr;
$global-left: if($global-text-direction == rtl, right, left);
$global-right: if($global-text-direction == rtl, left, right);

// Override settings of breakpoints for Foundation mixins
$breakpoints: (
  small: 0,
  medium: 640px,
  large: 1024px,
  xlarge: 1200px,
  xxlarge: 1440px,
) !default;
