.ModalForm {
  .InputText {
    margin: 15px 0;
    padding: 10px 0;
    border-bottom: solid 2px $gray-alpha-light;

    input,
    span {
      font-size: rem-calc(18);
      line-height: unitless-calc(24, 18);
    }

    input {
      &::placeholder {
        color: $gray-alpha-light;
      }
    }

    &::after {
      bottom: -2px;
      height: 2px;
      content: ' ';
      background-color: $mt-electric-purple;
    }

    &.is-invalid {
      border-bottom: solid 2px red !important;

      &::after {
        bottom: -2px;
        height: 2px;
        content: ' ';
        background-color: $red-error;
      }
    }
  }
}

.Button-wrapper.is-disabled {
  visibility: hidden;
  margin-top: -22px;

  .Button-shape {
    display: none;
  }

  .Button {
    margin-top: 30px;
  }

  .Button-link {
    display: flex;
    justify-content: center;
  }
}

.ModalForm-page {
  display: flex;
  flex-direction: column;
}

.ModalForm-title {
  margin-bottom: 30px;
  font-size: rem-calc(38);
  line-height: unitless-calc(38, 38);
  text-align: center;
  color: $mt-deep-purple;
  @include breakpoint(medium down) {
    margin-top: 10px;
    font-size: 24px;
  }
}

.ModalForm-subtitle {
  @include font-futura-demi();

  display: block;
  margin-bottom: 20px;
  font-size: rem-calc(18);
  line-height: unitless-calc(26, 18);
  text-align: center;
  color: $gray-alpha-dark;
}

.ModalForm-illustration {
  display: flex;
  justify-content: center;
  margin-bottom: 25px;
}

.ModalForm-graphic {
  height: 36px;
}

.ModalForm-graphic--large {
  width: 173px;
  height: 112px;
}

.ModalForm-image {
  width: 87px;
  height: 87px;
  margin: 0 auto 40px;
}
