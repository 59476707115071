.PropositionBlock {
  position: relative;
  z-index: 1;
  display: block;
  overflow: hidden;
  background-color: $mt-pampas-beige;

  &::before {
    position: absolute;
    top: 0;
    bottom: auto;
    left: 0;
    display: none;
    width: 50vw;
    height: 80px;
    content: '';
    background-color: $mt-frost-blue;
    @include breakpoint(medium) {
      display: block;
    }
  }
}

.PropositionBlock-pattern {
  display: none;

  @include breakpoint(medium) {
    position: absolute;
    top: 0;
    right: 0;
    left: 50vw;
    display: block;
    width: 50vw;
    height: 100%;
  }
}

.PropositionBlock-inner {
  @include xy-grid-container();

  position: relative;
  z-index: 10;
}

.PropositionBlock-wrapper {
  display: grid;
  grid-template-rows: repeat(2, auto);
  grid-template-columns: repeat(12, 1fr);
  grid-row-gap: 0;
  grid-column-gap: 9px;

  @include breakpoint(medium) {
    grid-column-gap: 30px;
  }

  @include breakpoint(large) {
    grid-column-gap: 40px;
  }
}

.PropositionBlock-content {
  grid-area: 1 / 1 / 2 / 13;
  margin: 0 -30px;
  padding: 50px 30px 30px;
  background-color: $white;

  @include breakpoint(medium) {
    margin: 0;
    padding: 20px 0;
  }

  @include breakpoint(large) {
    padding: 30px 0;
  }

  @include breakpoint(xlarge) {
    padding: 40px 0;
  }
}

.PropositionBlock-contentGrid {
  display: grid;
  grid-template-rows: repeat(2, auto);
  grid-template-columns: repeat(12, 1fr);
  grid-row-gap: 0;
  grid-column-gap: 9px;

  @include breakpoint(medium) {
    grid-row-gap: 30px;
    grid-column-gap: 30px;
  }

  @include breakpoint(large) {
    grid-row-gap: 40px;
    grid-column-gap: 40px;
  }
}

.PropositionBlock-title {
  grid-area: 1 / 1 / 2 / 13;
  font-family: $font-colfax-bold;
  color: $mt-deep-purple;

  @include breakpoint(small only) {
    margin-bottom: 30px;
    font-size: rem-calc(28);
    line-height: unitless-calc(30, 28);
  }

  @include breakpoint(medium) {
    margin-top: 20px;
    padding-right: 30px;
    padding-left: 30px;
    font-size: rem-calc(22);
    line-height: unitless-calc(24, 22);
  }

  @include breakpoint(large) {
    margin-top: 20px;
    padding-right: 40px;
    padding-left: 40px;
    font-size: rem-calc(26);
    line-height: unitless-calc(28, 26);
  }

  @include breakpoint(xlarge) {
    grid-area: 1 / 2 / 2 / 12;
    margin-top: 30px;
    padding: 0;
    font-size: rem-calc(28);
    line-height: unitless-calc(30, 28);
  }
}

.PropositionBlock-items {
  grid-area: 2 / 1 / 3 / 13;

  @include breakpoint(medium) {
    display: grid;
    grid-template-rows: repeat(2, auto);
    grid-template-columns: repeat(3, 1fr);
    // grid-area: 2 / 1 / 3 / 13;
    grid-column-gap: 30px;
    margin-bottom: 20px;
    padding-right: 30px;
    padding-left: 30px;
  }

  @include breakpoint(large) {
    grid-column-gap: 40px;
    margin-bottom: 20px;
    padding-right: 40px;
    padding-left: 40px;
  }

  @include breakpoint(xlarge) {
    grid-area: 2 / 2 / 3 / 12;
    grid-column-gap: 60px;
    margin-bottom: 30px;
    padding: 0;
  }

  > * {
    @include breakpoint(small only) {
      max-width: 400px;
      margin-bottom: 40px;
    }
    @include breakpoint(medium) {
      grid-column: span 1;
    }
  }
}

.PropositionBlock-itemIcon {
  width: 35px;
  height: 35px;
  margin-bottom: 20px;
  color: $mt-electric-purple;

  @include breakpoint(medium) {
    width: 30px;
    height: 30px;
    margin-bottom: 15px;
  }

  @include breakpoint(large) {
    width: 35px;
    height: 35px;
    margin-bottom: 20px;
  }

  @include breakpoint(xlarge) {
    width: 40px;
    height: 40px;
    margin-bottom: 25px;
  }
}

.PropositionBlock-itemTitle {
  margin-bottom: 15px;
  font-family: $font-colfax-bold;
  font-size: rem-calc(28);
  line-height: unitless-calc(30, 28);
  color: $mt-electric-purple;

  @include breakpoint(medium) {
    margin-bottom: 20px;
    font-size: rem-calc(22);
    line-height: unitless-calc(24, 22);
  }

  @include breakpoint(large) {
    margin-bottom: 25px;
    font-size: rem-calc(26);
    line-height: unitless-calc(28, 26);
  }

  @include breakpoint(xlarge) {
    margin-bottom: 30px;
    font-size: rem-calc(28);
    line-height: unitless-calc(30, 28);
  }

  p {
    margin: 0;
  }
}

.PropositionBlock-itemDescription p {
  margin-bottom: 32px;
  font-size: rem-calc(20);
  line-height: unitless-calc(28, 20);
  color: $mt-deep-purple;

  @include breakpoint(medium) {
    margin-bottom: 28px;
    font-size: rem-calc(16);
    line-height: unitless-calc(22, 16);
  }

  @include breakpoint(large) {
    margin-bottom: 28px;
    font-size: rem-calc(18);
    line-height: unitless-calc(26, 18);
  }

  @include breakpoint(xlarge) {
    margin-bottom: 30px;
    font-size: rem-calc(20);
    line-height: unitless-calc(28, 20);
  }

  &:last-child {
    margin-bottom: 0;
  }
}

.PropositionBlock.PropositionBlock--contentToggle {
  &::before {
    position: absolute;
    top: 50%;
    bottom: 0;
    left: 0;
    display: none;
    width: 100vw;
    height: auto;
    content: '';
    background-color: $mt-frost-blue;
    @include breakpoint(medium) {
      display: block;
    }
  }

  .PropositionBlock-pattern {
    display: none;
  }
}

.PropositionBlock.PropositionBlock--abmPage {
  &::before {
    background-color: $mt-deep-purple;
  }

  .PropositionBlock-title {
    @include breakpoint(medium) {
      max-width: 45%;
    }
    @include breakpoint(large only) {
      max-width: 40%;
    }
    @include breakpoint(xxlarge) {
      max-width: 50%;
    }
    strong, b {
      color: $mt-electric-purple;
    }
  }

  .PropositionBlock-itemTitle {
    color: $mt-deep-purple;

    b {
      color: $mt-electric-purple;
    }
  }
}
