.ScreenWindow {
    position: relative;
    display: block;
    height: auto;
    margin-right: auto;
    margin-left: auto;

    @include breakpoint(medium) {
        width: calc(100% + 36px);
        max-width: 420px;
    }
  
    @include breakpoint(large) {
        width: calc(100% - 40px);
        max-width: none;
        margin-bottom: -60%;
    }
  
    @include breakpoint(xlarge) {
        width: calc(100% - 60px);
        margin-bottom: -60%;
    }
  
    @include breakpoint(xxlarge) {
        width: calc(100% - 60px);
        margin-bottom: -60%;
    }
  
    &::before {
        position: relative;
        display: block;
        width: 100%;
        height: 0;
        padding-bottom: 100%;
        content: '';
    }
}
  
.ScreenWindow-windowWrap {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    top: 37.7%;
    right: 0%;
    bottom: 8%;
    left: 0%;
    padding: 3.5vw 3.5vw 2vw;
    overflow: hidden;
    
    background-color: $white;
    border-radius: 0px 0px 8px 8px;
  
    @include breakpoint(medium only) {
        padding: 5% 25px;
        top: 56.6%;
        bottom: -11%;
    }
  
    @include breakpoint(large) {
        padding: 25px;
        top: 31.5%;
        bottom: 14.1%;
    }

    @include breakpoint(xlarge) {
        padding: 30px;
    }

    h3 { 
        border: 2px dashed #AA00FF;
    }
}

.ScreenWindow-overlayImage {
    position: absolute;
    width: 100%;
    height: 100%;
    margin: -3.5vw -3.5vw -2vw;
    z-index: 1;
    object-fit: cover;
    z-index: 1;

    @include breakpoint(medium) {
        margin: -5% -25px;
    }

    @include breakpoint(large) {
        margin: -25px;
    }

    @include breakpoint(xlarge) {
        margin: -30px;
    }
}

.ScreenWindow-logo {
    height: 20px;

    div {
        height: inherit;
    }

    @include breakpoint(medium) {
        height: 25px;
    }

    @include breakpoint(large) {
        height: 30px;
    }
}

.ScreenWindow-windowWrap h3 {
    width: fit-content;
    padding: 4px 8px 4px 5px;

    font-family: $font-proxima-bold;
    font-weight: 700;
    font-size: 5vw;

    box-sizing: border-box;

    @include breakpoint(medium) {
        font-size: 25px;
    }

    @include breakpoint(large) {
        font-size: 30px;
    }
}

.ScreenWindow-banner {
    margin: 0px auto;
    padding: 10px 25px;
    border-radius: 4px;
    font-weight: bold;
    text-align: center;
    background-color: $mt-electric-purple;
    color: $mt-twilight-blue;
    z-index: 2;

    @include breakpoint(medium only) {
        padding: 3% 5%;
    }

    @include breakpoint(large) {
        padding: 13px 45px;
    }
}

.ScreenWindow-banner-subtitle {
    padding-bottom: 3px;
    font-size: 1.5vw;

    @include breakpoint(medium) {
        font-size: 10px;
    }
}

.ScreenWindow-banner-titleWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
}

.ScreenWindow-banner-icon {
    width: 2vw;
    height: 2vw;
    margin-right: 1vw;
    stroke: $mt-twilight-blue;
    fill: $mt-twilight-blue;

    @include breakpoint(medium) {
        width: 12px;
        height: 12px;
        margin-right: 10px;
    }
}

.ScreenWindow-banner-title {
    font-size: 2vw;

    @include breakpoint(medium) {
        font-size: 14px;
    }
}