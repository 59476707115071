.CarouselItem {
  position: relative;
  display: flex;
  flex-direction: column;
  user-select: none;
  opacity: 0;
  transition: opacity 300ms ease-in-out;

  picture,
  img {
    width: 100%;
  }

  .CarouselItem-image {
    width: 100%;
  }

  .CarouselItem-image--mobile {
    display: block;
    @include breakpoint(medium) {
      display: none;
    }
  }

  .CarouselItem-image--desktop {
    display: none;
    @include breakpoint(large) {
      display: block;
    }
  }

  &.is-active {
    z-index: 2;
    display: block;
    opacity: 1;
  }
}

//
// PDF
//
.CarouselItem--pdf {
  display: none;
}
