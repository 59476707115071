.Benefits {
  position: relative;
  display: block;
  padding: 0;
  background-color: $mt-pampas-beige;
}

.Benefits-inner {
  @include xy-grid-container();

  position: relative;
}

.Benefits-wrapper {
  display: grid;
  grid-template-rows: repeat(2, auto);
  grid-template-columns: repeat(12, 1fr);
  grid-row-gap: 0;
  grid-column-gap: 9px;

  @include breakpoint(medium) {
    grid-column-gap: 30px;
    background-color: transparent;
  }

  @include breakpoint(large) {
    grid-column-gap: 40px;
  }
}

.Benefits-figure {
  grid-area: 1 / 1 / 2 / 13;
  order: 1;
  width: calc(100% + 60px);
  margin-bottom: 40px;
  margin-left: -30px;
  text-align: center;
  color: $mt-deep-purple;

  @include breakpoint(medium) {
    width: auto;
    margin-bottom: 50px;
    margin-left: 0;
  }

  @include breakpoint(large) {
    margin-bottom: 70px;
  }

  @include breakpoint(xlarge) {
    margin-bottom: 90px;
  }
}

.Benefits-header {
  grid-area: 2 / 1 / 3 / 13;
  order: 1;
  max-width: 400px;
  margin: 0 auto 35px;
  text-align: center;
  color: $mt-deep-purple;

  @include breakpoint(medium) {
    max-width: 640px;
    margin-bottom: 40px;
  }

  @include breakpoint(large) {
    max-width: 840px;
    margin-bottom: 60px;
  }

  @include breakpoint(xlarge) {
    margin-bottom: 85px;
  }
}

.Benefits-title {
  font-size: rem-calc(26);
  line-height: unitless-calc(26, 26);
  text-align: center;
  color: $mt-deep-purple;
  @include breakpoint(medium) {
    font-size: rem-calc(30);
  }

  @include breakpoint(large) {
    font-size: rem-calc(34);
  }

  @include breakpoint(xlarge) {
    margin-bottom: 30px;
    font-size: rem-calc(38);
  }
}

.Benefits-description {
  max-width: 510px;
  margin: 0 auto;
  text-align: center;

  p {
    margin-bottom: 32px;
    font-size: rem-calc(18);
    line-height: unitless-calc(26, 18);
    color: $mt-deep-purple;
    @include breakpoint(medium only) {
      font-size: rem-calc(16);
      line-height: unitless-calc(24, 16);
    }

    @include breakpoint(medium) {
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

.Benefits-values {
  display: flex;
  grid-area: 3 / 1 / 4 / 13;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;

  @include breakpoint(medium) {
    margin-bottom: 30px;
  }

  @include breakpoint(large) {
    margin-bottom: 40px;
  }

  @include breakpoint(xlarge) {
    margin-bottom: 50px;
  }
}

.Benefits-items {
  margin: 0;
  opacity: .2;
  transition: opacity 150ms ease-in-out;
}

.Benefits-item {
  margin-bottom: 25px;
  padding: 0;
  font-family: $font-colfax-bold;
  font-size: rem-calc(24);
  font-weight: 700;
  line-height: unitless-calc(24, 24);
  text-align: center;
  text-transform: uppercase;
  color: $mt-deep-purple;

  @include breakpoint(medium) {
    margin-bottom: 30px;
    font-size: rem-calc(28);
  }

  @include breakpoint(large) {
    margin-bottom: 35px;
    font-size: rem-calc(34);
  }

  @include breakpoint(xlarge) {
    margin-bottom: 40px;
    font-size: rem-calc(38);
  }

  & > p {
    margin: 0;
  }

  &:last-child {
    margin: 0;
  }
}
