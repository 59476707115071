
.Carousel {
  --Carousel-slides-background-color: #{$mt-frost-blue};

  @include xy-grid-container();

  margin-bottom: 60px;
  color: $mt-deep-purple;
}

.Carousel-inner {
  display: flex;
  flex-direction: column;
}

.Carousel-head {
  margin-bottom: 30px;

  @include breakpoint(large) {
    display: grid;
    grid-template-rows: auto;
    grid-template-columns: repeat(12, 1fr);
    grid-row-gap: 0;
    grid-column-gap: 40px;
    margin-bottom: 50px;
  }
}

.Carousel-title {
  grid-area: 1 / 3 / 2 / 10;
  font-family: $font-colfax-bold;
  font-size: rem-calc(26);
  line-height: unitless-calc(24, 26);
  text-transform: uppercase;

  @include breakpoint(large) {
    font-size: rem-calc(36);
    line-height: unitless-calc(32, 36);
  }
}

.Carousel-container {
  position: relative;

  @include breakpoint(large) {
    display: grid;
    grid-template-rows: repeat(2, auto);
    grid-template-columns: repeat(12, 1fr);
    grid-row-gap: 0;
    grid-column-gap: 40px;
  }
}

.Carousel-navigation {
  position: relative;
  z-index: 1;
  padding: 35px 0 15px;
  font-size: rem-calc(16);
  line-height: unitless-calc(22, 16);
  background-color: var(--Carousel-slides-background-color);

  @include breakpoint(large) {
    display: grid;
    grid-area: 1 / 3 / 2 / 12;
    grid-template-rows: 1fr;
    grid-template-columns: repeat(9, 1fr);
    grid-row-gap: 0;
    grid-column-gap: 0;
    padding: 50px 0 40px;
    font-size: rem-calc(18);
    line-height: unitless-calc(25, 18);
  }
}

.Carousel-labels {
  display: flex;
  flex-flow: wrap;

  @include breakpoint(large) {
    grid-area: 1 / 1 / 2 / 7;
  }
}

.Carousel-liftNumber {
  @include font-futura-demi();

  margin-top: 20px;
  color: $mt-electric-purple;

  @include breakpoint(large) {
    grid-area: 1 / 7 / 2 / 10;
    margin-top: 0;
    margin-right: 40px;
    text-align: center;
  }
}

.Carousel-slideLabel {
  @include font-futura-demi();

  position: relative;
  margin-right: 50px;
  margin-bottom: 15px;
  user-select: none;
  transition: color 150ms ease-in-out;

  &:not(.is-active) {
    &:hover {
      cursor: pointer;
      color: $mt-electric-purple;
    }
  }

  &::after {
    position: absolute;
    bottom: -3px;
    left: 0;
    width: 100%;
    height: 2px;
    content: ' ';
    transform: scaleX(0);
    transform-origin: 0 0;
    background: $mt-deep-purple;
    transition: transform .4s cubic-bezier(.645, .045, .355, 1); // easeInOutCubic
    will-change: transform;

    @include breakpoint(large) {
      bottom: -8px;
    }
  }

  &.is-scalefromleft::after {
    transform-origin: 0 0;
  }

  &.is-scalefromright::after {
    transform-origin: 100% 0;
  }

  &.is-active::after {
    transform: scaleX(1);
  }

  @include breakpoint(large) {
    font-size: rem-calc(18);
    line-height: unitless-calc(25, 18);
  }

  &:last-child {
    margin-right: 0;
  }
}

.Carousel-slides {
  // Making all DOM elements in the same position... so long `position:absolute;` !! ༼ つ ◕_◕ ༽つ
  display: grid;
  grid-area: 2 / 2 / 3 / 12;
  font-size: 0;
  background-color: var(--Carousel-slides-background-color);

  > * {
    z-index: 1;
    grid-area: 1 / 1 / -1 / -1;
  }

  &::before {
    position: absolute;
    z-index: 0;
    top: 0;
    width: 50vw;
    height: 100%;
    content: ' ';
    transform: translateX(-50%);
    background-color: var(--Carousel-slides-background-color);
  }
}

.Carousel-arrow {
  position: absolute;
  z-index: 10;
  top: 55%;
  padding: 10px 20px;
  transition: color 150ms ease-in-out;

  svg {
    width: 20px;
    height: 40px;
  }

  &:hover {
    cursor: pointer;
    color: $mt-electric-purple;
  }
}

.Carousel-arrow.Carousel--previous {
  left: 0;
  transform: translateX(-20px);
}

.Carousel-arrow.Carousel--next {
  right: 0;
  transform: translateX(20px);
}

//
//  ABM Guide / Playbooks
//
.Carousel.Carousel--playbooks {
  --Carousel-slides-background-color: #{$mt-lily-white};
}

//
//  Chapters
//
.Carousel.Carousel--chapter {
  --Carousel-slides-background-color: #{$mt-lily-white};

  padding: 0;
}

.Carousel.Carousel--chapter .Carousel-container {
  display: block;
  margin: 0 calc(#{$gutter-small} * -1);
  padding: #{$gutter-small} 0 0 #{$gutter-small};
  background-color: var(--Carousel-slides-background-color);

  @include breakpoint(large) {
    margin: 0 calc(#{$gutter-large} * -1) 0 0;
    padding: #{$gutter-large} 0 0 #{$gutter-large};
  }
  @include breakpoint(xlarge) {
    width: calc(((100% + ((((100% - (6 * #{$gutter-large})) / 7) * 2) + (2 * #{$gutter-large}))) + #{$gutter-large}) - ((((100% - (6 * #{$gutter-large})) / 7) + #{$gutter-large})));
    margin: 0;
    padding: #{$gutter-large} 0 0 calc((((100% - (6 * #{$gutter-large})) / 7) + #{$gutter-large}));
  }
  @include breakpoint(1360px) {
    width: calc(((100% + ((((100% - (6 * #{$gutter-large})) / 7) * 2) + (2 * #{$gutter-large}))) + ((100vw - #{$global-width}) / 2)) - ((((100% - (6 * #{$gutter-large})) / 7) + #{$gutter-large})));
    padding: #{$gutter-large} 0 0 calc((((100% - (6 * #{$gutter-large})) / 7) + #{$gutter-large}));
  }
}

.Carousel.Carousel--chapter .Carousel-navigation {
  display: block;
  padding: 0 #{$gutter-small} 10px 0;

  @include breakpoint(large) {
    padding: 0 #{$gutter-large} 10px 0;
  }
}

.Carousel.Carousel--chapter .Carousel-slideLabel {
  margin-bottom: 20px;
  @include breakpoint(large) {
    margin-bottom: 25px;
  }
}

.Carousel.Carousel--chapter .Carousel-arrow {
  display: none;
}

.Carousel.Carousel--chapter .Carousel-slides::before {
  display: none;
}
