.Avatar {
  position: relative;
  display: block;
  overflow: hidden;
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.Avatar-image {
  width: 100%;
  object-fit: cover;
}
