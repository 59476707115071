
.CategoryLayout {
  display: block;
  margin-bottom: -60px;
  transform: translateY(-60px);

  @include breakpoint(large) {
    margin-bottom: -120px;
    transform: translateY(-120px);
  }
}

.CategoryLayout-header {
  position: relative;
  padding-top: 120px;
  background-color: $mt-tolopea-purple;
}

//
// RequestInvite / NewsletterSubscribe overwrites
//
.CategoryLayout .RequestInvite,
.CategoryLayout .NewsletterSubscribe {
  max-width: 640px;
}

.CategoryLayout .RequestInvite-inputContainer,
.CategoryLayout .NewsletterSubscribe-inputContainer {
  flex: 1;

  & > input {
    width: 100%;
  }
}

//
// CallToAction overwrites
//
.CategoryLayout-main .CallToAction {

  @include breakpoint(large) {
    padding: 20px 0 90px;
  }

  @include breakpoint(large) {
    padding: 20px 0 160px;
  }

  @include breakpoint(xlarge) {
    padding: 60px 0 160px;
  }
}

.CategoryLayout-main .CallToAction-title {
  max-width: 400px;
  margin: 0 auto 15px;
  padding: 0;
  font-size: rem-calc(30);
  line-height: unitless-calc(30, 30);
  text-align: center;
  text-transform: none;
  color: var(--CallToAction-title-color);

  @include breakpoint(medium) {
    margin: 0 auto 20px;
    font-size: rem-calc(34);
  }

  @include breakpoint(large) {
    margin: 0 auto 45px;
    font-size: rem-calc(38);
  }

  @include breakpoint(large) {
    margin: 0 auto 65px;
  }
}

.CategoryLayout-main .CallToAction-body {
  max-width: 500px;
  margin: 0 auto;
  font-size: rem-calc(20);
  line-height: unitless-calc(22, 20);
  color: var(--CallToAction-highlight-color);

  @include breakpoint(small down) {
    max-width: 400px;
  }

  @include breakpoint(large) {
    max-width: 700px;
    font-size: rem-calc(24);
    line-height: unitless-calc(30, 24);
  }
}
