.Filter {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
  padding: 0 20px;
  color: $mt-deep-purple;
  background-color: $mt-lily-white;
}

.Filter select {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}

.Filter-label {
  @include font-futura-demi();

  display: flex;
  align-items: center;
  box-sizing: border-box;
  min-height: 60px;
}

.Filter-arrow {
  width: 12px;
  height: 6px;
}
