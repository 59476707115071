.InlineImage {
  padding-bottom: 80px;
  font-size: 0;
}

.InlineImage-inner {
  @include xy-grid-container();

  @include breakpoint(large) {
    display: grid;
    grid-template-rows: repeat(2, auto);
    grid-template-columns: repeat(12, 1fr);
    grid-row-gap: 0;
    grid-column-gap: 40px;
  }
}

.InlineImage-picture {
  position: relative;
  padding-top: 30px;
  background-color: rgba($white, .5);

  @include breakpoint(large) {
    grid-area: 1 / 2 / 2 / 12;
    padding-top: 60px;
  }

  &::before {
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    width: 60vw;
    height: 100%;
    content: ' ';
    transform: translateX(-60vw);
    background-color: rgba($white, .5);
  }

  picture,
  img {
    width: 100%;
  }
}

.InlineImage-caption {
  padding-top: 30px;
  font-size: rem-calc(15);
  font-style: italic;
  line-height: unitless-calc(22, 15);
  color: $mt-deep-purple;

  @include breakpoint(large) {
    grid-area: 2 / 3 / 3 / 10;
    font-size: rem-calc(16);
    line-height: unitless-calc(24, 16);
  }
}
