.NewsletterSubscribe {
  width: 100%;
  max-width: 600px;

  --NewsletterSubscribe-color: #{$mt-electric-purple};
  --NewsletterSubscribe-focus-color: #{$white};
  --NewsletterSubscribe-success-color: #{$white};

  @include breakpoint(large) {
    max-width: 600px;
    height: 50px;
  }
}

.NewsletterSubscribe-inner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  opacity: 0;

  @include breakpoint(large) {
    flex-direction: row;
  }

  &.is-visible {
    opacity: 1;
  }
}

.NewsletterSubscribe-inputContainer {
  position: relative;
  margin-bottom: 20px;
  transform: translateY(50px);
  opacity: 0;
  transition: transform .6s cubic-bezier(.215, .61, .355, 1), opacity .6s cubic-bezier(.215, .61, .355, 1); // easeOutCubic
  transition-delay: .5s;

  .NewsletterSubscribe-inner.is-visible & {
    transform: translateY(0);
    opacity: 1;
  }

  & input {
    @include font-futura-demi();

    height: 50px;
    font-size: rem-calc(18);
    color: var(--NewsletterSubscribe-focus-color);
    border: none;
    outline: none;
    background: none;
  }

  & input::placeholder {
    color: var(--NewsletterSubscribe-color);
  }

  &::before {
    position: absolute;
    bottom: 0;
    display: block;
    width: 100%;
    height: 2px;
    margin-top: -2px;
    content: " ";
    transform: scaleX(1);
    transform-origin: 0 0;
    background: var(--NewsletterSubscribe-color);
    transition: background-color .3s, transform .6s cubic-bezier(.215, .61, .355, 1);// easeOutCubic
  }

  &::after {
    position: absolute;
    bottom: 0;
    display: block;
    width: 100%;
    height: 2px;
    margin-top: -2px;
    content: " ";
    transform: scaleX(0);
    transform-origin: 0 0;
    background: var(--NewsletterSubscribe-focus-color);
    transition: transform .6s cubic-bezier(.645, .045, .355, 1);
  }

  &.is-focused {
    &::after {
      transform: scaleX(1);
    }
  }

  &.is-invalid {
    &::before {
      animation-name: showErrorLine;
      animation-duration: 2s;
      animation-delay: .3s;
    }

    &::after {
      animation-name: showErrorLineFocus;
      animation-duration: 2s;
      animation-delay: .3s;
    }
  }

  @keyframes showErrorLine {
    0% {
      background: var(--NewsletterSubscribe-color);
    }

    30% {
      background: $red-error;
    }

    60% {
      background: $red-error;
    }

    100% {
      background: var(--NewsletterSubscribe-color);
    }
  }

  @keyframes showErrorLineFocus {
    0% {
      background: var(--NewsletterSubscribe-focus-color);
    }

    30% {
      background: $red-error;
    }

    60% {
      background: $red-error;
    }

    100% {
      background: var(--NewsletterSubscribe-focus-color);
    }
  }

  @include breakpoint(large) {
    margin-top: -2px;
    margin-right: 20px;
    margin-bottom: 0;
  }

  @include breakpoint(xlarge) {
    margin-right: 40px;
  }
}

.NewsletterSubscribe-buttonContainer {
  min-width: 210px;
  height: 50px;
  transform: translateY(50px);
  opacity: 0;
  transition: transform .6s cubic-bezier(.215, .61, .355, 1), opacity .6s cubic-bezier(.215, .61, .355, 1); // easeOutCubic
  transition-delay: .6s;
  @include breakpoint(large) {
    min-width: 0;
  }

  .NewsletterSubscribe-inner.is-visible & {
    transform: translateY(0);
    opacity: 1;
  }
}

.NewsletterSubscribe-success {
  @include font-futura-demi();

  margin-top: 12px;
  animation-name: showSucces;
  animation-duration: .6s;
  animation-timing-function: cubic-bezier(.215, .61, .355, 1); // easeOutCubic
  font-size: rem-calc(18);
  line-height: unitless-calc(25, 18);
  color: var(--NewsletterSubscribe-success-color);
}

@keyframes showSucces {
  from {
    transform: translateY(25px);
    opacity: 0;
  }

  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.NewsletterSubscribe.NewsletterSubscribe--homeHeader {
  justify-content: flex-start;

  .NewsletterSubscribe-inner {
    align-items: normal;
  }

  .NewsletterSubscribe-buttonContainer {
    min-width: 100%;

    @include breakpoint(large) {
      min-width: 0;
    }
  }
}

//
// Color Modifiers
//

.NewsletterSubscribe.NewsletterSubscribe--white {
  --NewsletterSubscribe-focus-color: #{$white};
}

.NewsletterSubscribe.NewsletterSubscribe--deepPurple {
  --NewsletterSubscribe-focus-color: #{$mt-deep-purple};
}

.NewsletterSubscribe.NewsletterSubscribe--electricPurple {
  --NewsletterSubscribe-color: #{$mt-electric-purple};
  --NewsletterSubscribe-focus-color: #{$white};
}

.CallToAction--white .NewsletterSubscribe.NewsletterSubscribe--electricPurple {
  --NewsletterSubscribe-focus-color: #{$mt-deep-purple};
  --NewsletterSubscribe-color: #{$mt-electric-purple};
  --NewsletterSubscribe-success-color: #{$mt-deep-purple};
}
