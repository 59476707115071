.Chapter {
  display: flex;
}

.Chapter-inner {
  @include breakpoint(large) {
    display: grid;
    grid-template-columns: repeat(9, 1fr);
    grid-row-gap: 0;
    grid-column-gap: 40px;
    margin: -68px 0 60px;
  }
}

.Chapter-header {
  display: flex;
  flex-direction: column;
  margin-top: 60px;

  @include breakpoint(medium) {
    flex-direction: row;
    margin-top: 60px;
  }

  @include breakpoint(large) {
    grid-area: 1/1/2/10;
    margin-top: 80px;
  }

  @include breakpoint(xlarge) {
    grid-area: 1/1/2/8;
    margin-top: 110px;
  }
}

.Chapter-content {
  @include breakpoint(large) {
    grid-area: 2/1/3/10;
  }

  @include breakpoint(xlarge) {
    grid-area: 2/1/3/8;
  }
}

.Chapter-illustration {
  display: block;
  width: 40px;
  height: 40px;
  margin: 0 0 15px 0;

  @include breakpoint(medium) {
    margin: -5px 20px 0 0;
  }

  @include breakpoint(large) {
    margin: 0 20px 0 0;
  }
}

.Chapter-graphic {
  width: 100%;
  height: 100%;
}

.Chapter-title {
  position: relative;
  margin: 0 0 20px;
  font-family: $font-body-text;
  font-size: rem-calc(32);
  line-height: unitless-calc(40, 32);
  text-transform: uppercase;
  color: $mt-electric-purple;

  @include breakpoint(large) {
    margin: 0 0 44px;
    font-size: rem-calc(36);
    line-height: unitless-calc(50, 36);
  }
}

.Chapter-orderedList + .Chapter-subheading,
.Chapter-unorderedList + .Chapter-subheading {
  margin-top: 40px;
  @include breakpoint(large) {
    margin-top: 60px;
  }
}

.Chapter-subheading {
  position: relative;
  margin: 0 0 24px;
  padding: 12px 0 6px 55px;
  font-size: rem-calc(20);
  line-height: unitless-calc(22, 20);
  color: $mt-pigment-purple;

  @include breakpoint(large) {
    margin: 0 0 30px;
    padding: 12px 0 6px 60px;
  }

  &::before {
    position: absolute;
    top: 0;
    left: 0;
    display: inline-flex;
    width: 36px;
    height: 36px;
    content: '';
    border: 2px solid $mt-pigment-purple;
    border-radius: 50%;
  }

  &::after {
    position: absolute;
    top: 0;
    left: 0;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 36px;
    padding: 3px 0 1px;
    content: attr(data-index);
    font-size: rem-calc(20);
    line-height: unitless-calc(36, 20);
  }
}

.Chapter-content > .Chapter-paragraph {
  margin-bottom: 32px;
  font-size: rem-calc(18);
  line-height: unitless-calc(26, 18);
  color: $mt-deep-purple;

  @include breakpoint(large) {
    font-size: rem-calc(18);
    line-height: unitless-calc(32, 18);
  }
}

.Chapter-content > .Button-link {
  display: inline-block;
  margin-bottom: 32px;
}

.Chapter-paragraph a {
  text-decoration: underline;
  color: $mt-electric-purple;
  transition: color .15s;
}

.Chapter-paragraph a:hover {
  color: $mt-deep-purple;
}

.Chapter-orderedList {
  margin-bottom: 45px;
  counter-reset: list-number;
  color: $mt-deep-purple;

  @include breakpoint(large) {
    margin-bottom: 40px;
  }
}

.Chapter-orderedList > li {
  position: relative;
  margin: 0 0 32px;
  margin-bottom: 16px;
  padding: 11px 0 7px 55px;
  counter-increment: list-number;

  @include breakpoint(large) {
    margin-bottom: 32px;
    padding: 9px 0 3px 60px;
  }

  & > p {
    margin: 0;
    padding: 0;
    font-size: rem-calc(18);
    line-height: unitless-calc(22, 18);
    color: $mt-deep-purple;

    @include breakpoint(large) {
      font-size: rem-calc(18);
      line-height: unitless-calc(28, 18);
    }
  }

  &::before {
    position: absolute;
    top: 0;
    left: 0;
    display: inline-flex;
    width: 40px;
    height: 40px;
    content: '';
    border-radius: 50%;
    background-color: $mt-electric-purple;
  }

  &::after {
    position: absolute;
    top: 0;
    left: 0;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 36px;
    padding: 3px 0 1px;
    content: counter(list-number);
    font-family: $font-headings;
    font-size: rem-calc(20);
    line-height: unitless-calc(36, 20);
    color: $white;
  }
}

.Chapter-unorderedList {
  margin: 0;
  color: $mt-deep-purple;
}

.Chapter-unorderedList > li {
  position: relative;
  margin-bottom: 26px;
  padding-left: 26px;

  @include breakpoint(large) {
    margin-bottom: 32px;
    padding-left: 32px;
  }

  & > p {
    margin: 0;
    padding: 0;
    font-size: rem-calc(18);
    line-height: unitless-calc(26, 18);
    color: $mt-deep-purple;

    @include breakpoint(large) {
      font-size: rem-calc(18);
      line-height: unitless-calc(32, 18);
    }
  }

  &::before {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    content: '\2022';
    font-size: rem-calc(24);
    line-height: unitless-calc(24, 24);
    color: $mt-electric-purple;

    @include breakpoint(large) {
      font-size: rem-calc(32);
      line-height: unitless-calc(32, 32);
    }
  }
}

.Chapter-paragraph:empty {
  display: none;
}
