.VimeoWidget {
  @include xy-grid-container();

  @include breakpoint(large) {
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: repeat(12, 1fr);
    grid-row-gap: 0;
    grid-column-gap: 40px;
  }

  margin-bottom: 60px;

  .VimeoWidget-inner {
    @include breakpoint(large) {
      grid-area: 1 / 3 / 2 / 10;
    }

    position: relative;
    overflow: hidden;
    max-width: 100%;
    height: 0;
    height: auto;
    padding-bottom: 56.25%;

    iframe,
    object,
    embed {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
}
