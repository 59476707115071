.ProductFeatures {
  position: relative;
  display: block;
  overflow: hidden;
  padding: 50px 0 65px;
  background-color: $mt-pampas-beige;

  --ProductFeatures-title-color: #{$mt-deep-purple};
  --ProductFeatures-highlight-color: #{$mt-electric-purple};

  @include breakpoint(medium) {
    padding: 60px 0 85px;
  }
  @include breakpoint(large) {
    padding: 70px 0 115px;
  }
  @include breakpoint(xxlarge) {
    padding: 95px 0 135px;
  }
}

.ProductFeatures-inner {
  @include xy-grid-container();

  position: relative;
}

.ProductFeatures-wrapper {
  display: grid;
  grid-template-rows: repeat(2, auto);
  grid-template-columns: repeat(12, 1fr);
  grid-row-gap: 0;
  grid-column-gap: 9px;

  @include breakpoint(medium) {
    grid-column-gap: 30px;
  }

  @include breakpoint(large) {
    grid-column-gap: 40px;
  }
}

.ProductFeatures-header {
  z-index: 2;
  grid-area: 1 / 1 / 2 / 13;
  padding-bottom: 40px;

  @include breakpoint(medium) {
    padding-bottom: 70px;
  }
  @include breakpoint(large) {
    padding-bottom: 80px;
  }
  @include breakpoint(xxlarge) {
    padding-bottom: 90px;
  }
}

.ProductFeatures-title {
  max-width: 300px;
  margin: 0 auto 30px;
  padding: 0;
  font-family: $font-colfax-bold;
  font-size: rem-calc(32);
  line-height: unitless-calc(32, 32);
  text-align: center;
  text-transform: uppercase;
  color: var(--ProductFeatures-title-color);

  @include breakpoint(medium) {
    max-width: 550px;
    font-size: rem-calc(45);
  }

  @include breakpoint(large) {
    max-width: 650px;
    margin-bottom: 25px;
    font-size: rem-calc(50);
  }

  @include breakpoint(xxlarge) {
    font-size: rem-calc(55);
  }

  &.is-visible {
    .ProductFeatures-titleHighlight {
      color: var(--ProductFeatures-highlight-color);
    }
  }
}

.ProductFeatures-titleHighlight {
  transition: color .8s;
}

.ProductFeatures-description {
  max-width: 400px;
  margin: 0 auto 30px;
  font-size: rem-calc(18);
  line-height: unitless-calc(24, 18);
  text-align: center;
  color: var(--ProductFeatures-title-color);

  p {
    @include title-line-breaks();
  }

  @include breakpoint(medium) {
    max-width: 640px;
    font-size: rem-calc(20);
    line-height: unitless-calc(26, 20);
  }

  @include breakpoint(large) {
    max-width: 500px;
    font-size: rem-calc(22);
    line-height: unitless-calc(28, 22);
  }
  @include breakpoint(xlarge) {
    max-width: 600px;
    font-size: rem-calc(24);
    line-height: unitless-calc(30, 24);
  }
}

.ProductFeatures-buttonGroup {
  display: flex;
  justify-content: center;

  & > * {
    margin: 0 auto;
  }
}

.ProductFeatures-content {
  grid-area: 2 / 1 / 3 / 13;
}

.ProductFeatures-item {
  display: grid;
  grid-template-rows: repeat(2, auto);
  grid-template-columns: repeat(12, 1fr);
  grid-row-gap: 0;
  grid-column-gap: 9px;
  margin-bottom: 15px;

  @include breakpoint(medium) {
    grid-row-gap: 20px;
    grid-column-gap: 30px;
    margin-bottom: 0;
  }

  @include breakpoint(large) {
    grid-row-gap: 40px;
    grid-column-gap: 40px;
  }
}

.ProductFeatures-itemCopy {
  display: flex;
  grid-area: 2 / 1 / 3 / 13;
  flex-direction: column;
  justify-content: center;
  padding: 20px 0 0;
  color: $mt-deep-purple;

  @include breakpoint(medium) {
    padding: 20px 0;
  }
  @include breakpoint(large) {
    padding: 30px 0;
  }
  @include breakpoint(xxlarge) {
    padding: 35px 0;
  }
}

.ProductFeatures-itemFigure {
  display: flex;
  grid-area: 1 / 1 / 2 / 13;
  flex-direction: column;
  justify-content: center;
  padding: 30px 0;
  @include breakpoint(medium) {
    opacity: 0;
    transition: opacity .6s cubic-bezier(.215, .61, .355, 1), transform .6s cubic-bezier(.215, .61, .355, 1); // easeOutCubic
    transition-delay: .1s;
    will-change: transform, opacity;
  }

  @include breakpoint(small down) {
    .ProductFeatures-item.ProductFeatures-item--wideImage & {
      margin-right: -70px;
    }
  }

  @include breakpoint(medium) {
    padding: 20px 0;
  }
  @include breakpoint(large) {
    padding: 30px 0;
  }
  @include breakpoint(xxlarge) {
    padding: 35px 0;
  }

  .ProductFeatures-item--leftImage & {
    @include breakpoint(medium) {
      transform: translateX(-200px);
    }
  }

  .ProductFeatures-item--rightImage & {
    @include breakpoint(medium) {
      transform: translateX(200px);
    }
  }

  .ProductFeatures-item.is-visible & {
    @include breakpoint(medium) {
      transform: translateX(0);
      opacity: 1;
    }
  }

  img {
    max-width: 100%;
    height: auto;
  }
}

.ProductFeatures-item.ProductFeatures-item--leftImage.ProductFeatures-item--wideImage {
  .ProductFeatures-itemFigure {

    @include breakpoint(medium) {
      grid-area: 1 / 1 / 2 / 7;
    }
    @include breakpoint(large) {
      grid-area: 1 / 1 / 2 / 8;
    }
    @include breakpoint(xxlarge) {
      grid-area: 1 / 1 / 2 / 8;
    }
  }

  .ProductFeatures-itemCopy {

    @include breakpoint(medium) {
      grid-area: 1 / 8 / 2 / 13;
    }
    @include breakpoint(large) {
      grid-area: 1 / 9 / 2 / 13;
    }
    @include breakpoint(xxlarge) {
      grid-area: 1 / 9 / 2 / 13;
    }
  }
}

.ProductFeatures-item.ProductFeatures-item--leftImage.ProductFeatures-item--slimImage {
  .ProductFeatures-itemFigure {

    @include breakpoint(medium) {
      grid-area: 1 / 1 / 2 / 6;
    }
    @include breakpoint(large) {
      grid-area: 1 / 1 / 2 / 7;
    }
    @include breakpoint(xxlarge) {
      grid-area: 1 / 1 / 2 / 7;
    }
  }

  .ProductFeatures-itemCopy {

    @include breakpoint(medium) {
      grid-area: 1 / 7 / 2 / 12;
    }
    @include breakpoint(large) {
      grid-area: 1 / 8 / 2 / 12;
    }
    @include breakpoint(xxlarge) {
      grid-area: 1 / 8 / 2 / 12;
    }
  }
}

.ProductFeatures-item.ProductFeatures-item--rightImage.ProductFeatures-item--wideImage {
  .ProductFeatures-itemFigure {
    @include breakpoint(medium) {
      grid-area: 1 / 6 / 2 / 13;
    }
    @include breakpoint(large) {
      grid-area: 1 / 6 / 2 / 13;
    }
    @include breakpoint(xxlarge) {
      grid-area: 1 / 6 / 2 / 13;
    }
  }

  .ProductFeatures-itemCopy {

    @include breakpoint(medium) {
      grid-area: 1 / 1 / 2 / 6;
    }
    @include breakpoint(large) {
      grid-area: 1 / 2 / 2 / 6;
    }
    @include breakpoint(xxlarge) {
      grid-area: 1 / 2 / 2 / 6;
    }
  }
}

.ProductFeatures-item.ProductFeatures-item--rightImage.ProductFeatures-item--slimImage {
  .ProductFeatures-itemFigure {

    @include breakpoint(medium) {
      grid-area: 1 / 7 / 2 / 13;
    }
    @include breakpoint(large) {
      grid-area: 1 / 7 / 2 / 13;
    }
    @include breakpoint(xxlarge) {
      grid-area: 1 / 7 / 2 / 13;
    }
  }

  .ProductFeatures-itemCopy {

    @include breakpoint(medium) {
      grid-area: 1 / 1 / 2 / 6;
    }
    @include breakpoint(large) {
      grid-area: 1 / 2 / 2 / 6;
    }
    @include breakpoint(xxlarge) {
      grid-area: 1 / 2 / 2 / 6;
    }
  }
}

.ProductFeatures-item.ProductFeatures-item--windowed .ProductFeatures-itemFigure img {
  margin: 0;
}

.ProductFeatures-item.ProductFeatures-item--unWindowed .ProductFeatures-itemFigure img {
  max-width: calc(100% + 20px);
  margin-right: -10px;
  margin-bottom: -40px;
  margin-left: -10px;

  @include breakpoint(medium) {
    max-width: calc(100% + 26px);
    margin-right: -13px;
    margin-left: -13px;
  }

  @include breakpoint(large) {
    max-width: calc(100% + 32px);
    margin-right: -16px;
    margin-bottom: -60px;
    margin-left: -16px;
  }

  @include breakpoint(xxlarge) {
    max-width: calc(100% + 40px);
    margin-right: -20px;
    margin-bottom: -80px;
    margin-left: -20px;
  }
}

.ProductFeatures-item--electricPurple .ProductFeatures-itemCopy {
  color: $mt-electric-purple;
}

.ProductFeatures-itemIcon {
  width: 40px;
  height: 40px;
  margin-bottom: 24px;
  color: currentColor;

  @include breakpoint(medium) {
    width: 34px;
    height: 34px;
    margin-bottom: 18px;
  }
  @include breakpoint(large) {
    width: 52px;
    height: 52px;
    margin-bottom: 28px;
  }
  @include breakpoint(xxlarge) {
    width: 58px;
    height: 58px;
    margin-bottom: 30px;
  }
}

.ProductFeatures-itemNumber {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 37px;
  height: 37px;
  margin-bottom: 20px;
  padding-top: 1px;
  font-family: $font-colfax-bold;
  font-size: rem-calc(18);
  font-weight: 700;
  line-height: unitless-calc(18, 18);
  text-align: center;

  @include breakpoint(large) {
    width: 41px;
    height: 41px;
    margin-bottom: 25px;
    padding-top: 1px;
    font-size: rem-calc(20);
  }

  &::before {
    position: absolute;
    top: 0;
    left: 0;
    display: inline-flex;
    width: 33px;
    height: 33px;
    content: '';
    border: 2px solid $mt-pigment-purple;
    border-radius: 50%;

    @include breakpoint(large) {
      width: 37px;
      height: 37px;
    }
  }
}

.ProductFeatures-item--electricPurple .ProductFeatures-itemNumber {
  color: $mt-electric-purple;

  &::before {
    border: 2px solid $mt-electric-purple;
  }
}

.ProductFeatures-itemTitle {
  margin: 0 0 20px;
  font-size: rem-calc(30);
  line-height: unitless-calc(30, 30);

  p {
    @include title-line-breaks();
  }

  @include breakpoint(medium) {
    margin: 0 0 18px;
    font-size: rem-calc(24);
  }

  @include breakpoint(large) {
    margin: 0 0 25px;
    font-size: rem-calc(32);
  }

  @include breakpoint(xxlarge) {
    margin: 0 0 30px;
    font-size: rem-calc(38);
  }
}

.ProductFeatures-itemDescription {
  p {
    font-size: rem-calc(18);
    line-height: unitless-calc(24, 18);

    @include breakpoint(medium) {
      font-size: rem-calc(18);
      line-height: unitless-calc(24, 18);
    }

    @include breakpoint(large) {
      font-size: rem-calc(22);
      line-height: unitless-calc(28, 22);
    }

    @include breakpoint(xxlarge) {
      font-size: rem-calc(24);
      line-height: unitless-calc(30, 24);
    }
  }
}

.ProductFeatures-window {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.ProductFeatures-window::after {
  position: absolute;
  z-index: 1;
  right: 5%;
  bottom: 0;
  left: 5%;
  height: 0;
  padding-bottom: 5%;
  content: '';
  box-shadow: 0 10px 30px rgba($black, .18);

  @include breakpoint(medium) {
    box-shadow: 0 15px 40px rgba($black, .18);
  }

  @include breakpoint(xxlarge) {
    box-shadow: 0 25px 50px rgba($black, .18);
  }
}

.ProductFeatures-windowBar {
  z-index: 2;
  padding: 17px 21px 15px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  background-color: $mt-powder-blue;

  &.is-dark {
    background-color: $black;
  }
}

.ProductFeatures-windowIcon {
  display: block;
  width: 34px;
  height: 8px;
}

.ProductFeatures-windowView {
  z-index: 2;
  overflow: hidden;
  border-right: 1px solid $mt-cararra;
  border-bottom: 1px solid $mt-cararra;
  border-left: 1px solid $mt-cararra;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  background-color: $white;

  img {
    display: block;
  }
}

//
// CntentToggle
//
.ProductFeatures.ProductFeatures--contentToggle {

  @include breakpoint(medium) {
    padding-bottom: 25px;
  }
  @include breakpoint(large) {
    padding-bottom: 45px;
  }
  @include breakpoint(xxlarge) {
    padding-bottom: 65px;
  }

  .ProductFeatures-header {
    padding-bottom: 15px;

    @include breakpoint(medium) {
      padding-bottom: 20px;
    }
    @include breakpoint(large) {
      padding-bottom: 25px;
    }
    @include breakpoint(xxlarge) {
      padding-bottom: 30px;
    }
  }

  .ProductFeatures-title {
    margin-bottom: 24px;
    padding: 0;
    font-size: rem-calc(30);
    line-height: unitless-calc(30, 30);
    text-transform: none;

    @include breakpoint(medium) {
      margin-bottom: 26px;
      font-size: rem-calc(34);
    }

    @include breakpoint(large) {
      margin-bottom: 28px;
      font-size: rem-calc(36);
    }

    @include breakpoint(xxlarge) {
      margin-bottom: 30px;
      font-size: rem-calc(38);
    }
  }
}