.PlaybooksSection {
  margin-bottom: 40px;

  @include breakpoint(large) {
    margin-bottom: 80px;
  }
}

.PlaybooksSection-inner {
  @include xy-grid-container();

  @include breakpoint(large) {
    display: grid;
    grid-template-rows: repeat(2, auto);
    grid-template-columns: repeat(12, 1fr);
    grid-row-gap: 0;
    grid-column-gap: 40px;
  }
}

.PlaybookSection-title {
  grid-area: 1 / 3 / 2 / 12;
  margin-bottom: 20px;
  padding-bottom: 20px;
  font-size: rem-calc(20);
  line-height: unitless-calc(18, 20);
  text-transform: uppercase;
  color: $mt-deep-purple;
  border-bottom: solid 1px $mt-periwinkle-gray;

  @include breakpoint(medium) {
    margin-bottom: 30px;
    padding-bottom: 30px;
  }
}

.PlaybookSection-grid {
  @include breakpoint(medium) {
    display: grid;
    grid-area: 2 / 3 / 2 / 12;
    grid-template-columns: repeat(10, 1fr);
    grid-row-gap: 40px;
    grid-column-gap: 40px;
    grid-auto-flow: dense;

    > * {
      grid-column: span 5;
    }
  }
  @include breakpoint(large) {
    grid-template-columns: repeat(9, 1fr);

    > * {
      grid-column: span 3;
    }
  }
}

// PlaybookCard in PlaybookSection
.PlaybookSection-grid .PlaybookCard {
  // padding-top: 30px;
  margin-bottom: 30px;
  // border-top: solid 1px $mt-periwinkle-gray;

  @include breakpoint(medium) {
    margin-bottom: 0;

    &:nth-child(-n+2) {
      margin-top: 0;
      padding-top: 0;
      border-top: none;
    }
  }
  @include breakpoint(large) {
    &:nth-child(-n+3) {
      padding-top: 0;
      border-top: none;
    }
  }

  &:first-child {
    margin-top: 0;
    padding-top: 0;
    border-top: none;
  }
}
