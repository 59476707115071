.Header {
  --Header-color: #{$mt-deep-purple};

  padding: 40px 0;
  color: var(--Header-color);

  @include breakpoint(large) {
    padding: 60px 0 40px;
  }
}

.Header-inner {
  @include xy-grid-container();

  @include breakpoint(large) {
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: repeat(12, 1fr);
    grid-row-gap: 0;
    grid-column-gap: 40px;
  }
}

.Header-back {
  padding-bottom: 20px;
}

.Header-text {
  font-size: rem-calc(16);
  line-height: unitless-calc(24, 16);

  @include breakpoint(large) {
    grid-area: 1 / 3 / 2 / 9;
    font-size: rem-calc(18);
    line-height: unitless-calc(26, 18);
  }
}

.Header-subtitle {
  @include font-futura-demi();

  display: block;
  margin-bottom: 10px;
  font-size: rem-calc(15);
}

.Header-title {
  margin-bottom: 15px;
  font-size: rem-calc(26);
  line-height: unitless-calc(24, 26);
  text-transform: uppercase;

  @include breakpoint(large) {
    margin-bottom: 30px;
    font-size: rem-calc(36);
    line-height: unitless-calc(32, 36);
  }

  span {
    display: block;
  }

  p {
    margin-bottom: 0;
  }
}

//
// Color Modifiers
//
.Header.Header--electricPurple {
  --Header-color: #{$mt-electric-purple};
}

.Header.Header--frostBlue {
  --Header-color: #{$mt-frost-blue};
}

//
// States
//
.Header.is-detail {
  color: $mt-electric-purple;

  .Header-text {
    grid-area: 1 / 3 / 2 / 10;
  }

  .Header-title {
    font-size: rem-calc(50);
    line-height: unitless-calc(54, 50);
    text-transform: none;
  }
}
