.TestimonialCard {
  --TestimonialCard-backgroundColor: #{$mt-deep-purple};
  --TestimonialCard-textColor: #{$white};

  display: flex;
  width: 100%;
  text-align: left;
  background: var(--TestimonialCard-backgroundColor);
  @include breakpoint(small down) {
    max-width: 400px;
    margin: 0 auto;
  }
}

.TestimonialCard-inner {
  display: block;
  padding: 35px 25px;

  @include breakpoint(large) {
    padding: 40px;
  }
}

.TestimonialCard-logo {
  position: relative;
  // flex: 0;
  width: auto;
  height: 20px;
  margin-right: auto;
  margin-bottom: 20px;

  @include breakpoint(large) {
    height: 30px;
    margin-bottom: 25px;
  }
}

.TestimonialCard-quote {
  max-width: 230px;
  margin-bottom: 25px;
  font-size: rem-calc(20);
  line-height: unitless-calc(22, 20);
  color: var(--TestimonialCard-textColor);

  @include breakpoint(large) {
    max-width: 270px;
    margin-bottom: 35px;
    font-size: rem-calc(24);
    line-height: unitless-calc(30, 24);
  }
}

.TestimonialCard.TestimonialCard--electricPurple {
  --TestimonialCard-backgroundColor: #{$mt-electric-purple};
}
