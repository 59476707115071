.Features {
  position: relative;
  overflow: hidden;

  @include breakpoint(large) {
    padding-bottom: 75px;
  }
}

.Features-inner {
  @include xy-grid-container();
}

.Features-header {
  position: relative;
  z-index: 1;
  padding: 40px 0;

  @include breakpoint(large) {
    display: grid;
    grid-template-rows: auto;
    grid-template-columns: repeat(12, 1fr);
    grid-row-gap: 0;
    grid-column-gap: 40px;
    padding: 100px 0;
  }
}

.Features-title {
  font-size: rem-calc(26);
  line-height: unitless-calc(24, 26);
  text-transform: uppercase;
  color: $mt-deep-purple;

  @include breakpoint(large) {
    grid-area: 1 / 1 / 2 / 6;
    margin-left: 40px;
    font-size: rem-calc(30);
    line-height: unitless-calc(26, 30);
  }

  @include breakpoint(xlarge) {
    grid-area: 1 / 2 / 2 / 6;
    margin-left: 0;
    font-size: rem-calc(36);
    line-height: unitless-calc(32, 36);
  }
}

.Features-pattern {
  position: absolute;
  z-index: -1;
  top: 0;
  left: 50%;
  display: none;
  width: 100vw;
  max-width: calc(1920px / 2);
  height: 100%;

  @include breakpoint(large) {
    display: block;
    height: calc(220%);
  }
}

.Features-items {
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: column;

  @include breakpoint(large) {
    flex-wrap: wrap;
    flex-direction: row;
  }
}

.Features-item {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  margin: 0 -30px;
  padding: 35px 0 0 30px;
  font-size: 0;

  &:nth-child(1) {
    background-color: $mt-frost-blue;
  }

  &:nth-child(2) {
    color: $white;
    background-color: $mt-electric-purple;

    h3 {
      color: $white;
    }
  }

  &:nth-child(3) {
    background-color: $mt-cararra;
  }

  &:nth-child(4) {
    background-color: $mt-celeste;
  }

  h3,
  p {
    padding-right: 30px;
  }

  h3 {
    margin-bottom: 20px;
    font-size: rem-calc(20);
    line-height: unitless-calc(18);
    text-transform: uppercase;
    color: $mt-deep-purple;
  }

  p {
    margin-bottom: 40px;
    font-size: rem-calc(18);
    line-height: unitless-calc(26, 18);
  }

  @include breakpoint(large) {
    position: relative;
    width: 50%;
    margin: 0;
    padding: 75px 0 0 50px;

    h3,
    p {
      padding-right: 60px;
    }

    &:nth-child(2),
    &:nth-child(4) {
      transform: translateY(75px);
    }
  }
}

.Features-media {
  align-self: flex-end;
  margin-top: auto;
}

// Modifiers for specific media wrappers
.Features-media.Features-media--percentage {
  overflow: hidden;
  width: 100%;

  @include breakpoint(large) {
    margin-bottom: 52px;
  }
}

.Features-media.Features-media--video {
  position: relative;
}

.Features-media.Features-media--image {
  align-self: flex-start;
  margin: 0;

  img {
    width: 75%;
    margin-bottom: 45px;
  }

  @include breakpoint(large) {
    img {
      margin: 0;
    }
  }
}
