.SubscribeBlock {
  @include breakpoint(medium) {
    @include xy-grid-container();

    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-row-gap: 0;
    grid-column-gap: 40px;
    margin-top: 120px;
    margin-bottom: 80px;
  }
}

.SubscribeBlock-inner {
  background-color: $mt-cararra;
  @include breakpoint(small down) {
    @include xy-grid-container();
  }

  @include breakpoint(medium) {
    grid-area: 1/1/2/13;
    border-radius: 10px;
  }

  @include breakpoint(large) {
    grid-area: 1/2/2/12;
  }
}

.SubscribeBlock-wrapper {
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: repeat(12, 1fr);
  grid-row-gap: 0;
  grid-column-gap: 9px;

  @include breakpoint(large) {
    grid-template-columns: repeat(10, 1fr);
    grid-column-gap: 40px;
  }
}

.SubscribeBlock-content {
  display: flex;
  grid-area: 1/1/2/13;
  flex-direction: column;
  justify-content: center;
  padding: 60px 0;

  @include breakpoint(medium) {
    grid-area: 1/1/2/8;
    padding: 40px 21px 40px 30px;
  }

  @include breakpoint(large) {
    grid-area: 1/1/2/6;
    padding: 85px 40px 55px;
  }

  @include breakpoint(xlarge) {
    grid-area: 1/2/2/7;
    padding: 85px 0 55px;
  }
}

.SubscribeBlock-title {
  margin-bottom: 30px;
  font-size: rem-calc(26);
  line-height: unitless-calc(26, 26);
  text-align: left;
  color: $mt-deep-purple;

  @include breakpoint(medium) {
    max-width: 400px;
    margin: 0 auto;
    margin-bottom: 30px;
    font-size: rem-calc(32);
    text-align: center;
  }

  @include breakpoint(large) {
    margin-bottom: 50px;
    font-size: rem-calc(36);
  }
}

.SubscribeBlock-buttonGroup {
  display: flex;
  flex-direction: column;
  justify-content: center;

  & > .Button {

    @include breakpoint(medium) {
      width: auto;
      margin: 0 auto;
    }
  }

  & .Button.Button--semiBlack {
    margin-top: 10px;

    .Button-shape {
      display: none;
    }
  }
}

.SubscribeBlock-card {
  position: relative;
  grid-area: 2/1/3/13;
  padding: 0 0 20px 0;

  @include breakpoint(medium) {
    grid-area: 1/8/2/13;
    padding: 0 30px 0 0;
  }

  @include breakpoint(large) {
    grid-area: 1/6/2/11;
    padding: 0 40px 0 0;
  }

  @include breakpoint(xlarge) {
    grid-area: 1/7/2/10;
    padding: 0;
  }

  .PlaybookCard {
    position: relative;
    z-index: 10;
    width: 100%;
    min-width: 200px;
    max-width: 400px;
    margin-bottom: 30px;

    @include breakpoint(medium) {
      margin-top: -30px;
    }

    @include breakpoint(large) {
      margin-top: -40px;
    }
  }
}

.SubscribeBlock-card::before {
  position: absolute;
  z-index: 5;
  right: 20px;
  bottom: 25px;
  left: 20px;
  height: 0;
  padding-bottom: 5%;
  content: '';
  transform: perspective(200px) rotateX(40deg);
  background-color: rgba($black, .9);
  filter: blur(20px);
}
