.PlaybooksGrid {
  position: relative;
  background-color: $mt-frost-blue;
}

.PlaybooksGrid-inner {
  @include xy-grid-container();

  position: relative;
  padding-top: 60px;
  padding-bottom: 35px;

  @include breakpoint(medium) {
    padding-top: 80px;
    padding-bottom: 60px;
  }

  @include breakpoint(large) {
    padding-top: 100px;
    padding-bottom: 60px;
  }

  @include breakpoint(xlarge) {
    padding-top: 120px;
    padding-bottom: 60px;
  }
}

.PlaybooksGrid-wrapper {
  display: flex;
  flex-direction: column;

  @include breakpoint(large) {
    display: grid;
    grid-template-rows: repeat(2, auto);
    grid-template-columns: repeat(12, 1fr);
    grid-row-gap: 0;
    grid-column-gap: 40px;
  }
}

.PlaybooksGrid-header {
  order: 1;
  text-align: center;
  color: $mt-deep-purple;

  @include breakpoint(medium) {
    grid-area: 1 / 1 / 2 / 13;
  }
}

.PlaybooksGrid-title {
  margin-bottom: 24px;
  font-size: rem-calc(30);
  line-height: unitless-calc(30, 30);

  @include breakpoint(medium) {
    margin-bottom: 26px;
    font-size: rem-calc(34);
  }

  @include breakpoint(large) {
    margin-bottom: 28px;
    font-size: rem-calc(36);
  }
  @include breakpoint(xlarge) {
    margin-bottom: 30px;
    font-size: rem-calc(38);
  }
}

.PlaybooksGrid-description {
  max-width: 400px;
  margin: 0 auto 30px;
  font-size: rem-calc(18);
  line-height: unitless-calc(24, 18);
  @include breakpoint(medium) {
    max-width: 640px;
    font-size: rem-calc(20);
    line-height: unitless-calc(26, 20);
  }

  @include breakpoint(large) {
    max-width: 750px;
    font-size: rem-calc(22);
    line-height: unitless-calc(28, 22);
  }
  @include breakpoint(xlarge) {
    max-width: 830px;
    font-size: rem-calc(24);
    line-height: unitless-calc(30, 24);
  }
}

.PlaybooksGrid-buttonGroup {
  display: flex;
  order: 2;
  justify-content: center;
  margin-bottom: 30px;
  @include breakpoint(medium) {
    grid-area: 2 / 1 / 3 / 13;
  }

  & > * {
    margin: 0 auto;
  }
}

.PlaybooksGrid-items {
  display: flex;
  flex-direction: column;
  order: 3;
  padding-top: 30px;

  @include breakpoint(medium only) {
    display: grid;
    grid-template-rows: repeat(2, auto);
    grid-template-columns: repeat(12, 1fr);
    grid-row-gap: 30px;
    grid-column-gap: 30px;
  }

  @include breakpoint(large) {
    display: grid;
    grid-area: 3 / 1 / 4 / 13;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 40px;
  }
}

.PlaybooksGrid-item {
  @include breakpoint(small only) {
    width: 100%;
    max-width: 400px;
    margin: 0 auto 45px;
    margin-bottom: 45px;
  }

  @include breakpoint(medium only) {
    display: flex;
    width: 100%;
  }

  @include breakpoint(large) {
    display: flex;
  }
}

.PlaybooksGrid-item:nth-child(1) {
  @include breakpoint(medium only) {
    grid-area: 1 / 1 / 2 / 7;
  }
}

.PlaybooksGrid-item:nth-child(2) {
  @include breakpoint(medium only) {
    grid-area: 1 / 7 / 2 / 13;
  }
}

.PlaybooksGrid-item:nth-child(3) {
  @include breakpoint(medium only) {
    grid-area: 2 / 4 / 3 / 10;
  }
}

.PlaybooksGrid-item .PlaybookCard-inner {
  // padding: 30px;
  @include breakpoint(medium) {
    padding-top: 35px;
    padding-bottom: 35px;
  }
  @include breakpoint(large) {
    padding: 50px;
  }
}

.PlaybooksGrid-item .PlaybookCard-title {
  @include breakpoint(large) {
    font-size: rem-calc(28);
  }
}

.PlaybooksGrid-item .PlaybookCard-author {
  @include breakpoint(large) {
    margin-bottom: 50px;
  }
}

.PlaybooksGrid-item .PlaybookCard-authorName {
  @include breakpoint(large) {
    font-size: rem-calc(16);
  }
}

.PlaybooksGrid-item .PlaybookCard-authorInfo {
  @include breakpoint(large) {
    font-size: rem-calc(16);
  }
}

.PlaybooksGrid-item .PlaybookCard-authorAvatar,
.PlaybooksGrid-item .PlaybookCard-authorAvatar .Avatar {
  @include breakpoint(large) {
    width: 50px;
    height: 50px;
  }
}

//
// ContentToggle
//
.PlaybooksGrid.PlaybooksGrid--contentToggle {
  .PlaybooksGrid-items {
    order: 2;
    margin-bottom: 30px;

    @include breakpoint(large) {
      grid-area: 2 / 1 / 3 / 13;
    }
  }

  .PlaybooksGrid-buttonGroup {
    order: 3;
    margin-top: 15px;

    @include breakpoint(medium) {
      grid-area: 3 / 1 / 4 / 13;
      margin-top: 30px;
    }
  }
}
