.SideMenu {
  position: sticky;
  top: 70px;
  display: block;
  width: 100%;
}

.SideMenu-list {
  padding-top: 30px;
}

.SideMenu-item {
  position: relative;
  margin: 20px 0 25px;
  padding: 0 0 0 20px;
}

.SideMenu-item::before {
  position: absolute;
  top: 6px;
  left: 0;
  display: block;
  width: 8px;
  height: 8px;
  content: '';
  transform: scale(0);
  border-radius: 50%;
  background-color: $mt-electric-purple;
  transition: transform .6s cubic-bezier(.645, .045, .355, 1); // easeInOutCubic
}

.SideMenu-item.SideMenu-item--last {
  margin: 20px 0 30px;

  &::after {
    position: absolute;
    right: 0;
    bottom: -15px;
    left: 20px;
    display: block;
    width: auto;
    height: 1px;
    content: '';
    background-color: $mt-celeste;
  }
}

.SideMenu-item.is-active::before {
  transform: scale(1);
}

.SideMenu-anchor {
  display: flex;
  // flex-direction: column;
  cursor: pointer;
  font-family: $font-colfax-bold;
  font-size: rem-calc(20);
  line-height: unitless-calc(22, 20);
  color: $mt-pigment-purple;
  transition: color .2s;
}

.SideMenu-bullet {
  width: 35px;
  padding-right: 5px;
  text-align: right;
  white-space: nowrap;
}

.SideMenu-label {
  flex: 1;
}

.SideMenu-anchor:hover,
.SideMenu-anchor:focus {
  color: $mt-electric-purple;
}

.SideMenu-item.SideMenu-item--nested .SideMenu-anchor {
  font-family: $font-colfax-regular;
}
