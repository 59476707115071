.TestimonialsBlock {
  position: relative;
  display: block;
  overflow: hidden;
}

.TestimonialsBlock-inner {
  @include xy-grid-container();

  position: relative;
}

.TestimonialsBlock-grid {
  @include breakpoint(medium) {
    display: grid;
    grid-template-rows: repeat(2, auto);
    grid-template-columns: repeat(12, 1fr);
    grid-row-gap: 0;
    grid-column-gap: 30px;
  }

  @include breakpoint(large) {
    grid-column-gap: 40px;
  }
}

.TestimonialsBlock-column {
  display: flex;
  grid-column: span 6;
  flex-direction: column;
  justify-content: flex-end;

  @include breakpoint(large) {
    grid-column: span 4;
    justify-content: flex-start;
    padding-bottom: 60px;
  }
}

.TestimonialsBlock-column:last-child {
  @include breakpoint(small only) {
    padding-bottom: 30px;
  }
  @include breakpoint(medium only) {
    grid-column: span 12;
    flex-direction: row;
    padding-bottom: 30px;

    .TestimonialsBlock-card {
      width: 50%;
    }

    .TestimonialsBlock-card:first-child {
      padding-right: 15px;
    }

    .TestimonialsBlock-card:last-child {
      padding-left: 15px;
    }
  }
}

.TestimonialsBlock-card {
  padding-top: 30px;
  transform: translateY(100px);
  opacity: 0;
  @include breakpoint(large) {
    padding-top: 40px;
  }
}

.TestimonialsBlock-column:nth-child(2) {
  @include breakpoint(large) {
    padding-top: 40px;
  }
}

.TestimonialsBlock-pattern {
  @include breakpoint(small down) {
    top: 30px;
  }

  position: absolute;
  top: 0;
  right: 0;
  left: auto;
  width: 100vw;
  height: 100%;
  opacity: 0;
}
