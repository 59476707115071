.Navigation {
  // logo
  --Navigation-logoColor: #{$mt-deep-purple};
  --Navigation-logoColor-hover: #{$mt-electric-purple};
  // default links
  --Navigation-linkColor: #{$mt-deep-purple};
  --Navigation-linkColor-hover: #{$mt-electric-purple};
  // mobile bar overwrites
  --Navigation-mobileLinkColor: #{$mt-electric-purple};
  --Navigation-mobileLinkColor-hover: #{$white};
  --Navigation-mobileBackground: #{$mt-tolopea-purple};
  // invite link overwrites
  --Navigation-inviteLinkColor--small: #{$white};
  --Navigation-inviteLinkColor--small-hover: #{$mt-deep-purple};
  --Navigation-inviteLinkColor--large: #{$mt-electric-purple};
  --Navigation-inviteLinkColor--large-hover: #{$mt-deep-purple};
  --Navigation-inviteLinkBackground: #{$mt-electric-purple};
  // scrolled bar overwrites
  --Navigation-scrolledBackgroundColor: #{$mt-tolopea-purple};
  --Navigation-scrolledLinkColor: #{$mt-electric-purple};
  --Navigation-scrolledLinkColor-hover: #{$mt-frost-blue};
  --Navigation-scrolledInviteColor: #{$white};
  --Navigation-scrolledInviteColor-hover: #{$mt-frost-blue};

  position: absolute;
  z-index: 1000;
  top: 0;
  width: calc(100% - 60px);
  height: 60px;

  @include breakpoint(large) {
    @include xy-grid-container();

    position: relative;
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: repeat(12, 1fr);
    grid-row-gap: 0;
    grid-column-gap: 40px;
    align-items: center;
    width: calc(100% - 80px);
    height: 120px;

    &::after {
      position: absolute;
      z-index: -1;
      left: 0;
      width: 200vw;
      height: 70px;
      content: ' ';
      transform: translateX(-50%) translateY(-200%);
      background-color: transparent;
      transition: transform 250ms ease-in-out;
    }
  }
}

.Navigation-link {
  @include font-futura-demi();

  color: var(--Navigation-linkColor);
  transition: color 150ms ease-in-out;

  @include breakpoint(large) {
    display: flex;
    align-items: center;
    margin: 0;
    font-size: rem-calc(18);
    line-height: unitless-calc(25, 18);
  }

  &:hover {
    color: var(--Navigation-linkColor-hover);
  }

  &.is-active {
    color: var(--Navigation-linkColor-hover);

    &:hover {
      cursor: default;
    }
  }
}

.Navigation-linkName {
  display: none;
}

.Navigation-link.Navigation-link--home {
  @include breakpoint(large) {
    position: absolute;
    margin-left: 40px;
  }
}

.Navigation-logo {
  width: 54px;
  height: 26px;
  opacity: 0;
  color: var(--Navigation-logoColor);
  transition: color 150ms ease-in-out;
}

.Navigation-logoRaccoon {
  position: absolute;
  width: 54px;
  height: 26px;
  pointer-events: none;
  opacity: 0;
  color: var(--Navigation-logoColor);
}

.Navigation-logo.Navigation--mobile {
  margin-left: 30px;

  @include breakpoint(large) {
    display: none;
  }
}

.Navigation-logoRaccoon.Navigation--mobile {
  margin-left: -64px;

  @include breakpoint(large) {
    display: none;
  }
}

.Navigation-logo.Navigation--desktop {
  display: none;

  @include breakpoint(large) {
    display: inline;
    grid-area: 1 / 1 / 2 / 3;
    width: 80px;
    height: 40px;
    transform: translateY(3px);
  }

  &:hover {
    color: var(--Navigation-logoColor-hover);
  }
}

.Navigation-logoRaccoon.Navigation--desktop {
  display: none;

  @include breakpoint(large) {
    display: inline;
    width: 95px;
    height: 71px;
    transform: translate(-7px, 18px);
  }
}

.Navigation-link.Navigation--login {
  display: none;

  @include breakpoint(large) {
    display: inline-flex;
  }
}

.Navigation-link.Navigation--invite {
  position: relative;
  display: inline-flex;
  align-items: center;
  height: 60px;
  margin-left: 30px;
  padding: 0 30px;
  background: var(--Navigation-inviteLinkBackground);

  @include breakpoint(large) {
    margin: 0;
    margin-left: 30px;
    padding: 0;
    color: var(--Navigation-inviteLinkColor--large);
    background-color: transparent;

    &:hover {
      color: var(--Navigation-inviteLinkColor--large-hover);
    }
  }
}

.Navigation-anchors {
  @include xy-grid-container();

  position: absolute;
  top: 60px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 60px;
  margin-top: 30px;
  font-size: rem-calc(16);

  @include breakpoint(400px) {
    height: 30px;
  }

  // Navigation-anchors for larger screens
  @include breakpoint(large) {
    position: relative;
    top: auto;
    grid-area: 1 / 3 / 2 / 10;
    justify-content: flex-start;
    align-items: center;
    width: auto;
    margin: 0;
    padding: 0;

    .Navigation-link {
      position: relative;
      width: auto;
      margin-left: 40px;
      word-wrap: normal;

      &:first-child {
        margin-left: 0;
      }
    }
  }

  @include breakpoint(xlarge) {
    grid-area: 1 / 4 / 2 / 10;
  }

  .Navigation-link {
    width: auto;
  }
}

.Navigation-label--short {
  @include breakpoint(medium) {
    display: none;
  }
}

.Navigation-label--full {
  @include breakpoint(small only) {
    display: none;
  }
}

// Mobile navigation bar
.Navigation-mobile {
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 60px;
  background-color: var(--Navigation-mobileBackground);

  @include breakpoint(large) {
    display: none;
  }

  .Navigation-link,
  .Navigation-logo {
    color: var(--Navigation-mobileLinkColor);

    &:hover {
      color: var(--Navigation-mobileLinkColor-hover);
    }

    &.Navigation--invite {
      color: var(--Navigation-inviteLinkColor--small);

      &:hover {
        color: var(--Navigation-inviteLinkColor--small-hover);
      }
    }
  }
}

.Navigation-app {
  display: none;

  @include breakpoint(large) {
    display: flex;
    grid-area: 5 / 9 / 1 / 13;
    justify-content: flex-end;
    align-items: center;
  }

  @include breakpoint(xlarge) {
    grid-area: 1 / 10 / 2 / 13;
  }
}

// Scrolled state styles
.has-scrolled {
  @include breakpoint(large) {
    padding-top: 120px;

    .Navigation {
      position: fixed;
      height: 70px;
      margin-left: 50%;
      transform: translateX(-50%);

      &::after {
        transform: translateX(-50%) translateY(0);
        background-color: var(--Navigation-scrolledBackgroundColor);
      }
    }

    .Navigation-logo,
    .Navigation-link {
      color: var(--Navigation-scrolledLinkColor);

      &:hover {
        color: var(--Navigation-scrolledLinkColor-hover);
      }

      &.is-active {
        color: var(--Navigation-scrolledLinkColor-hover);

        &:hover {
          cursor: default;
        }
      }
    }

    .Navigation-logoRaccoon {
      display: none;
    }

    .Navigation-link.Navigation--invite {
      color: var(--Navigation-scrolledInviteColor);

      &:hover {
        color: var(--Navigation-scrolledInviteColor-hover);
      }
    }
  }
}

//
// Color Modifiers
//

// Modifiers for homepage
.Navigation.Navigation--home,
.Navigation.Navigation--abm {
  // logo
  --Navigation-logoColor: #{$mt-frost-blue};
  --Navigation-logoColor-hover: #{$mt-electric-purple};
  // default links
  --Navigation-linkColor: #{$mt-frost-blue};
  --Navigation-linkColor-hover: #{$mt-electric-purple};
  // invite link overwrites
  --Navigation-inviteLinkColor--small: #{$white};
  --Navigation-inviteLinkColor--small-hover: #{$mt-deep-purple};
  --Navigation-inviteLinkColor--large: #{$white};
  --Navigation-inviteLinkColor--large-hover: #{$mt-electric-purple};
  --Navigation-inviteLinkBackground: #{$mt-electric-purple};
  // scrolled bar overwrites
  --Navigation-scrolledLinkColor: #{$mt-frost-blue};
  --Navigation-scrolledLinkColor-hover: #{$mt-electric-purple};
  --Navigation-scrolledInviteColor-hover: #{$mt-electric-purple};
}

// Modifiers for playbookHeader Variations
.Navigation.Navigation--electricPurple {
  // logo
  --Navigation-logoColor: #{$white};
  --Navigation-logoColor-hover: #{$mt-deep-purple};
  // default links
  --Navigation-linkColor: #{$white};
  --Navigation-linkColor-hover: #{$mt-frost-blue};
  // invite link overwrites
  --Navigation-inviteLinkColor--large: #{$mt-frost-blue};
  --Navigation-inviteLinkColor--large-hover: #{$mt-deep-purple};
  // scrolled bar overwrites
  --Navigation-scrolledLinkColor: #{$white};
  --Navigation-scrolledLinkColor-hover: #{$mt-frost-blue};
  --Navigation-scrolledInviteColor: #{$mt-frost-blue};
  --Navigation-scrolledInviteColor-hover: #{$mt-electric-purple};
}

.Navigation.Navigation--deepPurple {
  // logo
  --Navigation-logoColor: #{$white};
  --Navigation-logoColor-hover: #{$mt-electric-purple};
  // default links
  --Navigation-linkColor: #{$white};
  --Navigation-linkColor-hover: #{$mt-electric-purple};
  // invite link overwrites
  --Navigation-inviteLinkColor--large: #{$mt-frost-blue};
  --Navigation-inviteLinkColor--large-hover: #{$mt-electric-purple};
  // scrolled bar overwrites
  --Navigation-scrolledLinkColor: #{$white};
  --Navigation-scrolledLinkColor-hover: #{$mt-electric-purple};
  --Navigation-scrolledInviteColor: #{$mt-frost-blue};
  --Navigation-scrolledInviteColor-hover: #{$mt-electric-purple};
}

.Navigation.Navigation--frostBlue {
  // logo
  --Navigation-logoColor: #{$mt-deep-purple};
  --Navigation-logoColor-hover: #{$mt-electric-purple};
  // default links
  --Navigation-linkColor: #{$mt-deep-purple};
  --Navigation-linkColor-hover: #{$mt-electric-purple};
  // invite link overwrites
  --Navigation-inviteLinkColor--large: #{$mt-electric-purple};
  --Navigation-inviteLinkColor--large-hover: #{$white};
  // scrolled bar overwrites
  --Navigation-scrolledLinkColor: #{$white};
  --Navigation-scrolledLinkColor-hover: #{$mt-electric-purple};
  --Navigation-scrolledInviteColor: #{$mt-frost-blue};
  --Navigation-scrolledInviteColor-hover: #{$mt-electric-purple};
}

.Navigation.Navigation--tolopeaPurple {
  // logo
  --Navigation-logoColor: #{$mt-frost-blue};
  --Navigation-logoColor-hover: #{$mt-electric-purple};
  // default links
  --Navigation-linkColor: #{$mt-frost-blue};
  --Navigation-linkColor-hover: #{$mt-electric-purple};
  // invite link overwrites
  --Navigation-inviteLinkColor--small: #{$white};
  --Navigation-inviteLinkColor--small-hover: #{$mt-deep-purple};
  --Navigation-inviteLinkColor--large: #{$white};
  --Navigation-inviteLinkColor--large-hover: #{$mt-electric-purple};
  --Navigation-inviteLinkBackground: #{$mt-electric-purple};
  // scrolled bar overwrites
  --Navigation-scrolledBackgroundColor: #{$mt-pigment-purple};
  --Navigation-scrolledLinkColor: #{$mt-frost-blue};
  --Navigation-scrolledLinkColor-hover: #{$white};
  --Navigation-scrolledInviteColor-hover: #{$mt-electric-purple};
}
