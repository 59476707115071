.Modal-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  visibility: hidden;
  justify-content: center;
  align-items: center;

  &.Modal-open {
    z-index: 10001;
    display: flex;
    visibility: visible;
    width: 100%;
    height: 100%;
  }
}

.Modal-overlay {
  visibility: hidden;
  width: 100%;
  height: 100%;
  opacity: 0;
  background: $black;

  .Modal-open & {
    visibility: visible;
    opacity: .3;
    transition: opacity .8s ease-out;
  }
}

.Modal-container {
  @include breakpoint(small down) {
    max-width: calc(100% - 60px);
  }

  @include breakpoint(medium down) {
    min-width: 300px;
    padding: 48px 16px;
  }

  position: absolute;
  display: block;
  visibility: hidden;
  min-width: 350px;
  max-width: 350px;
  min-height: 200px;
  padding: 115px 110px 100px;
  transform: translateY(75px);
  opacity: 0;
  background: $white;

  .Modal-open & {
    visibility: visible;
    transform: translateY(0);
    opacity: 1;
    transition: transform .5s cubic-bezier(.25, .46, .45, .94), opacity .2s cubic-bezier(.25, .46, .45, .94);
  }
}

.Modal-close {
  position: absolute;
  top: 12px;
  right: 12px;
  cursor: pointer;
  color: $mt-electric-purple;

  &:hover {
    color: $mt-deep-purple;
  }
}
