.AssetLandingPage {
  @include xy-grid-container();

  margin-bottom: 80px;

  @include breakpoint(large) {
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: repeat(12, 1fr);
    grid-row-gap: 0;
    grid-column-gap: 40px;
    margin-top: 80px;
  }
  @include breakpoint(medium down) {
    margin-top: 40px;
  }
}

.AssetLandingPage-text {
  @include breakpoint(large) {
    grid-area: 1 / 1 / 2 / 8;

    h1 {
      margin-bottom: 24px;
      font-size: rem-calc(60);
      line-height: unitless-calc(72, 60);
    }

    .AssetLandingPage-description {
      margin-bottom: 24px;
      font-size: rem-calc(24);
      line-height: unitless-calc(40, 24);
    }

    .AssetLandingPage-buttonContainer {
      display: flex;

      .Button-label {
        font-size: rem-calc(24);
        line-height: unitless-calc(29, 24);
      }

      .Button {
        height: 80px;
        padding: 0 65px;
      }

      .Button-outline,
      .Button-bg {
        height: 80px;
      }
    }
  }

  @include breakpoint(medium down) {
    h1 {
      margin-bottom: 24px;
      font-size: rem-calc(36);
      line-height: unitless-calc(43, 36);
    }

    .AssetLandingPage-description {
      margin-bottom: 24px;
      font-size: rem-calc(16);
      line-height: unitless-calc(32, 16);
    }

    .AssetLandingPage-buttonContainer {
      display: flex;
      justify-content: center;
      margin-bottom: 34px;

      .Button-label {
        font-size: rem-calc(16);
        line-height: unitless-calc(19, 16);
      }
    }
  }
}

.AssetLandingPage-image {
  @include breakpoint(large) {
    grid-area: 1 / 8 / 2 / 13;
  }

  @include breakpoint(medium down) {
    padding: 0 30px;
  }

  img {
    width: 100%;
    height: auto;
  }
}
