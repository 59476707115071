.Thanks-inner {
  @include xy-grid-container();

  padding-top: 40px;
  color: $mt-deep-purple;

  @include breakpoint(large) {
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: repeat(12, 1fr);
    grid-row-gap: 0;
    grid-column-gap: 40px;
    padding-top: 60px;
  }
}

.Thanks-text {
  font-size: rem-calc(20);
  line-height: unitless-calc(22, 20);

  @include breakpoint(large) {
    grid-area: 1 / 3 / 2 / 8;
    font-size: rem-calc(36);
    line-height: unitless-calc(39, 36);
  }
}
