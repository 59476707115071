.PlaybooksFilter {
  padding: 40px 0;

  @include breakpoint(large) {
    padding: 80px 0;
  }
}

.PlaybooksFilter-inner {
  @include xy-grid-container();

  @include breakpoint(large) {
    position: relative;
    display: grid;
    grid-template-rows: repeat(2, auto);
    grid-template-columns: repeat(12, 1fr);
    grid-row-gap: 0;
    grid-column-gap: 40px;
  }
}

.PlaybooksFilter-title {
  margin-bottom: 30px;
  font-size: rem-calc(20);
  line-height: unitless-calc(18, 20);
  text-transform: uppercase;
  color: $mt-deep-purple;

  @include breakpoint(large) {
    grid-area: 1 / 3 / 2 / 12;
  }
}

.PlaybooksFilter-filters {
  max-width: 270px;
  margin-bottom: 60px;
  @include breakpoint(large) {
    display: grid;
    grid-area: 2 / 3 / 3 / 12;
    grid-template-rows: 1fr;
    grid-template-columns: repeat(9, 1fr);
    grid-row-gap: 0;
    grid-column-gap: 40px;
    max-width: none;
    margin-bottom: 0;

    & > *:first-child {
      grid-area: 1 / 1 / 2 / 4;
    }
  }
}

.PlaybooksFilter-toggle {
  @include breakpoint(large) {
    display: flex;
    grid-area: 2 / 6 / 3 / 12;
    flex-direction: row;
    justify-content: flex-end;
    min-width: 270px;
  }
}

.PlaybooksFilter-toggleList {
  display: flex;
  justify-content: flex-start;
  height: 30px;
  margin-bottom: 30px;

  @include breakpoint(large) {
    justify-content: center;
  }
}

.PlaybooksFilter-toggleItem {
  @include font-futura-demi();

  position: relative;
  margin: 0 20px;
  cursor: pointer;
  font-size: rem-calc(18);
  line-height: unitless-calc(25, 18);
  color: $mt-deep-purple;
  transition: color .2s;

  &::after {
    display: block;
    width: 100%;
    height: 1px;
    margin-top: 4px;
    content: "";
    transform: scaleX(0);
    transform-origin: 0 0;
    background: $mt-electric-purple;
    transition: transform .4s cubic-bezier(.645, .045, .355, 1); // easeInOutCubic
  }

  &.is-active {
    cursor: default;
    color: $mt-electric-purple;
  }

  &.is-active::after {
    transform: scaleX(1);
  }

  &:hover {
    cursor: pointer;
    color: $mt-electric-purple;
  }
}

.PlaybooksFilter-toggleItem:first-child {
  margin-left: 0;
}

.PlaybooksFilter-toggleItem:first-child::after {
  transform-origin: 100% 0;
}

.PlaybooksFilter-toggleItem:last-child {
  margin-right: 0;
}

.PlaybooksFilter-toggleItem:last-child::before {
  position: absolute;
  top: 0;
  bottom: 0;
  left: -20px;
  width: 1px;
  height: auto;
  content: '';
  background-color: $mt-powder-blue;
}
