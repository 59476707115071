.Solutions {
  background-color: $white;

  @include breakpoint(medium) {
    background-color: $mt-pampas-beige;
  }

  @include breakpoint(xlarge) {
    padding: 0px;
  }
}

.Solutions-inner {
  @include xy-grid-container();
  padding-top: 50px;
  padding-bottom: 50px;
  background-color: $white;

  @include breakpoint(medium) {
    padding: 60px 40px;
  }
  @include breakpoint(xlarge) {
    padding: 100px 0;
  }
}

.Solutions-text {
  @include breakpoint (medium) {
    max-width: 80%;
  }
  @include breakpoint (large) {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-row-gap: 0;
    grid-column-gap: 40px;
    max-width: none;
  }
}

.Solutions-title {
  margin-bottom: 30px;
  font-size: rem-calc(26);
  line-height: unitless-calc(24, 26);
  color: $mt-deep-purple;

  @include breakpoint(medium) {
    grid-area: 1 / 2 / 2 / 9;
    max-width: 450px;
  }
  @include breakpoint(large) {
    max-width: 480px;
    margin-bottom: 60px;
    font-size: rem-calc(32);
    line-height: unitless-calc(32, 36);
  }
  @include breakpoint(xlarge) {
    max-width: 550px;
    font-size: rem-calc(36);
  }

  span {
    color: $mt-electric-purple;
  }
}

.Solutions-grid {
  @include breakpoint(medium) {
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: repeat(12, 1fr);
    grid-row-gap: 0;
    grid-column-gap: 40px;
    margin-top: 50px;
  }

  @include breakpoint(large) {
    margin-top: 0px;
  }
}

.Solutions-wrapper {
  display: flex;
  grid-area: 1 / 1 / 2 / 13;
  flex-direction: column;

  @include breakpoint(large) {
    grid-area: 1 / 2 / 2 / 12;
  }
}

.Solutions-item {
  margin-bottom: 50px;
  padding-bottom: 50px;
  font-size: rem-calc(14);
  line-height: unitless-calc(18, 16);
  color: $mt-deep-purple;
  border-bottom: solid 1px $mt-periwinkle-gray;

  @include breakpoint(medium) {
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: repeat(10, 1fr);
    grid-row-gap: 0;
    grid-column-gap: 40px;
    font-size: rem-calc(16);
    line-height: unitless-calc(22, 18);
  }

  span {
    color: $mt-electric-purple;
  }

  &:last-of-type {
    margin-bottom: 0;
    padding-bottom: 0;
    border-bottom: none;
  }
}

.Solutions-problem {
  @include breakpoint(medium) {
    grid-area: 1 / 1 / 2 / 5;
  }
}

.Solutions-separator {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 20px 0;
  color: $mt-periwinkle-gray;

  @include breakpoint(medium) {
    grid-area: 1 / 5 / 2 / 7;
    justify-content: center;
    padding: 0;
  }

  svg {
    width: 66px;
    height: 20px;

    &:first-child {
      @include breakpoint(medium) {
        display: none;
      }
    }

    &:last-child {
      display: none;
      @include breakpoint(medium) {
        display: block;
      }
    }
  }
}

.Solutions-solution {
  @include breakpoint(medium) {
    grid-area: 1 / 7 / 2 / 11;
  }
}

.Solutions-item.is-head {
  display: none;
  padding-bottom: 0;
  font-family: $font-colfax-bold;
  font-size: rem-calc(18);
  margin-bottom: 40px;
  border-bottom: none;

  @include breakpoint(medium) {
    display: grid;
  }

  .Solutions-separator {
    display: none;
  }
}
