.InvestorsOverview {
  position: relative;
  display: block;
  padding: 0;
  background-color: $mt-pampas-beige;
}

.InvestorsOverview-inner {
  @include xy-grid-container();

  position: relative;
}

.InvestorsOverview-wrapper {
  display: grid;
  grid-template-rows: repeat(2, auto);
  grid-template-columns: repeat(12, 1fr);
  grid-row-gap: 0;
  grid-column-gap: 9px;

  @include breakpoint(medium) {
    grid-column-gap: 30px;
    background-color: transparent;
  }

  @include breakpoint(large) {
    grid-column-gap: 40px;
  }
}

.InvestorsOverview-container {
  position: relative;
  z-index: 2;
  grid-area: 1 / 1 / 2 / 13;
  max-width: 400px;
  margin: 0 auto;
  padding: 50px 0 50px;

  @include breakpoint(medium) {
    max-width: none;
    margin: 0;
    padding: 60px 30px 60px;
  }

  @include breakpoint(large) {
    padding: 80px 40px 70px;
  }

  @include breakpoint(xlarge) {
    padding: 120px 40px 110px;
  }
}

.InvestorsOverview-header {
  max-width: 400px;
  margin: 0 auto 35px;

  @include breakpoint(medium) {
    max-width: 640px;
  }

  @include breakpoint(large) {
    max-width: 840px;
    margin-bottom: 40px;
  }

  @include breakpoint(xlarge) {
    margin-bottom: 45px;
  }
}

.InvestorsOverview-title {
  font-size: rem-calc(26);
  line-height: unitless-calc(26, 26);
  text-align: center;
  color: $mt-deep-purple;
  @include breakpoint(medium) {
    font-size: rem-calc(30);
  }

  @include breakpoint(large) {
    font-size: rem-calc(34);
  }

  @include breakpoint(xlarge) {
    font-size: rem-calc(38);
  }
}

.InvestorsOverview-description {
  max-width: 510px;
  margin: 0 auto;
  text-align: center;

  p {
    margin-bottom: 32px;
    font-size: rem-calc(18);
    line-height: unitless-calc(26, 18);
    color: $mt-deep-purple;
    @include breakpoint(medium only) {
      font-size: rem-calc(16);
      line-height: unitless-calc(24, 16);
    }

    @include breakpoint(medium) {
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

.InvestorsOverview-content {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-start;
  justify-content: center;
  max-width: 400px;
  margin: 0 -13px;

  @include breakpoint(medium) {
    max-width: 720px;
    margin: 0 auto;
  }

  @include breakpoint(large) {
    max-width: 780px;
  }

  @include breakpoint(xlarge) {
    max-width: 840px;
  }
}

.InvestorsOverview-item {
  position: relative;
  width: 100%;
  max-width: calc(50% - 26px);
  min-height: 60px;
  margin-right: 13px;
  margin-bottom: 20px;
  margin-left: 13px;

  @include breakpoint(medium) {
    max-width: calc(20% - 32px);
    margin-right: 16px;
    margin-bottom: 25px;
    margin-left: 16px;
  }

  @include breakpoint(large) {
    max-width: calc(20% - 36px);
    margin-right: 18px;
    margin-bottom: 35px;
    margin-left: 18px;
  }

  @include breakpoint(xlarge) {
    max-width: calc(20% - 40px);
    margin-right: 20px;
    margin-left: 20px;
  }
}
