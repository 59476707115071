.CategoryHeader {
  position: relative;
  display: flex;
  overflow: hidden;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: calc(100vw - 60px);
  max-width: 400px;
  margin: 0 auto;
  padding: 50px 30px;
  background: transparent;

  @include breakpoint(medium) {
    width: 100vw;
    max-width: none;
    margin: 0;
    padding: 50px 0;
  }

  @include breakpoint(large) {
    padding: 90px 0;
  }
}

.CategoryHeader-titleContainer {
  width: 100%;
  max-width: 1280px;
}

.CategoryHeader-title {
  max-width: 500px;
  margin: 0 auto;
  padding: 0 0 20px 0;
  font-size: rem-calc(34);
  line-height: unitless-calc(34, 36);
  text-align: center;
  text-transform: uppercase;
  opacity: 0;
  color: $mt-electric-purple;
  transition: color .8s;

  @include breakpoint(large) {
    max-width: 700px;
    padding: 0 0 30px 0;
    font-size: rem-calc(46);
    line-height: unitless-calc(44, 46);
  }

  @include breakpoint(xlarge) {
    padding: 0 0 50px 0;
    font-size: rem-calc(54);
    line-height: unitless-calc(50, 54);
  }

  &.is-visible {
    opacity: 1;

    .CategoryHeader-titleHighlight {
      color: $mt-electric-purple;
    }
  }

  &.is-focused {
    color: $mt-frost-blue;
  }
}

.CategoryHeader-titleHighlight {
  transition: color .8s;
}

.CategoryHeader-body {
  max-width: 500px;
  margin: 0 auto;
  padding: 0 30px 30px 30px;
  transform: translateY(55px);
  font-family: $font-colfax-regular;
  font-size: rem-calc(20);
  line-height: unitless-calc(22, 20);
  text-align: center;
  opacity: 0;
  color: $mt-frost-blue;

  @include breakpoint(small down) {
    max-width: 400px;
  }

  @include breakpoint(large) {
    max-width: 700px;
    padding: 0 30px 50px 30px;
    font-size: rem-calc(24);
    line-height: unitless-calc(30, 24);
  }
}
