.Pattern {
  width: 100%;
  height: 100%;
  animation: pattern-infinite-scroll 60s linear infinite;
  background-image: url('/images/pattern_purple_360x360.png');
  background-repeat: repeat;
  background-position: 0 0;

  @include breakpoint(large) {
    background-image: url('/images/pattern_purple_720x720.png');
  }
}

@keyframes pattern-infinite-scroll {
  from {
    background-position: 0 0;
  }

  to {
    background-position: 720px -720px;
  }
}

//
// Color Modifiers
//
.Pattern--white {
  background-image: url('/images/pattern_white_720x720.png');
}

.Pattern--tolopeaPurple {
  background-image: url('/images/pattern_darkpurple_360x360.png');
  @include breakpoint(large) {
    background-image: url('/images/pattern_darkpurple_720x720.png');
  }
}

.Pattern--pampasBeige {
  background-image: url('/images/pattern_lightsand_360x360.png');
  @include breakpoint(large) {
    background-image: url('/images/pattern_lightsand_720x720.png');
  }
}
