.PlaybookLayout-inner {
  display: flex;
  @include xy-grid-container();

  @include breakpoint(large) {
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: repeat(12, 1fr);
    grid-row-gap: 0;
    grid-column-gap: 40px;
  }
}

.PlaybookLayout-aside {
  display: none;
  @include breakpoint(large) {
    display: block;
    grid-area: 1 / 1 / 2 / 4;
  }
  @include breakpoint(xlarge) {
    grid-area: 1 / 1 / 2 / 4;
  }
}

.PlaybookLayout-main {
  @include breakpoint(medium) {
    grid-area: 1 / 1 / 2 / 13;
  }
  @include breakpoint(large) {
    grid-area: 1 / 4 / 2 / 13;
  }

  @include breakpoint(xlarge) {
    grid-area: 1 / 4 / 2 / 13;
  }

  .Wysiwyg-inner {
    padding: 0;
    @include breakpoint(large) {
      grid-template-columns: repeat(8, 1fr);
    }

    @include breakpoint(xlarge) {
      grid-template-columns: repeat(9, 1fr);
    }
  }

  .Wysiwyg-text {
    @include breakpoint(large) {
      grid-area: 1 / 1 / 2 / 9;
    }

    @include breakpoint(xlarge) {
      grid-area: 1 / 1 / 2 / 8;
    }
  }
}
