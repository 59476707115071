@use 'sass:math';

.HomeCases {
  background-color: $mt-pampas-beige;

  * {
    user-select: none;
  }
}

.HomeCases-inner {
  @include xy-grid-container();

  position: relative;
}

.HomeCases-wrapper {
  display: flex;
  flex-direction: column;
  @include breakpoint(medium) {
    position: relative;
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: repeat(12, 1fr);
    grid-row-gap: 0;
    grid-column-gap: 30px;
    margin: 0 auto;
  }

  @include breakpoint(large) {
    grid-column-gap: 40px;
  }
}

.HomeCases-text {
  position: relative;
  order: 2;
  margin-bottom: 10px;
  padding-top: 45px;

  @include breakpoint(medium) {
    grid-area: 1 / 1 / 2 / 6;
    margin-bottom: 60px;
    padding-top: 30px;
  }

  @include breakpoint(large) {
    margin-bottom: 70px;
    padding-top: 65px;
  }
  @include breakpoint(xlarge) {
    grid-area: 1 / 2 / 2 / 6;
    margin-bottom: 80px;
    padding-top: 105px;
  }
}

.HomeCases-text::before {
  display: none;

  @include breakpoint(medium) {
    position: absolute;
    top: 0;
    right: -30px;
    bottom: 0;
    left: -30px;
    display: block;
    content: '';
    background-color: $white;
  }
  @include breakpoint(large) {
    right: -40px;
    left: -40px;
  }
  @include breakpoint(xlarge) {
    left: calc((((100% - (3 * #{$gutter-large})) / 4) + #{$gutter-large} + #{$gutter-large}) * -1);
  }

  @include breakpoint(1360px) {
    left: calc(((((100% - (3 * #{$gutter-large})) / 4) + #{$gutter-large}) + ((100vw - #{$global-width}) / 2)) * -1);
  }
}

.HomeCases-textContainer {
  position: relative;
  @include breakpoint(medium) {
    max-width: 400px;
  }
  @include breakpoint(xxlarge) {
    max-width: 470px;
  }
}

.HomeCases-tag {
  @include font-futura-demi();

  display: inline-flex;
  margin: 0;
  padding: 2px 5px 2px;
  font-size: rem-calc(13);
  line-height: unitless-calc(18, 13);
  color: $white;
  border-radius: 5px;
  background-color: $mt-deep-purple;

  @include breakpoint(large) {
    font-size: rem-calc(14);
    line-height: unitless-calc(22, 14);
  }

  @include breakpoint(xlarge) {
    font-size: rem-calc(16);
    line-height: unitless-calc(22, 16);
  }
}

.HomeCases-textLink {
  display: block;
}

.HomeCases-textLink:hover,
.HomeCases-textLink:focus {
  .HomeCases-title,
  .HomeCases-titleIcon {
    color: $mt-electric-purple;
  }
}

.HomeCases-title {
  max-width: 425px;
  margin-top: 20px;
  margin-bottom: 25px;
  padding-right: 25px;
  font-size: rem-calc(26);
  line-height: unitless-calc(26, 26);
  color: $mt-deep-purple;
  transition: color .15s;

  @include breakpoint(medium) {
    margin-top: 20px;
    margin-bottom: 25px;
    padding-right: 23px;
    font-size: rem-calc(22);
    line-height: unitless-calc(22, 22);
  }

  @include breakpoint(large) {
    margin-top: 25px;
    margin-bottom: 30px;
    padding-right: 20px;
    font-size: rem-calc(34);
    line-height: unitless-calc(34, 34);
  }
  @include breakpoint(xlarge) {
    margin-top: 30px;
    margin-bottom: 40px;
    padding-right: 25px;
    font-size: rem-calc(38);
    line-height: unitless-calc(38, 38);
  }
}

.HomeCases-titleIcon {
  width: 15px;
  height: 15px;
  margin-right: -18px;
  padding-left: 3px;
  transform: translateY(0);
  color: $mt-deep-purple;
  transition: color .15s;

  @include breakpoint(medium) {
    width: 13px;
    height: 13px;
    margin-right: -18px;
    padding-left: 3px;
    transform: translateY(1px);
  }

  @include breakpoint(large) {
    width: 18px;
    height: 18px;
    margin-right: -20px;
    padding-left: 3px;
    transform: translateY(0);
  }
  @include breakpoint(xlarge) {
    width: 20px;
    height: 20px;
    margin-right: -25px;
  }
}

.HomeCases-cases {
  position: relative;
  order: 1;
  margin: 0 -30px;

  @include breakpoint(medium) {
    grid-area: 1 / 6 / 2 / 13;
    margin: 0;
  }

  @include breakpoint(large) {
    grid-area: 1 / 6 / 2 / 13;
  }
}

.HomeCases-container {
  position: relative;
  padding: 0 0 0 30px;
  background-color: $mt-electric-purple;
  transition: background-color .2s ease-in-out;
  will-change: background;

  @include breakpoint(medium) {
    display: flex;
    flex-direction: column;
    width: calc((100% + #{$gutter-small}) - 30px);
    min-height: calc(100% + 60px);
    padding: 0 0 0 30px;
  }

  @include breakpoint(large) {
    display: flex;
    flex-direction: column;
    width: calc((100% + #{$gutter-large}) - (((100% - (6 * #{$gutter-large})) / 7) + #{$gutter-large}));
    padding: 0 0 0 calc((((100% - (6 * #{$gutter-large})) / 7) + #{$gutter-large}));
  }

  @include breakpoint(xlarge) {
    width: calc((100% + #{$gutter-large}) - (((100% - (6 * #{$gutter-large})) / 7) + #{$gutter-large}));
    padding: 0 0 0 calc((((100% - (6 * #{$gutter-large})) / 7) + #{$gutter-large}));
  }

  @include breakpoint(1360px) {
    width: calc((100% + ((100vw - #{$global-width}) / 2)) - (((100% - (6 * #{$gutter-large})) / 7) + #{$gutter-large}));
    padding: 0 0 0 calc((((100% - (6 * #{$gutter-large})) / 7) + #{$gutter-large}));
  }

  .HomeCases-cases.is-visible & {
    background-color: $mt-frost-blue;

    .HomeCases-scenarioLink {
      &:not(.is-active) {
        &:hover {
          color: $mt-electric-purple;
        }
      }
    }
  }
}

.HomeCases-navigation {
  position: relative;
  background-color: $mt-electric-purple;
  transition: background-color .2s ease-in-out;
  will-change: background;

  @include breakpoint(medium) {
    width: calc((100% + #{$gutter-small}));
  }

  @include breakpoint(large) {
    width: calc((100% + #{$gutter-large}));
  }

  @include breakpoint(1360px) {
    width: calc(100% + ((100vw - #{$global-width}) / 2));
  }

  .HomeCases-cases.is-visible & {
    background-color: $mt-frost-blue;
  }
}

.HomeCases-caseNavigation {
  display: flex;
  overflow: hidden;
  height: 72px;
  background-color: inherit;

  @include breakpoint(medium only) {
    height: 80px;
    margin-top: -140px;

    *:nth-child(n+4):not(.HomeCases-caseNav) {
      display: none;
    }
  }
  @include breakpoint(large) {
    height: 100px;
    margin-top: -150px;

    *:nth-child(n+5):not(.HomeCases-caseNav) {
      display: none;
    }
  }
  @include breakpoint(xlarge) {
    height: 120px;
    margin-top: -180px;
  }

  > * {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: calc(100% / 2 - 25px);
    color: $mt-frost-blue;
    border-right: solid 1px $mt-deep-purple;
    background-color: inherit;
    @include breakpoint(medium) {
      width: calc(100% / 3 - 18px);
      min-width: 0;
    }

    @include breakpoint(large) {
      width: calc(100% / 4 - 16px);
    }

    @include breakpoint(xlarge) {
      width: calc(100% / 4 - 21px);
    }

    &.is-active {
      cursor: default;
      color: $mt-deep-purple;
      background-color: inherit;
    }
  }

  &:hover {
    cursor: pointer;
  }
}

.HomeCases-caseNav {
  position: absolute;
  right: 0;
  width: 50px;
  min-width: 0;
  height: 72px;
  color: $mt-frost-blue;
  border-right: none;
  border-left: solid 1px $mt-deep-purple;
  background: $mt-tolopea-purple;

  @include breakpoint(medium) {
    width: 54px;
    height: 80px;
  }

  @include breakpoint(large) {
    width: 64px;
    height: 100px;
  }

  @include breakpoint(xlarge) {
    width: 84px;
    height: 120px;
  }

  &:hover {
    color: $mt-electric-purple;
  }
}

.HomeCases-caseNavArrow {
  width: 9px;
  height: 12px;
}

.HomeCases-caseLogo {
  position: relative;

  .HomeCases-logoImage--default {
    opacity: 0;
  }

  .HomeCases-logoImage--active {
    opacity: 1;
  }

  &.is-active {
    .is-visible & {
      .HomeCases-logoImage--default {
        opacity: 1;
      }

      .HomeCases-logoImage--active {
        opacity: 0;
      }
    }
  }
}

.HomeCases-caseLogo:not(.is-active) {
  background-color: $mt-tolopea-purple;

  @media (hover: hover) {
    &:hover {
      .HomeCases-logoImage {
        opacity: .8;
      }
    }
  }
}

.HomeCases-logoImage {
  position: absolute;
  width: 100%;
  height: 25px;
  background-color: transparent;
  // transition: opacity 150ms ease-in-out;

  @include breakpoint(medium) {
    max-width: 80%;
    height: 25px;
  }

  @include breakpoint(large) {
    max-width: 80%;
    height: 30px;
  }
  @include breakpoint(xxlarge) {
    max-width: 95%;
    height: 35px;
  }
}

.HomeCases-caseScenarios {
  @include font-futura-demi();

  position: relative;
  display: flex;
  flex-flow: wrap;
  padding: 40px 0 15px 0;
  white-space: nowrap;
  background-color: inherit;
  @include breakpoint(medium) {
    padding: 30px 0 20px 0;
  }

  @include breakpoint(large) {
    padding: 47px 0 35px 0;
  }
  @include breakpoint(xlarge) {
    padding: 55px 0 50px;
  }
}

.HomeCases-scenarioLink {
  position: relative;
  margin-right: 15px;
  margin-bottom: 15px;
  font-size: rem-calc(16);
  line-height: unitless-calc(25, 16);
  color: $mt-deep-purple;
  transition: color .2s;

  @include breakpoint(large) {
    margin-right: 20px;
    font-size: rem-calc(17);
    line-height: unitless-calc(20, 17);
  }

  @include breakpoint(xlarge) {
    margin-right: 30px;
    font-size: rem-calc(18);
    line-height: unitless-calc(25, 18);
  }

  &:not(.is-active) {
    &:hover {
      cursor: pointer;
      color: $white;
    }
  }

  &::after {
    position: absolute;
    bottom: -3px;
    left: 0;
    width: 100%;
    height: 2px;
    content: ' ';
    transform: scaleX(0);
    transform-origin: 0 0;
    background: $mt-deep-purple;
    transition: transform .4s cubic-bezier(.645, .045, .355, 1); // easeInOutCubic
    will-change: transform;

    @include breakpoint(large) {
      bottom: -8px;
    }
  }

  &.is-scalefromleft::after {
    transform-origin: 0 0;
  }

  &.is-scalefromright::after {
    transform-origin: 100% 0;
  }

  &.is-active::after {
    transform: scaleX(1);
  }
}

.HomeCases-caseScreens {
  display: flex;
  flex: 1;
  height: 100%;
  margin-right: 0;
  font-size: 0;
}

.HomeCases-screen {
  position: relative;
  display: none;
  overflow: hidden;
  width: 100%;
  border-top-left-radius: 10px;

  &::before {
    position: relative;
    display: block;
    width: 100%;
    height: 0;
    padding-bottom: percentage(math.div(510, 700));
    content: '';
  }

  &.is-active {
    display: block;
  }

  img {
    width: 100%;
  }
}

.HomeCases-anchor {
  position: absolute;
  z-index: 10;
  top: -55px;

  @include breakpoint(large) {
    transform: translateY(-260px);
  }
}

//
// Campaign Modifier
//
.HomeCases.HomeCases--campaign {
  padding-top: 40px;

  @include breakpoint(large) {
    padding-top: 75px;
  }

  @include breakpoint(xlarge) {
    padding-top: 100px;
  }

  .HomeCases-cases {
    background-color: $mt-frost-blue;
  }

  .HomeCases-caseNavigation {
    @include breakpoint(large) {
      margin-bottom: 0;
      transform: translateY(0);
    }
    @include breakpoint(xlarge) {
      margin-bottom: 0;
      transform: translateY(0);
    }

    & > * {
      border-right: solid 1px $white;
    }
  }

  .HomeCases-caseNav {
    color: $mt-deep-purple;
    border-left: solid 1px $white;
    background-color: $mt-powder-blue;
  }

  .HomeCases-caseLogo:not(.is-active) {
    background-color: $mt-powder-blue;
  }
}
