@use 'sass:math';

.AboutHeader {
  position: relative;
  z-index: 10;
  display: flex;
  align-items: flex-start;
  width: 100vw;
  margin-bottom: -60px;
  padding-top: 120px;
  transform: translateY(-60px);
  background-color: $mt-pampas-beige;

  @include breakpoint(medium) {
    padding-top: 80px;
  }

  @include breakpoint(large) {
    margin-bottom: -120px;
    padding-top: 120px;
    transform: translateY(-120px);
  }
}

.AboutHeader-inner {
  @include xy-grid-container();

  width: 100%;
}

.AboutHeader-wrapper {
  @include breakpoint(medium) {
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: repeat(12, 1fr);
    grid-row-gap: 0;
    grid-column-gap: 30px;
  }

  @include breakpoint(large) {
    grid-column-gap: 40px;
  }
}

.AboutHeader-text {
  padding-bottom: 8px;

  @include breakpoint(medium only) {
    padding-top: 30px;
    padding-bottom: 60px;
  }

  @include breakpoint(medium) {
    display: flex;
    grid-area: 1 / 1 / 2 / 7;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }

  @include breakpoint(large) {
    grid-area: 1 / 1 / 2 / 7;
  }

  @include breakpoint(xlarge) {
    grid-area: 1 / 2 / 2 / 7;
    margin-top: 0;
  }
}

.AboutHeader-title {
  margin-bottom: 20px;
  font-size: rem-calc(28);
  line-height: unitless-calc(26, 28);
  text-align: left;
  text-transform: uppercase;
  color: $mt-deep-purple;

  @include breakpoint(large) {
    margin-bottom: 30px;
    font-size: rem-calc(32);
    line-height: unitless-calc(28, 32);
  }

  @include breakpoint(xlarge) {
    margin-bottom: 40px;
    font-size: rem-calc(36);
    line-height: unitless-calc(32, 36);
  }

  p {
    margin: 0;

    b {
      color: $mt-electric-purple;
    }
  }
}

.AboutHeader-description {
  max-width: 430px;
}

.AboutHeader-description p {
  margin-bottom: 32px;
  font-size: rem-calc(18);
  line-height: unitless-calc(26, 18);
  color: $mt-deep-purple;
  @include breakpoint(medium only) {
    font-size: rem-calc(16);
    line-height: unitless-calc(24, 16);
  }
}

.AboutHeader-buttonGroup {
  display: none;
  flex-direction: column;
  justify-content: flex-start;

  @include breakpoint(medium) {
    display: flex;
  }

  @include breakpoint(large) {
    flex-direction: row;
  }
}

.AboutHeader-figure {
  @include breakpoint(small down) {
    display: block;
    margin: 0 auto;
  }
  @include breakpoint(medium only) {
    display: flex;
    justify-content: center;
    margin-right: -30px;
  }
  @include breakpoint(medium) {
    grid-area: 1 / 7 / 2 / 13;
  }
}

.AboutHeader-image {
  position: relative;
  display: block;
  width: calc(100% + 30px);
  height: auto;
  margin-right: auto;
  margin-left: auto;

  @include breakpoint(medium only) {
    flex: 1 1 auto;
  }

  @include breakpoint(large) {
    width: calc(100% + 40px);
  }

  @include breakpoint(1360px) {
    width: calc(100% + ((100vw - #{$global-width}) / 2));
    max-width: 820px;
  }

  &::before {
    position: relative;
    display: block;
    width: 100%;
    height: 0;
    padding-bottom: percentage(math.div(280, 360));
    content: '';
    @include breakpoint(medium) {
      padding-bottom: percentage(math.div(610, 700));
    }
  }

  &::after {
    position: absolute;
    z-index: 1;
    right: 0;
    bottom: 0;
    left: -30px;
    display: block;
    width: auto;
    height: 50%;
    content: '';
    background-color: $white;

    @include breakpoint(medium) {
      display: none;
    }
  }
}

.AboutHeader-imageWrap {
  position: absolute;
  z-index: 2;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.AboutHeader-story {
  @include breakpoint(medium) {
    grid-area: 2 / 1 / 3 / 13;
  }
}

.AboutHeader-storyBlock {
  position: relative;
  padding-top: 70px;
  padding-bottom: 80px;

  @include breakpoint(medium) {
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: repeat(12, 1fr);
    grid-row-gap: 0;
    grid-column-gap: 30px;
    padding-top: 65px;
    padding-bottom: 65px;
  }

  @include breakpoint(large) {
    grid-column-gap: 40px;
    padding-top: 85px;
    padding-bottom: 85px;
    background-color: $white;
  }

  @include breakpoint(xlarge) {
    padding-top: 105px;
    padding-bottom: 105px;
  }

  &::after {
    position: absolute;
    right: -30px;
    bottom: 0;
    left: -30px;
    display: block;
    width: auto;
    height: 100%;
    content: '';
    background-color: $white;

    @include breakpoint(large) {
      display: none;
    }
  }
}

.AboutHeader-storyTitle {
  position: relative;
  z-index: 2;
  margin-bottom: 25px;
  font-size: rem-calc(30);
  line-height: unitless-calc(30, 30);
  text-align: left;
  color: $mt-deep-purple;

  @include breakpoint(medium only) {
    max-width: 300px;
    font-size: rem-calc(28);
  }

  @include breakpoint(medium) {
    grid-area: 2 / 1 / 3 / 6;
  }

  @include breakpoint(large) {
    grid-area: 2 / 2 / 3 / 6;
  }

  @include breakpoint(large) {
    font-size: rem-calc(32);
  }

  p {
    margin: 0;

    b {
      color: $mt-electric-purple;
    }
  }
}

.AboutHeader-storyDescription {
  position: relative;
  z-index: 2;
  @include breakpoint(medium) {
    grid-area: 2 / 7 / 3 / 13;
  }

  @include breakpoint(large) {
    grid-area: 2 / 7 / 3 / 12;
  }

  p {
    margin-bottom: 32px;
    font-size: rem-calc(18);
    line-height: unitless-calc(26, 18);
    color: $mt-deep-purple;
    @include breakpoint(medium only) {
      font-size: rem-calc(16);
      line-height: unitless-calc(24, 16);
    }

    @include breakpoint(medium) {
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

.AboutHeader-storyButtonGroup {
  position: relative;
  z-index: 2;
  display: flex;
  @include breakpoint(medium) {
    display: none;
  }
}

.AboutHeader-storyButtonGroup .Button-link {
  @include breakpoint(small only) {
    width: auto;
    margin-right: auto;
  }
}
