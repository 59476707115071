.PageWrapper {
  margin-top: -120px;
  padding-top: 120px;

  &.PageWrapper--frostBlue {
    background-color: $mt-frost-blue;
  }

  &.PageWrapper--productPage,
  &.PageWrapper--abmPage {
    overflow: hidden;
  }

  &.is-hidden {
    display: none;
  }
}
