.CallToAction {
  --CallToAction-background-color: $black;
  --CallToAction-title-color: $black;
  --CallToAction-highlight-color: $black;
  --CallToAction-body-color: $black;

  position: relative;
  display: flex;
  overflow: hidden;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
  padding: 50px 0;
  background: var(--CallToAction-background-color);

  @include breakpoint(large) {
    padding: 150px 0;
  }
}

.CallToAction-titleContainer {
  width: 100%;
  max-width: 1280px;
}

.CallToAction-title {
  max-width: 300px;
  margin: 0 auto;
  padding: 0 0 30px 0;
  font-size: rem-calc(40);
  line-height: unitless-calc(42, 40);
  text-align: center;
  color: var(--CallToAction-title-color);

  @include breakpoint(large) {
    max-width: 620px;
    padding: 0 0 50px 0;
    font-size: rem-calc(45);
    line-height: unitless-calc(47, 45);
  }

  @include breakpoint(xlarge) {
    font-size: rem-calc(50);
    line-height: unitless-calc(52, 50);
  }

  &.is-visible {
    .CallToAction-titleHighlight {
      color: var(--CallToAction-highlight-color);
    }
  }
}

.CallToAction-titleHighlight {
  transition: color .8s;
}

.CallToAction-body {
  max-width: 400px;
  margin: 0 auto;
  padding: 0 30px 30px 30px;
  font-family: $font-colfax-regular;
  font-size: rem-calc(18);
  line-height: unitless-calc(26, 18);
  text-align: center;
  color: var(--CallToAction-body-color);

  @include breakpoint(medium) {
    max-width: 600px;
  }

  @include breakpoint(large) {
    padding: 0 30px 50px 30px;
  }
}

.CallToAction.CallToAction--deepPurple {
  --CallToAction-background-color: #{$mt-deep-purple};
  --CallToAction-title-color: #{$white};
  --CallToAction-highlight-color: #{$mt-frost-blue};
  --CallToAction-body-color: #{$white};
}

.CallToAction.CallToAction--electricPurple {
  --CallToAction-background-color: #{$mt-deep-purple};
  --CallToAction-title-color: #{$mt-electric-purple};
  --CallToAction-highlight-color: #{$mt-frost-blue};
  --CallToAction-body-color: #{$white};
}

.CallToAction.CallToAction--frostBlue {
  --CallToAction-background-color: #{$mt-frost-blue};
  --CallToAction-title-color: #{$mt-deep-purple};
  --CallToAction-highlight-color: #{$mt-electric-purple};
  --CallToAction-body-color: #{$mt-deep-purple};
}

.CallToAction.CallToAction--white {
  --CallToAction-background-color: #{$white};
  --CallToAction-title-color: #{$mt-deep-purple};
  --CallToAction-highlight-color: #{$mt-electric-purple};
  --CallToAction-body-color: #{$mt-deep-purple};
}

.CallToAction.CallToAction--transparent {
  --CallToAction-background-color: transparent;
  --CallToAction-title-color: #{$mt-electric-purple};
  --CallToAction-highlight-color: #{$mt-frost-blue};
  --CallToAction-body-color: #{$white};
}
