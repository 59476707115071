@use 'sass:math';

.ProductHeader {
  position: relative;
  z-index: 10;
  display: flex;
  align-items: center;
  width: 100vw;
  margin-bottom: -60px;
  transform: translateY(-60px);
  background: $mt-frost-blue;

  @include breakpoint(medium) {
    min-height: 500px;
  }

  @include breakpoint(large) {
    min-height: 500px;
    margin-bottom: -120px;
    transform: translateY(-120px);
  }

  @include breakpoint(xlarge) {
    min-height: 575px;
  }
}

.ProductHeader-inner {
  @include xy-grid-container();

  width: 100%;
  padding-top: 125px;
  @include breakpoint(medium) {
    padding-top: 35px;
  }
}

.ProductHeader-wrapper {
  @include breakpoint(medium) {
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: repeat(12, 1fr);
    grid-row-gap: 0;
    grid-column-gap: 30px;
  }

  @include breakpoint(large) {
    grid-column-gap: 40px;
  }
}

.ProductHeader-text {

  @include breakpoint(medium) {
    display: flex;
    grid-area: 1 / 1 / 2 / 7;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }

  @include breakpoint(large) {
    grid-area: 1 / 1 / 2 / 7;
  }

  @include breakpoint(xlarge) {
    grid-area: 1 / 2 / 2 / 7;
  }
}

.ProductHeader-title {
  margin-bottom: 20px;
  font-size: rem-calc(28);
  line-height: unitless-calc(26, 28);
  text-align: left;
  text-transform: uppercase;
  color: $mt-deep-purple;

  @include breakpoint(medium) {
    margin-bottom: 25px;
    font-size: rem-calc(30);
    line-height: unitless-calc(30, 34);
  }

  @include breakpoint(large) {
    margin-bottom: 30px;
    font-size: rem-calc(36);
    line-height: unitless-calc(32, 36);
  }

  @include breakpoint(xlarge) {
    margin-bottom: 40px;
    font-size: rem-calc(36);
    line-height: unitless-calc(32, 36);
  }

  p {
    margin: 0;
  }

  b {
    color: $mt-electric-purple;
  }
}

.ProductHeader-description {
  max-width: 430px;
}

.ProductHeader-description p {
  margin-bottom: 32px;
  font-size: rem-calc(18);
  line-height: unitless-calc(24, 18);
  color: $mt-deep-purple;
  @include breakpoint(medium) {
    font-size: rem-calc(16);
    line-height: unitless-calc(24, 16);
  }

  @include breakpoint(large) {
    font-size: rem-calc(18);
    line-height: unitless-calc(26, 18);
  }
}

.ProductHeader-buttonGroup {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  @include breakpoint(large) {
    flex-direction: row;
  }
}

.ProductHeader-buttonGroup .Button-link {
  @include breakpoint(small only) {
    width: auto;
    margin-right: auto;
  }
}

.ProductHeader-buttonGroup .Button-link:not(:first-child) {
  @include breakpoint(small only) {
    margin: 20px auto 0 0;
  }

  margin: 20px 0 0 0;
  @include breakpoint(large) {
    margin: 0 0 0 25px;
  }
}

.ProductHeader-figure {
  position: relative;
  @include breakpoint(small down) {
    display: block;
    max-width: 460px;
    margin: 0 auto;
  }
  @include breakpoint(medium only) {
    // overflow: hidden;
    margin-right: -30px;
  }
  @include breakpoint(medium) {
    grid-area: 1 / 7 / 2 / 13;
  }
}

.ProductHeader-screenImage {
  position: relative;
  display: block;
  height: auto;
  margin-right: auto;
  margin-left: auto;
  @include breakpoint(medium) {
    width: calc(100% + 36px);
    max-width: 420px;
  }

  @include breakpoint(large) {
    width: calc(100% - 40px);
    max-width: none;
    margin-bottom: -60%;
  }

  @include breakpoint(xlarge) {
    width: calc(100% - 60px);
    margin-bottom: -60%;
  }

  @include breakpoint(xxlarge) {
    width: calc(100% - 60px);
    margin-bottom: -60%;
  }

  &::before {
    position: relative;
    display: block;
    width: 100%;
    height: 0;
    padding-bottom: 100%;
    content: '';
  }
}

.ProductHeader-screenImageWrap {
  position: absolute;
  top: 39.1%;
  right: 10.5%;
  bottom: 11.3%;
  left: 10.5%;
  background-color: $black;

  @include breakpoint(medium only) {
    top: 58.1%;
    bottom: -7.7%;
  }

  @include breakpoint(large) {
    top: 33.1%;
    bottom: 17.3%;
  }
}

.ProductHeader-mask {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 5;
}

.ProductHeader-maskImage {
  position: relative;
  display: block;
  height: auto;
  margin-right: auto;
  margin-left: auto;
  @include breakpoint(medium) {
    width: calc(100% + 36px);
    max-width: 420px;
  }

  @include breakpoint(large) {
    width: calc(100% - 40px);
    max-width: none;
    margin-bottom: -60%;
  }

  @include breakpoint(xlarge) {
    width: calc(100% - 60px);
    margin-bottom: -60%;
  }

  @include breakpoint(xxlarge) {
    width: calc(100% - 60px);
    margin-bottom: -60%;
  }

  &::before {
    position: relative;
    display: block;
    width: 100%;
    height: 0;
    padding-bottom: 100%;
    content: '';
  }
}

.ProductHeader-patternContainer {
  position: absolute;
  z-index: -1;
  bottom: 0;
  left: 30px;
  width: 100vw;
  height: 10vh;
  opacity: 0;

  @include breakpoint(small only) {
    max-height: 70px;
  }

  @include breakpoint(medium) {
    top: 0;
    right: 0;
    left: auto;
    width: 33vw;
    height: 100%;
  }

  @include breakpoint(large) {
    width: 25vw;
  }
}

.ProductHeader-maskImageWrap {
  position: absolute;
  top: 0;
  right: 0;
  bottom: -6%;
  left: 0;

  @include breakpoint(medium only) {
    bottom: -25%;
  }

  @include breakpoint(large) {
    bottom: 0;
  }
}

.ProductHeader.ProductHeader--abmPage {
  background: $mt-deep-purple;

  .ProductHeader-title {
    color: $white;
  }

  .ProductHeader-description {
    color: $white;
  }

  .ProductHeader-description p {
    color: $mt-lily-white;
  }
  
  .ProductHeader-text {
    @include breakpoint(medium only) {
      grid-area: 1/1/2/6;
    }

    @include breakpoint(large) {
      height: 300px;
    }
  }
  .ProductHeader-figure {
    @include breakpoint(medium) {
      grid-area: 1/6/2/13;
      transform: translate(5%, -30px);
    }
    @include breakpoint(large) {
      transform: translate(20px, -10%);
    }
    @include breakpoint(xlarge) {
      transform: translateY(-100px);
    }
  }
}
