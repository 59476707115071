@use "sass:math";

.CaseTextBlock {
  @include xy-grid-container();

  padding-top: 30px;
  padding-bottom: 30px;

  @include breakpoint(large) {
    position: relative;
    z-index: 1;
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: repeat(12, 1fr);
    grid-row-gap: 0;
    grid-column-gap: 40px;
    padding-top: 50px;
    padding-bottom: 50px;
  }
}

.CaseTextBlock-inner {
  grid-area: 1 / 3 / 2 / 10;
}

.CaseTextBlock-timeline {
  position: relative;
  float: left;
  padding-right: 5px;
  transform: translateY(-1px);
  font-family: $font-colfax-bold-italic;
  color: $mt-electric-purple;

  @include breakpoint(large) {
    @include font-futura-demi-oblique();

    position: absolute;
    z-index: -1;
    left: math.div(map-get($grid-column-gutter, large), 2);
    float: none;
    font-size: rem-calc(18);
    line-height: unitless-calc(25, 18);
    color: $mt-deep-purple;
    transition: color 150ms ease-in-out;

    &::after {
      position: absolute;
      z-index: 1;
      top: 50%;
      width: 250px;
      height: 1px;
      background-color: $mt-deep-purple;
    }

    &:hover {
      cursor: pointer;
      color: $mt-electric-purple;
    }
  }

  &::after {
    content: ' —';

    @include breakpoint(large) {
      content: ' ';
    }
  }

  > * {
    clear: left;
  }
}

.CaseTextBlock-time {
  z-index: 2;

  @include breakpoint(large) {
    padding-right: 20px;
    background-color: $white;
  }
}

.CaseTextBlock-title {
  margin-bottom: 30px;
  font-size: rem-calc(36);
  text-transform: uppercase;
  color: $mt-deep-purple;

  @include breakpoint(large) {
    display: flex;
    flex-direction: column;
  }
}

.CaseTextBlock-description {
  margin-left: -25px;
  padding-left: 25px;
  line-height: unitless-calc(24, 16);
  background-color: $white;

  a {
    color: $mt-electric-purple;

    &:hover {
      text-decoration: underline;
    }
  }

  @include breakpoint(large) {
    font-size: rem-calc(20);
    line-height: unitless-calc(32, 20);
  }
}
