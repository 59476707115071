.ShareOptions {
  .Button {
    margin-top: 10px;
  }

  .Button-label {
    display: flex;
    flex-direction: row-reverse;

    &.Button-label--withIcon {
      margin-right: 0;
    }
  }

  .Button-labelIcon {
    padding-left: 0;
    transform: translate(-20px, -1px);
  }

  .ShareOptions-option--linkedin {
    .Button-labelIcon {
      width: 18px;
      height: 18px;
    }
  }

  .ShareOptions-option--twitter {
    .Button-labelIcon {
      width: 24px;
      height: 18px;
    }
  }

  .ShareOptions-option--email {
    .Button-labelIcon {
      width: 20px;
      height: 16px;
    }
  }

  .ShareOptions-option--copylink {
    .Button-labelIcon {
      width: 20px;
      height: 20px;
    }

    .Button {
      transition: background .5s cubic-bezier(.25, .46, .45, .94);
    }

    // state after copying url to clipboard
    .Button.is-copied {
      color: $white;
      background-color: $mt-electric-purple;
      transition: background .2s cubic-bezier(.25, .46, .45, .94);

      .Button-label {
        color: $white;
      }

      .Button-labelIcon {
        display: none;
      }
    }
  }
}
