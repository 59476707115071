.ExplainerBlock {
  position: relative;
  display: block;
  padding: 90px 0 35px;
  background-color: $mt-pampas-beige;

  @include breakpoint(medium) {
    padding: 60px 0 30px;
  }

  @include breakpoint(large) {
    padding: 90px 0 45px;
  }

  @include breakpoint(xlarge) {
    padding: 140px 0 70px;
  }
}

.ExplainerBlock-inner {
  @include xy-grid-container();

  position: relative;
}

.ExplainerBlock-wrapper {
  display: grid;
  grid-template-rows: repeat(2, auto);
  grid-template-columns: repeat(12, 1fr);
  grid-row-gap: 0;
  grid-column-gap: 9px;

  @include breakpoint(medium) {
    grid-column-gap: 30px;
  }

  @include breakpoint(large) {
    grid-column-gap: 40px;
  }
}

.ExplainerBlock-quote {
  color: $mt-electric-purple;

  .Author.Author--explainerBlock {
    margin-bottom: 55px;

    @include breakpoint(medium) {
      margin-bottom: 65px;
    }

    @include breakpoint(large) {
      margin-bottom: 35px;
    }

    @include breakpoint(xlarge) {
      margin-bottom: 35px;
    }
  }
}

.ExplainerBlock-quoteText {
  margin: 55px 0 25px;
  font-size: rem-calc(20);
  line-height: unitless-calc(24, 20);

  @include breakpoint(medium) {
    margin: 65px 0 35px;
    font-size: rem-calc(22);
    line-height: unitless-calc(26, 22);
  }

  @include breakpoint(large) {
    margin: 35px 0 35px;
    font-size: rem-calc(24);
    line-height: unitless-calc(30, 24);
  }
}

.ExplainerBlock-content {
  grid-area: 2 / 1 / 3 / 13;

  @include breakpoint(small down) {
    max-width: 400px;
    margin: 0 auto;
  }
}

.ExplainerBlock-contentWrap {
  display: grid;
  grid-template-rows: repeat(2, auto);
  grid-template-columns: repeat(12, 1fr);
  grid-row-gap: 0;
  grid-column-gap: 30px;
}

.ExplainerBlock-title {
  grid-area: 1 / 1 / 2 / 12;
  margin-bottom: 40px;
  font-size: rem-calc(30);
  line-height: unitless-calc(30, 30);
  text-align: left;
  color: $mt-deep-purple;

  @include breakpoint(medium) {
    grid-area: 1 / 1 / 2 / 5;
    margin-bottom: 0;
  }

  @include breakpoint(800px) {
    font-size: rem-calc(36);
  }
  @include breakpoint(large) {
    grid-area: 1 / 2 / 2 / 5;
  }

  @include breakpoint(xlarge) {
    grid-area: 1 / 2 / 2 / 5;
    font-size: rem-calc(38);
  }

  p {
    margin: 0;
  }
}

.ExplainerBlock-copy {
  grid-area: 2 / 1 / 3 / 13;
  margin-bottom: 30px;

  @include breakpoint(medium) {
    grid-area: 1 / 5 / 2 / 13;
    margin-bottom: 50px;
  }

  @include breakpoint(800px) {
    grid-area: 1 / 5 / 2 / 12;
  }

  @include breakpoint(xlarge) {
    grid-area: 1 / 5 / 2 / 10;
    margin-bottom: 70px;
  }

  @include breakpoint(xlarge) {
    margin-bottom: 90px;
  }
}

.ExplainerBlock-copy p {
  margin-bottom: 32px;
  font-size: rem-calc(18);
  line-height: unitless-calc(26, 18);
  color: $mt-deep-purple;

  @include breakpoint(large) {
    line-height: unitless-calc(32, 18);
  }
}

.ExplainerBlock-infographic {
  grid-area: 3 / 1 / 4 / 13;
  @include breakpoint(560px down) {
    display: flex;
    justify-content: flex-end;
  }
  @include breakpoint(medium) {
    grid-area: 3 / 1 / 4 / 13;
  }
  @include breakpoint(large) {
    grid-area: 3 / 2 / 4 / 12;
  }
  @include breakpoint(xlarge) {
    grid-area: 3 / 2 / 4 / 12;
  }
}

.ExplainerBlock-infographicMobile {
  display: block;
  max-width: 330px;
  margin-right: -30px;
  margin-left: 30px;
  @include breakpoint(560px) {
    display: none;
  }
}

.ExplainerBlock-infographicDesktop {
  display: none;
  @include breakpoint(560px) {
    display: block;
  }
}
