.CasesOverview {
  h2 {
    margin-bottom: 20px;
  }
}

.CasesOverviewList {
  display: flex;
  flex-direction: column;
}

.CasesOverviewList li {
  margin-bottom: 20px;

  &:last-child {
    margin-bottom: 0;
  }
}

.CasesOverviewList a {
  @include font-futura-demi();

  &::after {
    content: '  >';
  }
}
