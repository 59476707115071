#chili-piper-container {
  overflow: hidden;
}

@media screen and (max-width: 560px) {
  #chili-piper-container {
    width: 85vw;
  }
}

div.chilipiper-popup.in-custom-element {
  position: relative;
  background: unset;
  z-index: 0;
}
