.InputRadio {
  @include font-futura-demi();

  display: flex;
  align-items: center;
  margin-bottom: 20px;
  padding-bottom: 20px;
  font-size: rem-calc(18);
  line-height: unitless-calc(22, 18);
  color: $mt-deep-purple;
  border-bottom: solid 1px $mt-alto;
  transition: color 150ms ease-in-out;

  @include breakpoint(large) {
    font-size: rem-calc(22);
    line-height: unitless-calc(25, 22);
  }

  &:last-child {
    border-bottom: none;
  }

  &:hover {
    cursor: pointer;
    color: $mt-electric-purple;

    span {
      &::after {
        background-color: rgba($mt-alto, .5);
      }
    }
  }

  input {
    display: none;
    width: auto;
  }

  span {
    position: relative;
    overflow: hidden;
    width: 20px;
    min-width: 20px;
    height: 20px;
    margin-right: 15px;
    border: solid 1px $mt-half-baked;
    border-radius: 50%;
    background-color: $white;

    &::after {
      position: absolute;
      top: 50%;
      left: 50%;
      overflow: hidden;
      width: 10px;
      height: 10px;
      content: ' ';
      transform: translate(-50%, -50%);
      border-radius: 50%;
    }
  }

  input:checked + span {
    &::after {
      background-color: $mt-electric-purple;
    }
  }
}
