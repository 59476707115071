.Author {
  display: flex;
  flex-direction: row;
}

.Author-avatar {
  width: 48px;
  height: 48px;
  padding-right: 16px;
  @include breakpoint(large) {
    width: 60px;
    height: 60px;
  }
}

.Author-avatar .Avatar {
  width: 48px;
  height: 48px;
  @include breakpoint(large) {
    width: 60px;
    height: 60px;
  }
}

.Author-copy {
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: rem-calc(16);
  line-height: unitless-calc(22, 16);
}

.Author-name {
  @include font-futura-demi();

  display: block;
}

.Author-info {
  @include font-futura-book();

  display: block;
}

//
//  ExplainerBlock
//
.Author.Author--explainerBlock {
  margin-bottom: 70px;

  @include breakpoint(xlarge) {
    margin-bottom: 90px;
  }
}

//
//  PlaybookCard
//
.Author.Author--playbookCard {
  align-items: center;
  margin-bottom: 40px;
  color: var(--Playbook-textColor);

  .Author-avatar {
    width: 42px;
    height: 42px;
    padding-right: 16px;
  }

  .Author-avatar .Avatar {
    width: 42px;
    height: 42px;
  }

  .Author-copy {
    font-size: rem-calc(14);
    line-height: unitless-calc(16, 14);
  }
}

//
// HomeCases
//
.Author.Author--homeCases {
  align-items: center;
  margin-bottom: 40px;
  color: $mt-deep-purple;

  .Author-avatar {
    width: 50px;
    height: 50px;
    padding-right: 12px;
    @include breakpoint(large) {
      padding-right: 16px;
    }
  }

  .Author-avatar .Avatar {
    width: 50px;
    height: 50px;
  }

  .Author-copy {
    font-size: rem-calc(14);
    line-height: unitless-calc(18, 14);
    @include breakpoint(large) {
      font-size: rem-calc(16);
      line-height: unitless-calc(25, 16);
    }
  }
}

//
// PlaybookHeader
//
.Author.Author--playbookHeader {
  margin-bottom: 70px;

  @include breakpoint(large) {
    margin-bottom: 0;
  }

  .Author-avatar {
    width: 60px;
    height: 60px;
    padding-right: 20px;

    @include breakpoint(large) {
      width: 72px;
      height: 72px;
      padding-right: 30px;
    }
  }

  .Author-copy {
    font-size: rem-calc(18);
    line-height: unitless-calc(20, 18);
    @include breakpoint(large) {
      font-size: rem-calc(19);
      line-height: unitless-calc(24, 19);
    }
  }
}

//
//  TestimonialCard
//
.Author.Author--testimonialCard {
  color: var(--TestimonialCard-textColor);

  .Author-copy {
    justify-content: center;
    font-size: rem-calc(16);
    line-height: unitless-calc(22, 16);
  }
}

//
//  OgImage
//
.Author.Author--ogImage {
  margin-right: 25px;
  margin-bottom: auto;
  border-right: 1px solid var(--OgImage-textColor);

  .Author-avatar {
    padding-right: 30px;
  }

  .Author-copy {
    justify-content: center;
    padding-right: 25px;
    font-size: rem-calc(24);
    line-height: unitless-calc(22, 24);
  }

  .Author-info {
    display: none;
  }
}
