.PlaybookExcerpt {
  @include font-futura-demi();

  display: inline;
  margin-right: 7px;
  padding: 2px 0 0;
  font-size: rem-calc(13);
  font-variant-numeric: tabular-nums;
  line-height: unitless-calc(15, 13);
  color: var(--Playbook-textColor);
}

.PlaybookExcerpt-wrapper {
  display: inline-block;
  text-align: right;
}

//
// PlaybookHeader
//
.PlaybookHeader-meta .PlaybookExcerpt {
  padding: 3px 0 0;
  font-size: rem-calc(14);

  @include breakpoint(large) {
    padding: 4px 0 0;
    font-size: rem-calc(18);
  }
}
