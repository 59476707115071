.ProgressBar {
  position: relative;
  width: 100%;
  height: 10px;
  margin: auto;
  border-radius: 14px;
  background-color: $mt-blizzard-blue;
}

.ProgressBar-indicator {
  position: absolute;
  top: 0;
  left: 0;
  display: inline-flex;
  overflow: hidden;
  width: 0;
  height: 10px;
  border-radius: 10px;
  background-color: $white;
  transition: width 350ms ease-in-out;
}
