.ContentToggle {
  display: block;
  width: 100%;
  padding-top: 30px;
  background-color: $mt-pampas-beige;
  @include breakpoint(medium) {
    padding-top: 50px;
  }

  @include breakpoint(large) {
    padding-top: 70px;
  }

  @include breakpoint(xlarge) {
    padding-top: 100px;
  }
}

.ContentToggle-menu {
  @include xy-grid-container();

  position: relative;
}

.ContentToggle-menuWrapper {
  display: flex;
  justify-content: center;
  margin: 0 -30px;
  padding: 0 30px;
  border-bottom: 2px solid $mt-celeste;

  @include breakpoint(medium) {
    margin: 0;
    padding: 0;
  }
}

.ContentToggle-menuItem {
  width: 50%;
  margin-bottom: -2px;
  padding-top: 15px;
  padding-bottom: 15px;
  cursor: pointer;
  font-size: rem-calc(18);
  font-weight: 700;
  line-height: unitless-calc(38, 18);
  color: $white;
  border-width: 2px 2px 0 2px;
  border-style: solid;
  border-color: $mt-celeste;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  background-color: $mt-celeste;
  @include font-futura-demi();

  @include breakpoint(medium) {
    width: 260px;
    padding-top: 15px;
    padding-bottom: 15px;
    font-size: rem-calc(18);
    line-height: unitless-calc(40, 18);
  }

  @include breakpoint(xlarge) {
    width: 310px;
  }
}

.ContentToggle-menuItem:hover {
  color: $mt-electric-purple;
}

.ContentToggle-menuItem.is-active {
  cursor: default;
  color: $mt-electric-purple;
  background-color: $mt-pampas-beige;
}

.ContentToggle-group {
  display: none;
}

.ContentToggle-group.is-active {
  display: block;
}
