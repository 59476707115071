$logoContainerHeight: 96px;
$logoPadding: 36px;

.LogoContainer {
    height: $logoContainerHeight;
    margin: auto;
    padding-bottom: 50px;

    @include breakpoint(medium) {
        padding-bottom: 65px;
    }
}

.LogoContainer-wrapper {
    display: flex;
    width: fit-content;
    height: inherit;
    margin: auto;
    overflow: hidden;
    background-color: $white;

    border: 1px solid $mt-celeste;
    border-radius: 7px;
    box-shadow: 0px 20px 20px -10px rgba(0, 0, 0, 0.1);
}

.LogoContainer-logosWrapper {
    display: flex;
}

.LogoContainer-logo {
    display: flex;
    padding: 0 $logoPadding;

    &:not(:first-of-type):before {
        $separatorHeight: 36px;
        content: "";
        position: absolute;
        border-left: 1px solid $mt-celeste;
        height: $separatorHeight;
        margin-top: calc((#{$logoContainerHeight} - #{$separatorHeight}) / 2);
        margin-left: -$logoPadding;
    }

    img {
        max-width: 150px;
        height: 50%;
        align-self: center;
        object-fit: contain;
    }
}

.LogoContainer-plus {
    display:inline-block;
    margin: auto 10px auto 15px;
    min-width:24px;
    min-height:24px;
    text-align: center;
    line-height: 54px;
    font-size: 22px;
    color: #acad9f;

    @include breakpoint(medium only) {
        display: none;
    }
  }

.LogoContainer--ClientsOverview {
    height: 52px;
    padding: 0 0;

    .LogoContainer-wrapper {
        display: block;
        white-space: nowrap;

        @include breakpoint(medium) {
            display: flex;
        }
    }

    .LogoContainer-plus {
        width: 20%;
        margin: 0px;
        padding-left: 10px;
        vertical-align: top;
        box-sizing: border-box;

        @include breakpoint(medium) {
            padding-left: 0px;
            margin: auto 10px auto 15px;
            font-size: 28px;
        }
    }

    .LogoContainer-logosWrapper {
        display: inline-block;
        width: 80%;
        height: 100%;
        vertical-align: text-bottom;

        @include breakpoint(medium) {
            display: flex;
            width: 100%;
        }
    }

    .LogoContainer-logo {
        display: block;
        height: 100%;
        box-sizing: border-box;
        padding: 10px;

        &::before {
            content: none;
        }

        img {
            width: 90%;
            height: 100%;

            @include breakpoint(medium only) {
                width: 80%;
                margin: 0px 10%;
            }
        }
        
        @include breakpoint(medium) {
            margin: 0px 10px;
            padding: 10px 0px;
        }

        @include breakpoint(large) {
            margin-right: 15px;
        }
    }

    .LogoContainer-logo--mutiny {
        display: none;
    }
}
