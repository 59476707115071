.InputText {
  @include font-futura-demi();

  position: relative;
  display: flex;
  width: 100%;
  color: $mt-deep-purple;
  border-bottom: solid 3px $mt-half-baked;
  background: none;

  input,
  span {
    @include font-futura-demi();

    font-size: rem-calc(22);
    line-height: unitless-calc(40, 22);

    @include breakpoint(large) {
      font-size: rem-calc(32);
      line-height: unitless-calc(60, 32);
    }
  }

  span {
    position: absolute;
    left: 0;
    height: 100%;
    color: transparent;
  }

  input {
    width: 100%;
    color: $mt-deep-purple;
    border: none;
    outline: none;
    background-color: transparent;
    caret-color: $mt-deep-purple;

    &::placeholder {
      color: $mt-half-baked;
    }
  }

  &.is-active {
    &::after {
      width: 100%;
    }
  }

  &::after {
    position: absolute;
    z-index: 10;
    bottom: -3px;
    left: 0;
    width: 0;
    height: 3px;
    content: ' ';
    background-color: $mt-deep-purple;
    transition: width 300ms ease-in-out;
  }
}
