.ClientsOverview {
  position: relative;
  display: block;
  padding: 0;
  background-color: $white;

  @include breakpoint(medium) {
    padding: 0 0 40px;
    background-color: $mt-pampas-beige;
  }

  @include breakpoint(large) {
    padding: 0 0 50px;
  }

  @include breakpoint(xlarge) {
    padding: 0 0 60px;
  }
}

.ClientsOverview-inner {
  @include xy-grid-container();

  position: relative;
}

.ClientsOverview-wrapper {
  display: grid;
  grid-template-rows: repeat(2, auto);
  grid-template-columns: repeat(12, 1fr);
  grid-row-gap: 0;
  grid-column-gap: 9px;

  @include breakpoint(medium) {
    grid-column-gap: 30px;
    background-color: transparent;
  }

  @include breakpoint(large) {
    grid-column-gap: 40px;
  }
}

.ClientsOverview-container {
  position: relative;
  z-index: 2;
  grid-area: 1 / 1 / 2 / 13;
  max-width: 400px;
  margin: 0 auto;
  padding: 50px 0 70px;

  @include breakpoint(medium) {
    max-width: none;
    margin: 0;
    padding: 60px 30px 60px;
    background-color: $white;
  }

  @include breakpoint(large) {
    padding: 80px 40px 70px;
  }

  @include breakpoint(xlarge) {
    padding: 120px 40px 110px;
  }
}

.ClientsOverview-header {
  max-width: 400px;
  margin: 0 auto 35px;

  @include breakpoint(medium) {
    max-width: 640px;
  }

  @include breakpoint(large) {
    max-width: 840px;
    margin-bottom: 40px;
  }

  @include breakpoint(xlarge) {
    margin-bottom: 45px;
  }
}

.ClientsOverview-title {
  font-size: rem-calc(26);
  line-height: unitless-calc(26, 26);
  text-align: center;
  color: $mt-electric-purple;
  @include breakpoint(medium) {
    font-size: rem-calc(30);
  }

  @include breakpoint(large) {
    font-size: rem-calc(34);
  }

  @include breakpoint(xlarge) {
    font-size: rem-calc(38);
  }
}

.ClientsOverview-content {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-start;
  justify-content: center;
  max-width: 400px;
  margin: 0 -13px;

  @include breakpoint(medium) {
    max-width: 720px;
    margin: 0 auto;
  }

  @include breakpoint(large) {
    max-width: 780px;
  }

  @include breakpoint(xlarge) {
    max-width: 840px;
  }
}

.ClientsOverview-item {
  position: relative;
  width: 100%;
  max-width: calc(33.3% - 26px);
  min-height: 35px;
  margin-right: 13px;
  margin-bottom: 20px;
  margin-left: 13px;

  @include breakpoint(medium) {
    max-width: calc(20% - 32px);
    margin-right: 16px;
    margin-bottom: 25px;
    margin-left: 16px;
  }

  @include breakpoint(large) {
    max-width: calc(20% - 36px);
    margin-right: 18px;
    margin-bottom: 35px;
    margin-left: 18px;
  }

  @include breakpoint(xlarge) {
    max-width: calc(20% - 40px);
    margin-right: 20px;
    margin-left: 20px;
  }
}

.ClientsOverview-item-logo {
  max-width: none;
  width: fit-content;
}

.ClientsOverview-buttonGroup {
  display: flex;
  padding-top: 15px;
  @include breakpoint(large) {
    padding-top: 25px;
  }

  @include breakpoint(large) {
    padding-top: 35px;
  }

  & > * {
    width: auto;
    margin: 0 auto;
  }
}

.ClientsOverview-pattern {
  display: none;

  @include breakpoint(medium) {
    position: absolute;
    z-index: 1;
    top: 0;
    right: 0;
    left: 50vw;
    display: block;
    width: 50%;
    height: calc(100% + 140px);
  }

  @include breakpoint(large) {
    height: calc(100% + 173px);
  }

  @include breakpoint(xlarge) {
    height: calc(100% + 200px);
  }
}

//
// Product Page
//
.ClientsOverview--productPage {

  @include breakpoint(medium) {
    padding: 0 0 60px;
    background-color: $mt-frost-blue;
  }

  @include breakpoint(large) {
    padding: 0 0 70px;
  }

  @include breakpoint(xlarge) {
    padding: 0 0 80px;
  }

  .ClientsOverview-pattern {
    @include breakpoint(medium) {
      display: none;
    }
  }
}

.ClientsOverview--abmPage {

  @include breakpoint(medium) {
    background-color: $mt-pampas-beige;
  }

  @include breakpoint(xlarge) {
    padding: 0px;
  }

  .ClientsOverview-inner {
    background-color: $mt-cararra;
  
    @include breakpoint(medium) {
      background-color: $mt-pampas-beige;
    }
    @include breakpoint(xlarge) {
    }
  }

  .ClientsOverview-container {
    background-color: $mt-cararra;
  }

  .ClientsOverview-title {
    color: $mt-deep-purple;
  }

  .ClientsOverview-content {
    @include breakpoint(small only) {
      margin: 0px;
    }
  }

  .ClientsOverview-buttonGroup {
    flex-direction: column;
    justify-content: center;

    .Button-link:not(:first-child) {
      margin-top: 15px;
    }

    @include breakpoint(medium) {
      flex-direction: row;

      .Button-link {
        margin: 0 0;
      }

      .Button-link:not(:first-child) {
        margin-top: 0px;
        margin-left: 25px;
      }
    }
  } 

  .ClientsOverview-item,
  .ClientsOverview-item-logo {
    max-width: calc(50% - 26px);

    @include breakpoint(small only) {
      &:nth-child(7) {
        display: none;
      }
    }

    @include breakpoint(medium) {
      max-width: calc(20% - 40px);
    }
  }

  .ClientsOverview-item-logo {
    //max-width: calc(50% - 26px);

    @include breakpoint(medium) {
      //max-width: none;
    }

    @include breakpoint(large) {
      max-width: none;
    }
  }
}