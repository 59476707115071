.PlaybookHeader {
  //default when no modifier is set
  --Playbook-backgroundColor: #{$mt-electric-purple};
  --Playbook-textColor: #{$white};
  --Playbook-textColor-hover: #{$mt-frost-blue};

  position: relative;
  display: flex;
  overflow: hidden;
  align-items: center;
  width: 100vw;
  margin-bottom: -60px;
  transform: translateY(-60px);
  background: var(--Playbook-backgroundColor);

  @include breakpoint(320px down) {
    min-height: 100vh;
  }

  @include breakpoint(medium) {
    min-height: 75vh;
  }

  @include breakpoint(large) {
    // height: 100vh;
    min-height: 650px;
    // max-height: calc(100vh - 150px);
    margin-bottom: -120px;
    transform: translateY(-120px);
  }

  @include breakpoint(xlarge) {
    height: 100vh;
    max-height: calc(100vh - 150px);
  }
}

.PlaybookHeader-inner {
  @include xy-grid-container();

  width: 100%;
  margin-top: 85px;

  @include breakpoint(large) {
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: repeat(12, 1fr);
    grid-row-gap: 0;
    grid-column-gap: 40px;
    margin-top: 150px;
    margin-bottom: 100px;
  }
}

.PlaybookHeader-back {
  margin-bottom: 45px;

  @include breakpoint(large) {
    grid-area: 1 / 1 / 2 / 3;
  }

  .GoBackButton {
    --GoBackButton-color: var(--Playbook-textColor);
    --GoBackButton-color-hover: var(--Playbook-textColor-hover);
  }
}

.PlaybookHeader-text {
  color: var(--Playbook-textColor);
  @include breakpoint(large) {
    grid-area: 1 / 4 / 2 / 10;
  }

  @include breakpoint(xlarge) {
    grid-area: 1 / 4 / 2 / 9;
  }
}

.PlaybookHeader-meta {
  display: flex;
  justify-content: flex-start;
  margin: 0 0 45px;
}

.PlaybookHeader-tag {
  @include font-futura-demi();

  display: inline-flex;
  margin: 0 7px auto 0;
  padding: 2px 5px 2px;
  font-size: rem-calc(15);
  line-height: unitless-calc(23, 15);
  color: var(--Playbook-backgroundColor);
  border-radius: 4px;
  background-color: var(--Playbook-textColor);

  @include breakpoint(large) {
    margin: 0 10px auto 0;
    font-size: rem-calc(16);
    line-height: unitless-calc(23, 16);
  }
}

.PlaybookHeader-subTitle {
  @include font-futura-demi();

  display: block;
  margin-bottom: 30px;
  font-size: rem-calc(13);
  line-height: unitless-calc(16, 13);

  @include breakpoint(large) {
    margin-bottom: 45px;
    font-size: rem-calc(18);
    line-height: unitless-calc(25, 18);
  }
}

.PlaybookHeader-title {
  margin-bottom: 25px;
  font-size: rem-calc(34);
  line-height: unitless-calc(36, 34);

  @include breakpoint(large) {
    margin-bottom: 45px;
    font-size: rem-calc(48);
    line-height: unitless-calc(52, 48);
  }
}

.PlaybookHeader-illustration {
  display: flex;
  align-items: flex-end;
  margin-bottom: 90px;
  @include breakpoint(large) {
    grid-area: 1 / 10 / 2 / 13;
    justify-content: flex-end;
    margin-bottom: 0;
  }

  @include breakpoint(xlarge) {
    grid-area: 1 / 9 / 2 / 13;
  }
}

.PlaybookHeader-figureImage {
  display: block;
  max-width: 100%;
  height: auto;
}

.PlaybookHeader-figure {
  position: relative;
  width: 100%;
  max-width: 390px;
  pointer-events: none;
}

.PlaybookHeader-figure::before {
  position: relative;
  display: block;
  width: 100%;
  padding-bottom: 66%;
  content: '';
}

.PlaybookHeader-figureLayer {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  transform-origin: 0 100%;
}

.PlaybookFigure-colorOne {
  fill: var(--PlaybookFigure-colorOne);
}

.PlaybookFigure-colorTwo {
  fill: var(--PlaybookFigure-colorTwo);
}

.PlaybookFigure-colorThree {
  fill: var(--PlaybookFigure-colorThree);
}

.PlaybookFigure-colorFour {
  fill: var(--PlaybookFigure-colorFour);
}

.PlaybookFigure-colorFive {
  fill: var(--PlaybookFigure-colorFive);
}

.PlaybookHeader-figureLogo {
  position: absolute;
  width: auto;
  height: 20%;
}

.PlaybookHeader-figureLogo--pdf {
  display: none;
}

//
// Color Modifiers
//
.PlaybookHeader.PlaybookHeader--electricPurple {
  --Playbook-backgroundColor: #{$mt-electric-purple};
  --Playbook-textColor: #{$white};
  --Playbook-textColor-hover: #{$mt-frost-blue};
}

.PlaybookHeader--electricPurple.PlaybookHeader--figureOne {
  --PlaybookFigure-colorOne: #{$mt-heart-purple};
  --PlaybookFigure-colorTwo: #{$mt-violet-purple};
  --PlaybookFigure-colorThree: #{$mt-indigo-purple};
  --PlaybookFigure-colorFour: #{$mt-violet-purple};
}

.PlaybookHeader--electricPurple.PlaybookHeader--figureTwo {
  --PlaybookFigure-colorOne: #{$mt-heart-purple};
  --PlaybookFigure-colorTwo: #{$mt-violet-purple};
  --PlaybookFigure-colorThree: #{$mt-indigo-purple};
  --PlaybookFigure-colorFour: #{$mt-violet-purple};
  --PlaybookFigure-colorFive: #{$mt-indigo-purple};
}

.PlaybookHeader--electricPurple.PlaybookHeader--figureThree {
  --PlaybookFigure-colorOne: #{$mt-heart-purple};
  --PlaybookFigure-colorTwo: #{$mt-violet-purple};
  --PlaybookFigure-colorThree: #{$mt-indigo-purple};
  --PlaybookFigure-colorFour: #{$mt-violet-purple};
}

.PlaybookHeader.PlaybookHeader--deepPurple {
  --Playbook-backgroundColor: #{$mt-deep-purple};
  --Playbook-textColor: #{$mt-frost-blue};
  --Playbook-textColor-hover: #{$mt-electric-purple};
}

.PlaybookHeader--deepPurple.PlaybookHeader--figureOne {
  --PlaybookFigure-colorOne: #{$mt-seance-purple};
  --PlaybookFigure-colorTwo: #{$mt-electric-purple};
  --PlaybookFigure-colorThree: #{$mt-violet-purple};
  --PlaybookFigure-colorFour: #{$mt-indigo-purple};
}

.PlaybookHeader--deepPurple.PlaybookHeader--figureTwo {
  --PlaybookFigure-colorOne: #{$mt-muave-purple};
  --PlaybookFigure-colorTwo: #{$mt-electric-purple};
  --PlaybookFigure-colorThree: #{$mt-violet-purple};
  --PlaybookFigure-colorFour: #{$mt-violet-purple};
  --PlaybookFigure-colorFive: #{$mt-indigo-purple};
}

.PlaybookHeader--deepPurple.PlaybookHeader--figureThree {
  --PlaybookFigure-colorOne: #{$mt-muave-purple};
  --PlaybookFigure-colorTwo: #{$mt-electric-purple};
  --PlaybookFigure-colorThree: #{$mt-violet-purple};
  --PlaybookFigure-colorFour: #{$mt-indigo-purple};
}

.PlaybookHeader.PlaybookHeader--frostBlue {
  --Playbook-backgroundColor: #{$mt-powder-blue};
  --Playbook-textColor: #{$mt-deep-purple};
  --Playbook-textColor-hover: #{$mt-electric-purple};
}

.PlaybookHeader--frostBlue.PlaybookHeader--figureOne {
  --PlaybookFigure-colorOne: #{$white};
  --PlaybookFigure-colorTwo: #{$white};
  --PlaybookFigure-colorThree: #{$mt-iceberg-blue};
  --PlaybookFigure-colorFour: #{$mt-powder-blue};
}

.PlaybookHeader--frostBlue.PlaybookHeader--figureTwo {
  --PlaybookFigure-colorOne: #{$white};
  --PlaybookFigure-colorTwo: #{$white};
  --PlaybookFigure-colorThree: #{$mt-iceberg-blue};
  --PlaybookFigure-colorFour: #{$mt-iceberg-blue};
  --PlaybookFigure-colorFive: #{$mt-powder-blue};
}

.PlaybookHeader--frostBlue.PlaybookHeader--figureThree {
  --PlaybookFigure-colorOne: #{$white};
  --PlaybookFigure-colorTwo: #{$white};
  --PlaybookFigure-colorThree: #{$mt-iceberg-blue};
  --PlaybookFigure-colorFour: #{$mt-powder-blue};
}

.PlaybookHeader--figureOne .PlaybookHeader-figureLogo {
  bottom: 6.75%;
  left: 7.5%;
}

.PlaybookHeader--figureTwo .PlaybookHeader-figureLogo {
  top: 6.75%;
  right: 7.5%;
}

.PlaybookHeader--figureThree .PlaybookHeader-figureLogo {
  right: 7.5%;
  bottom: 32.5%;
}
