$urlBarHeight: 52px;
$editorButtonHeight: 43px;

.EditableWindow {
    width: inherit;
    height: 260px;
    background-color: $white;

    @include breakpoint(medium) {
        height: 310px;
    }

    @include breakpoint(large) {
        height: 370px;  
    }
}

.EditableWindow-urlBar {
    height: $urlBarHeight;
    padding: 10px;
    box-sizing: border-box;
    background-color: $mt-pampas-beige;
}

.EditableWindow-urlBar-inner {
    display: flex;
    align-items: center;
    height: 100%;
    box-sizing: border-box;
    border-radius: 7px;
    background-color: $mt-cararra;
}

.EditableWindow-urlBar-url {
    padding: 0.8em;
    font-family: $font-proxima-bold;
    font-weight: 700;
    overflow: hidden;

    @include breakpoint(medium only) {
        font-size: 13px;
    }
}

.EditableWindow-content {
    display: flex;
    height: 100%;
}

.EditableWindow-content--withUrl {
    height: calc(100% - #{$urlBarHeight});
}

.EditableWindow-page {
    flex: 3;
    padding: 25px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &.highlightedTitle {
        h3 {
            padding-left: 0.2em;
            border: 2px dashed #AA00FF;
        }
    }

    p {
        font-size: clamp(5px, 16px, 2vw);

        @include breakpoint(medium only) {
            font-size: 12px;
            margin-bottom: 10px;
        }
    }


    @include breakpoint(medium) {
        padding: 20px; 
    }

    @include breakpoint(xlarge) {
        padding: 40px;  
    }
}

.EditableWindow-page-logo {
    height: 30px;
    max-width: 70px;

    div {
        height: inherit;
    }

    @include breakpoint(large) {
        max-width: 150px;
    }
}

.EditableWindow-page h3 {
    width: fit-content;
    padding: 0.2em 0.2em 0.2em 0px;

    font-family: 'Helvetica', 'Arial', sans-serif;
    font-weight: 700;
    font-size: clamp(10px, 38px, 5vw);

    box-sizing: border-box;

    @include breakpoint(medium only) {
        margin-bottom: 10px;
        font-size: 3vw;
    }
}

.EditableWindow-page p:empty {
    display: none;
}

.EditableWindow-page-button {
    width: fit-content;
    padding: 0.8em 1.4em;

    font-family: $font-proxima-bold;
    font-weight: 700;
    font-size: clamp(5px, 16px, 2vw);
    
    border-radius: 50px;
    color: $white;
    background-color: #1976D2;

    @include breakpoint(medium only) {
        font-size: 10px;
    }
}

.EditableWindow-editor {
    flex: 2;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 10px;
    border-left: 1px solid $mt-celeste;
}

.EditableWindow-editor-blockContainer {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.EditableWindow-editor-block {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 10px;
    border: 1px solid $mt-celeste;
}

.EditableWindow-editor-block--isButtonEditor {
    height: auto;
    .EditableWindow-editor-block-content {
        padding: 0.7em 1em;
    }
}

.EditableWindow-editor-block-content {
    height: 100%;
    padding: 1em;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.EditableWindow-editor-block-suggestions {
    margin: -1px;
    display: flex;
    justify-content: space-between;
    padding: 0.7em;
    border: 1px solid $mt-electric-purple;
    background: #AA00FF1A;
    color: $mt-electric-purple;
    font-size: clamp(5px, 14px, 1.8vw);

    div {
        display: flex;
        align-items: center;
    }

    svg {
        width: 15px;
        height: 15px;
        margin-right: 10px;
    }
}

.EditableWindow-editor-block-text b {
    font-family: $font-colfax-regular;
    color: $mt-electric-purple;
}

.EditableWindow-editor-block-text p {
    font-size: clamp(5px, 16px, 2.2vw);
    margin-bottom: 0px;

    @include breakpoint(medium only) {
        font-size: 12px;
    }
}

.EditableWindow-editor-block-variable {
    bottom: 0px;
    font-size: clamp(5px, 14px, 1.8vw);
    color: #88B1BA;
}

.EditableWindow-editor-buttons {
    max-width: 40vw;
    display: flex;
    height: 28px;
    justify-content: space-between;

    @include breakpoint(large) {
        height: 35px;
    }
}

.EditableWindow-editor-paragraph {
    display: flex;
    height: inherit;
    margin-right: 10px;
    border: 1px solid $mt-celeste;
    box-sizing: border-box;
}

.EditableWindow-editor-paragraph-button {
    display: flex;
    align-items: center;
    padding: 4px 10px;

    @include breakpoint(large) {
        padding: 12px 20px;
    }

    svg {
        width: 12px;
        height: 12px;

        @include breakpoint(medium only) {
            width: 9px;
            height: 9px;
        }

        @include breakpoint(large) {
            width: 15px;
            height: 15px;
        }
    }
}

.EditableWindow-editor-paragraph-button:nth-child(2) {
    background-color: $mt-powder-blue;
}

.EditableWindow-editor-publish {
    display: flex;
    align-items: center;
    width: fit-content;
    padding: 0px 1.3rem;

    font-family: $font-proxima-bold;
    font-weight: 700;
    font-size: clamp(5px, 16px, 2.2vw);

    color: $white;
    background: #AA00FF;

    @include breakpoint(medium only) {
        font-size: 12px;
    }
}