.Percentage {
  white-space: nowrap;

  @include breakpoint(medium) {
    height: 250px;
  }

  @include breakpoint(large) {
    height: 174px;
    padding-bottom: 46px;
  }
}

.Percentage-inner {
  display: inline-block;
  opacity: 0;
}

.Percentage-arrow {
  display: inline-block;
  width: 100px;
  min-width: 100px;
  height: 115px;
  margin-right: 20px;
  transform: translateY(174px);
  color: $white;

  @include breakpoint(large) {
    height: 174px;
    margin-left: 0;
  }
}

.Percentage-label {
  display: inline-block;
  font-family: $font-colfax-regular;
  font-size: rem-calc(157);
  white-space: nowrap;
  color: $white;

  @include breakpoint(large) {
    font-size: rem-calc(240);
  }
}
