.SummaryBlock {
  position: relative;
  margin: -50px 0 20px;

  @include breakpoint(large) {
    display: grid;
    grid-template-columns: repeat(9, 1fr);
    grid-row-gap: 0;
    grid-column-gap: 40px;
    margin: -68px 0 60px;
  }
}

.SummaryBlock-inner {
  border: 1px solid $mt-powder-blue;
  border-radius: 10px;
  background-color: $mt-twilight-blue;

  @include breakpoint(large) {
    grid-area: 1/1/2/10;
    border-radius: 10px;
  }

  @include breakpoint(xlarge) {
    grid-area: 1/1/2/8;
    border-radius: 10px;
  }
}

.SummaryBlock-section {
  position: relative;
  display: block;
  padding: 50px 20px 40px;

  @include breakpoint(large) {
    padding: 50px 50px 40px;
  }

  &::before {
    position: relative;
    display: block;
    width: 0;
    height: 140px;
    margin-top: -140px;
    content: '';
  }
}

.SummaryBlock-section:not(:first-child) {
  padding-top: 45px;

  @include breakpoint(large) {
    padding-top: 55px;
  }
}

.SummaryBlock-section:not(:first-child)::after {
  position: absolute;
  top: 0;
  right: 10px;
  left: 10px;
  height: 1px;
  content: '';
  background-color: $mt-powder-blue;
}

.SummaryBlock-title {
  position: relative;
  margin: 0 0 25px;
  padding: 0 0 0 45px;
  font-size: rem-calc(20);
  line-height: unitless-calc(22, 20);
  color: $mt-pigment-purple;

  @include breakpoint(large) {
    margin: 0 0 40px;
    padding: 0 50px 0;
  }
}

.SummaryBlock-anchor {
  position: relative;
  display: block;
  width: 0;
  height: 140px;
  margin-top: -140px;
  content: '';
}

.SummaryBlock-list {
  padding: 0 0 0 45px;

  @include breakpoint(large) {
    padding: 0 50px 0;
  }
}

.SummaryBlock-item {
  position: relative;
  margin: 0 0 25px 0;

  @include breakpoint(large) {
    margin: 0 0 40px 0;
  }
}

.SummaryBlock-item:last-child {
  margin-bottom: 0;
}

.SummaryBlock-bullet {
  position: absolute;
  left: -42px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  border: 2px solid $mt-electric-purple;
  border-radius: 50%;

  @include breakpoint(large) {
    left: -40px;
  }
}

.SummaryBlock-bulletIcon {
  width: 12px;
  height: 12px;
  color: $mt-electric-purple;
}

.SummaryBlock-label {
  display: inline-block;
  padding-top: 2px;
  font-size: rem-calc(18);
  line-height: unitless-calc(26, 18);
  color: $mt-pigment-purple;

  @include breakpoint(large) {
    font-size: rem-calc(18);
    line-height: unitless-calc(20, 18);
  }
}

.SummaryBlock-buttonGroup {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 0 25px 35px;

  @include breakpoint(medium) {
    flex-direction: row;
    padding: 30px 60px 60px;
  }
}

.SummaryBlock-buttonGroup .Button:not(:first-child) {
  margin: 20px 0 0 0;
  @include breakpoint(medium) {
    margin: 0 0 0 25px;
  }
}
