
.CalendlyWidget {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  height: 100%;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    min-height: 100%;
    border: 0;
  }
}
