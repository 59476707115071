.CaseItem {
  display: flex;
  flex-direction: column;
  padding-bottom: 55px;
  color: $mt-deep-purple;
  border-bottom: solid 1px $mt-periwinkle-gray;
  transition: color 150ms ease-in-out, border-color 150ms ease-in-out;
}

.CaseItem:hover {
  color: $mt-electric-purple;
}

.CaseItem-image {
  padding-bottom: 20px;

  img {
    height: 30px;
  }
}

.CaseItem-title {
  padding-bottom: 20px;
  text-transform: uppercase;

  @include breakpoint(large) {
    font-size: rem-calc(22);
    line-height: unitless-calc(24, 22);
  }

  @include breakpoint(xlarge) {
    font-size: rem-calc(26);
    line-height: unitless-calc(30, 26);
  }
}

.CaseItem-icon {
  width: 6px;
  height: 12px;
}

.CaseItem-story {
  @include font-futura-demi();

  display: flex;
  align-items: center;
  margin-top: auto;
}

.CaseItem-label {
  padding-right: 5px;
}
