html {
  visibility: visible;
  font-size: rem-calc($global-font-size);
  line-height: unitless-calc(24);
  opacity: 1;
  background-color: $mt-deep-purple;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

body {
  position: relative;
  overflow-x: hidden;
  height: auto;
  padding-top: 120px; // navigation space
  -webkit-tap-highlight-color: rgba($black, 0);
  font-family: $font-body-text;
  font-weight: 400;
  font-style: normal;
  text-decoration-skip-ink: none;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  background-color: $white;
  -webkit-overflow-scrolling: touch;

  @include breakpoint(large) {
    padding-top: 0;
  }
}

a {
  text-decoration: none;
  text-decoration-skip-ink: none;
}

h1,
h2,
h3,
h4 {
  font-family: $font-headings;
}

p {
  margin-bottom: 20px;
}

strong,
b {
  font-family: $font-colfax-bold;

  i {
    font-family: $font-colfax-bold-italic;
  }
}

i {
  font-family: $font-colfax-regular-italic;
}
