.GoBackButton {
  --GoBackButton-color: #{$mt-deep-purple};
  --GoBackButton-color-hover: #{$mt-electric-purple};

  color: var(--GoBackButton-color);
  transition: color 150ms ease-in-out;

  &:hover {
    cursor: pointer;
    color: var(--GoBackButton-color-hover);
  }
}

.GoBackButton-icon {
  width: 100%;
  width: 44px;
  height: 12px;

  @include breakpoint(large) {
    width: auto;
    max-width: 100%;
    height: 18px;
  }
}

//
// Color Modifiers
//
.GoBackButton.GoBackButton--electricPurple {
  --GoBackButton-color: #{$mt-electric-purple};
  --GoBackButton-color-hover: #{$mt-deep-purple};
}

//
// Exceptions
//
.Header.Header--electricPurple,
.Header.is-detail {
  .GoBackButton {
    --GoBackButton-color: #{$mt-electric-purple};
    --GoBackButton-color-hover: #{$mt-deep-purple};
  }
}
