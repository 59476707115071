.CasesGrid {
  padding-bottom: 50px;

  @include breakpoint(large) {
    padding-bottom: 100px;
  }
}

.CasesGrid-inner {
  @include xy-grid-container();

  display: grid;
  grid-row-gap: 45px;
  grid-column-gap: 40px;

  @include breakpoint(large) {
    grid-template-rows: 1fr;
    grid-template-columns: repeat(12, 1fr);
  }
}

.CasesGrid-items {
  display: grid;
  grid-row-gap: 45px;
  grid-column-gap: 40px;

  @include breakpoint(medium) {
    grid-template-columns: repeat(10, 1fr);

    * {
      grid-column: span 5;
    }
  }
  @include breakpoint(large) {
    grid-area: 1 / 3 / 2 / 12;
    grid-template-columns: repeat(9, 1fr);

    * {
      grid-column: span 3;
    }
  }

  @include breakpoint(xlarge) {
    grid-column-gap: 40px;
  }
}
