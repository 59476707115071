.VacanciesBlock {
  position: relative;
  background-color: $mt-frost-blue;
}

.VacanciesBlock-inner {
  @include xy-grid-container();

  position: relative;
  padding-top: 50px;
  padding-bottom: 60px;

  @include breakpoint(medium) {
    padding-top: 90px;
    padding-bottom: 70px;
  }

  @include breakpoint(large) {
    padding-top: 120px;
    padding-bottom: 100px;
  }

  @include breakpoint(xlarge) {
    padding-top: 180px;
    padding-bottom: 160px;
  }
}

.VacanciesBlock-wrapper {
  display: flex;
  flex-direction: column;

  @include breakpoint(medium) {
    display: grid;
    grid-template-rows: repeat(2, auto);
    grid-template-columns: repeat(12, 1fr);
    grid-row-gap: 30px;
    grid-column-gap: 30px;
  }

  @include breakpoint(large) {
    grid-row-gap: 40px;
    grid-column-gap: 40px;
  }
}

.VacanciesBlock-copy {
  max-width: 400px;
  margin: 0 auto;

  @include breakpoint(medium) {
    grid-area: 1 / 2 / 2 / 12;
    text-align: center;
  }

  @include breakpoint(large) {
    grid-area: 1 / 4 / 2 / 10;
  }
}

.VacanciesBlock-title {
  margin-bottom: 15px;
  font-size: rem-calc(30);
  line-height: unitless-calc(30, 30);
  color: $mt-deep-purple;

  @include breakpoint(medium) {
    margin-bottom: 20px;
    font-size: rem-calc(34);
  }

  @include breakpoint(large) {
    margin-bottom: 25px;
    font-size: rem-calc(36);
  }
  @include breakpoint(xlarge) {
    margin-bottom: 30px;
    font-size: rem-calc(38);
  }

  p {
    margin: 0;
  }
}

.VacanciesBlock-description {
  margin: 0 auto 10px;
  font-size: rem-calc(18);
  line-height: unitless-calc(26, 18);
  color: $mt-deep-purple;

  @include breakpoint(medium) {
    max-width: 420px;
    margin-bottom: 20px;
  }

  @include breakpoint(large) {
    margin-bottom: 30px;
  }

  @include breakpoint(xlarge) {
    margin-bottom: 40px;
  }
}

.VacanciesBlock-embed {
  max-width: 400px;
  min-height: 330px;
  margin: 0 auto;

  @include breakpoint(medium) {
    grid-area: 2 / 2 / 3 / 12;
    max-width: none;
    margin: 0;
  }

  @include breakpoint(large) {
    grid-area: 2 / 4 / 3 / 10;
  }

  /* stylelint-disable selector-class-pattern */
  .job-board-items {
    display: flex;
    flex-direction: column;
  }

  .job-board-item {
    padding: 26px 0 25px;
    border-bottom: 1px solid $mt-powder-blue;

    @include breakpoint(medium) {
      padding: 19px 0 18px;
    }

    @include breakpoint(large) {
      padding: 26px 0 25px;
    }

    @include breakpoint(xlarge) {
      padding: 26px 0 25px;
    }
  }

  h2.job-board-group {
    display: none;
  }

  .job-board-title {
    margin-bottom: 10px;

    @include breakpoint(medium) {
      margin-bottom: 8px;
    }

    @include breakpoint(large) {
      margin-bottom: 10px;
    }
  }

  .job-board-title > a {
    position: relative;
    padding-right: 25px;
    font-size: rem-calc(18);
    line-height: unitless-calc(26, 18);
    color: $mt-deep-purple;

    &:hover,
    &:focus {
      color: $mt-electric-purple;

      &::after {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 24'%3E%3Cpath fill='%23AA00FF' fill-rule='evenodd' clip-rule='evenodd' d='M8.5 12L.2 3.8 3.8.2 15.5 12 3.8 23.8.2 20.2 8.5 12z' /%3E%3C/svg%3E");
      }
    }

    &::after {
      display: inline-block;
      width: 7px;
      height: 12px;
      margin-left: 5px;
      content: '';
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 24'%3E%3Cpath fill='%233A0068' fill-rule='evenodd' clip-rule='evenodd' d='M8.5 12L.2 3.8 3.8.2 15.5 12 3.8 23.8.2 20.2 8.5 12z' /%3E%3C/svg%3E");
      background-repeat: no-repeat;
    }
  }

  .job-board-info {
    display: inline;
    font-size: rem-calc(16);
    line-height: unitless-calc(18, 16);
    color: $mt-electric-purple;

    @include breakpoint(medium) {
      font-size: rem-calc(17);
      line-height: unitless-calc(20, 17);
    }

    @include breakpoint(large) {
      font-size: rem-calc(18);
      line-height: unitless-calc(22, 18);
    }
  }

  .job-board-dept,
  .job-board-location {
    display: inline-flex;
  }

  .job-board-dept::after {
    margin-right: 2px;
    margin-left: 5px;
    content: '•';
    transform: translateY(1px);
  }

  .job-board-date,
  .job-board-dept > *:first-child,
  .job-board-location > *:first-child {
    display: none;
  }
  /* stylelint-enable selector-class-pattern */
}
