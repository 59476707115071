.Footer {
  display: block;
  overflow: hidden;
  padding: 25px 0;
  background-color: $mt-electric-purple;

  @include breakpoint(large) {
    padding: 150px 0;
  }

  a {
    color: $black;
    transition: color 150ms ease-in-out;

    &:hover {
      color: rgba($black, .4);
    }
  }
}

.Footer-inner {
  @include xy-grid-container();

  overflow: hidden;

  @include breakpoint(large) {
    overflow: visible;
    grid-area: 1 / 3 / 2 / 11;
  }
}

.Footer-columns {
  display: grid;
  grid-template-rows: repeat(2, auto);
  grid-template-columns: repeat(2, 1fr);
  grid-row-gap: 0;
  grid-column-gap: 40px;

  @include breakpoint(large) {
    grid-template-rows: 1fr;
    grid-template-columns: repeat(12, 1fr);
    grid-row-gap: 0;
    grid-column-gap: 40px;
  }
}

.Footer-column {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding-bottom: 0;

  @include breakpoint(large) {
    padding-bottom: 80px;
  }

  // racoon element
  &:nth-child(1) {
    display: none;

    @include breakpoint(large) {
      display: flex;
      grid-area: 1 / 3 / 2 / 5;
    }
  }

  &:nth-child(2) {
    grid-area: 1 / 1 / 2 / 2;

    @include breakpoint(large) {
      grid-area: 1 / 5 / 2 / 7;
    }
  }

  &:nth-child(3) {
    grid-area: 1 / 2 / 2 / 3;

    @include breakpoint(large) {
      grid-area: 1 / 7 / 2 / 9;
    }
  }

  &:nth-child(4) {
    grid-area: 2 / 1 / 3 / 3;
    padding-top: 40px;
    border-top: solid 1px $mt-deep-purple;

    @include breakpoint(large) {
      grid-area: 1 / 9 / 2 / 11;
      padding-top: 0;
      border-top: none;
    }
  }
}

.Footer-link {
  @include font-futura-demi();

  padding-bottom: 40px;
  font-size: rem-calc(18);
  line-height: unitless-calc(18, 18);
}

.Footer-link--short {
  @include breakpoint(xlarge) {
    display: none;
  }
}

.Footer-link--full {
  @include breakpoint(large down) {
    display: none;
  }
}

.Footer-logo {
  display: none;
  width: 107px;
  height: 73px;

  @include breakpoint(large) {
    display: inline-flex;
  }
}

.Footer-linkName {
  display: none;
}

.Footer-disclaimer {
  @include font-futura-demi();

  padding-top: 40px;
  font-size: rem-calc(13);
  border-top: solid 1px $mt-deep-purple;

  @include breakpoint(large) {
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: repeat(12, 1fr);
    grid-row-gap: 0;
    grid-column-gap: 40px;
    font-size: rem-calc(18);
    border-top: none;
  }

  p:nth-child(2) {
    flex: 1;
    text-decoration: line-through;
    color: $mt-electric-purple;
  }

  p:last-child {
    flex-wrap: nowrap;
    margin-right: 0;
    margin-left: 20px;
    text-align: right;
    color: $mt-frost-blue;
  }
}

.Footer-disclaimerInner {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;

  @include breakpoint(large) {
    display: flex;
    grid-area: 1 / 3 / 2 / 11;
    justify-content: space-between;
    align-items: center;
    padding-top: 50px;
    border-top: solid 1px $mt-deep-purple;
  }
}

.Footer-disclaimerLogo {
  width: 52px;
  min-width: 52px;
  height: 36px;
  margin-right: 20px;

  @include breakpoint(large) {
    display: none;
  }
}
