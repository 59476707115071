
.RequestInvite {
  --RequestInvite-color: #{$mt-electric-purple};
  --RequestInvite-focus-color: #{$white};

  width: 100%;
  max-width: 600px;

  @include breakpoint(large) {
    max-width: 600px;
    height: 50px;
  }
}

.RequestInvite-inner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @include breakpoint(medium) {
    flex-direction: row;
  }
}

.RequestInvite-inputContainer {
  position: relative;
  margin-bottom: 25px;
  transform: translateY(50px);
  opacity: 0;
  transition: transform .6s cubic-bezier(.215, .61, .355, 1), opacity .6s cubic-bezier(.215, .61, .355, 1); // easeOutCubic
  transition-delay: .5s;

  .RequestInvite-container.is-visible & {
    transform: translateY(0);
    opacity: 1;
  }

  @include breakpoint(medium) {
    margin-top: -2px;
    margin-right: 20px;
    margin-bottom: 0;
  }

  @include breakpoint(xlarge) {
    margin-right: 30px;
  }

  & input {
    @include font-futura-demi();

    height: 50px;
    font-size: rem-calc(18);
    color: var(--RequestInvite-focus-color);
    border: none;
    outline: none;
    background: none;
  }

  & input::placeholder {
    color: var(--RequestInvite-color);
    transition: color .3s;
  }

  &::before {
    position: absolute;
    bottom: 0;
    display: block;
    width: 100%;
    height: 2px;
    margin-top: -2px;
    content: " ";
    transform: scaleX(1);
    transform-origin: 0 0;
    background: var(--RequestInvite-color);
    transition: background-color .3s, transform .6s cubic-bezier(.215, .61, .355, 1); // easeOutCubic
  }

  &::after {
    position: absolute;
    bottom: 0;
    display: block;
    width: 100%;
    height: 2px;
    margin-top: -2px;
    content: " ";
    transform: scaleX(0);
    transform-origin: 0 0;
    background: var(--RequestInvite-focus-color);
    transition: transform .6s cubic-bezier(.645, .045, .355, 1);
  }

  &.is-focused {
    &::after {
      transform: scaleX(1);
    }
  }
}

.RequestInvite-buttonContainer {
  min-width: 210px;
  height: 50px;
  transform: translateY(50px);
  opacity: 0;
  transition: transform .6s cubic-bezier(.215, .61, .355, 1), opacity .6s cubic-bezier(.215, .61, .355, 1); // easeOutCubic
  transition-delay: .6s;
  @include breakpoint(medium) {
    min-width: 0;
  }

  .RequestInvite-container.is-visible & {
    transform: translateY(0);
    opacity: 1;
  }
}

.RequestInvite-textLink {
  display: block;
  margin-top: 30px;
  color: $mt-frost-blue;
  transition: color 150ms ease-in-out;

  @include breakpoint(medium) {
    margin-top: 25px;
  }

  &:hover {
    color: $mt-electric-purple;
    transition-duration: 0ms;
  }
}

.RequestInvite-textLinkLabel {
  @include font-futura-demi();

  font-size: rem-calc(16);
  line-height: unitless-calc(22, 16);
  opacity: 0;
  transition: opacity .6s cubic-bezier(.215, .61, .355, 1); // easeOutCubic
  transition-delay: 1.1s;

  @include breakpoint(medium) {
    font-size: rem-calc(18);
    line-height: unitless-calc(25, 18);
  }

  .RequestInvite-container.is-visible & {
    opacity: 1;
  }
}

.RequestInvite-textLinkIcon {
  width: 12px;
  height: 12px;
  padding-left: 6px;
  transform: translateY(1px);
  opacity: 0;
  transition: opacity .6s cubic-bezier(.215, .61, .355, 1); // easeOutCubic
  transition-delay: 1.1s;

  @include breakpoint(medium) {
    width: 14px;
    height: 14px;
    padding-left: 8px;
    transform: translateY(1px);
  }

  .RequestInvite-container.is-visible & {
    opacity: 1;
  }
}

//
// HomeHeader
//
.RequestInvite.RequestInvite--homeHeader {
  max-width: 530px;

  --RequestInvite-focus-color: #{$white};
  --RequestInvite-color: #{$mt-electric-purple};
}

.RequestInvite.RequestInvite--homeHeader .RequestInvite-inner {
  justify-content: flex-start;

  @include breakpoint(small down) {
    align-items: flex-start;
  }
}

.RequestInvite.RequestInvite--homeHeader .RequestInvite-inputContainer {
  flex: 1;
}

.RequestInvite.RequestInvite--homeHeader .RequestInvite-buttonContainer {
  // min-width: 100%;

  // @include breakpoint(medium) {
  //   min-width: 0;
  // }
}

//
// Color Modifiers
//
.RequestInvite.RequestInvite--white {
  --RequestInvite-focus-color: #{$white};
  --RequestInvite-color: #{$mt-electric-purple};
}

.RequestInvite.RequestInvite--deepPurple {
  --RequestInvite-focus-color: #{$mt-deep-purple};
}

.RequestInvite.RequestInvite--electricPurple {
  --RequestInvite-focus-color: #{$white};
  --RequestInvite-color: #{$mt-electric-purple};
}

.CallToAction--white .RequestInvite.RequestInvite--electricPurple {
  --RequestInvite-focus-color: #{$mt-deep-purple};
}
