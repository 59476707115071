.OgImage {
  width: 100vw;
  height: 100vh;
  background-color: $white;
}

//
// Color Modifiers
//
.OgImage.OgImage--home {
  --OgImage-backgroundColor: #{$mt-deep-purple};
  --OgImage-textColor: #{$mt-frost-blue};
}

.OgImage.OgImage--community {
  --OgImage-backgroundColor: #{$mt-tolopea-purple};
  --OgImage-textColor: #{$mt-frost-blue};
}

// playbook variations
.OgImage.PlaybookHeader--electricPurple {
  --OgImage-backgroundColor: #{$mt-electric-purple};
  --OgImage-textColor: #{$white};
  --OgImage-textColor-hover: #{$mt-frost-blue};
}

.OgImage.PlaybookHeader--electricPurple.PlaybookHeader--figureOne {
  --OgImageFigure-colorOne: #{$mt-heart-purple};
  --OgImageFigure-colorTwo: #{$mt-violet-purple};
  --OgImageFigure-colorThree: #{$mt-indigo-purple};
  --OgImageFigure-colorFour: #{$mt-violet-purple};
}

.OgImage.PlaybookHeader--electricPurple.PlaybookHeader--figureTwo {
  --OgImageFigure-colorOne: #{$mt-heart-purple};
  --OgImageFigure-colorTwo: #{$mt-violet-purple};
  --OgImageFigure-colorThree: #{$mt-indigo-purple};
  --OgImageFigure-colorFour: #{$mt-violet-purple};
  --OgImageFigure-colorFive: #{$mt-indigo-purple};
}

.OgImage.PlaybookHeader--electricPurple.PlaybookHeader--figureThree {
  --OgImageFigure-colorOne: #{$mt-heart-purple};
  --OgImageFigure-colorTwo: #{$mt-violet-purple};
  --OgImageFigure-colorThree: #{$mt-indigo-purple};
  --OgImageFigure-colorFour: #{$mt-violet-purple};
}

.OgImage.PlaybookHeader--deepPurple {
  --OgImage-backgroundColor: #{$mt-deep-purple};
  --OgImage-textColor: #{$mt-frost-blue};
  --OgImage-textColor-hover: #{$mt-electric-purple};
}

.OgImage.PlaybookHeader--deepPurple.PlaybookHeader--figureOne {
  --OgImageFigure-colorOne: #{$mt-seance-purple};
  --OgImageFigure-colorTwo: #{$mt-electric-purple};
  --OgImageFigure-colorThree: #{$mt-violet-purple};
  --OgImageFigure-colorFour: #{$mt-indigo-purple};
}

.OgImage.PlaybookHeader--deepPurple.PlaybookHeader--figureTwo {
  --OgImageFigure-colorOne: #{$mt-muave-purple};
  --OgImageFigure-colorTwo: #{$mt-electric-purple};
  --OgImageFigure-colorThree: #{$mt-violet-purple};
  --OgImageFigure-colorFour: #{$mt-violet-purple};
  --OgImageFigure-colorFive: #{$mt-indigo-purple};
}

.OgImage.PlaybookHeader--deepPurple.PlaybookHeader--figureThree {
  --OgImageFigure-colorOne: #{$mt-muave-purple};
  --OgImageFigure-colorTwo: #{$mt-electric-purple};
  --OgImageFigure-colorThree: #{$mt-violet-purple};
  --OgImageFigure-colorFour: #{$mt-indigo-purple};
}

.OgImage.PlaybookHeader--frostBlue {
  --OgImage-backgroundColor: #{$mt-powder-blue};
  --OgImage-textColor: #{$mt-deep-purple};
  --OgImage-textColor-hover: #{$mt-electric-purple};
}

.OgImage.PlaybookHeader--frostBlue.PlaybookHeader--figureOne {
  --OgImageFigure-colorOne: #{$white};
  --OgImageFigure-colorTwo: #{$white};
  --OgImageFigure-colorThree: #{$mt-iceberg-blue};
  --OgImageFigure-colorFour: #{$mt-powder-blue};
}

.OgImage.PlaybookHeader--frostBlue.PlaybookHeader--figureTwo {
  --OgImageFigure-colorOne: #{$white};
  --OgImageFigure-colorTwo: #{$white};
  --OgImageFigure-colorThree: #{$mt-iceberg-blue};
  --OgImageFigure-colorFour: #{$mt-iceberg-blue};
  --OgImageFigure-colorFive: #{$mt-powder-blue};
}

.OgImage.PlaybookHeader--frostBlue.PlaybookHeader--figureThree {
  --OgImageFigure-colorOne: #{$white};
  --OgImageFigure-colorTwo: #{$white};
  --OgImageFigure-colorThree: #{$mt-iceberg-blue};
  --OgImageFigure-colorFour: #{$mt-powder-blue};
}

.OgImage-inner {
  position: relative;
  overflow: hidden;
  width: 1200px;
  height: 630px;
  background: var(--OgImage-backgroundColor);
}

.OgImage-content {
  position: relative;
  z-index: 2;
  padding-top: 100px;
  padding-left: 100px;
  color: var(--OgImage-textColor);
}

.OgImage-siteLogo {
  width: 122px;
  height: 60px;
  margin-bottom: 35px;
}

.OgImage-title {
  max-width: 530px;
  margin-bottom: 50px;
  font-size: rem-calc(48);
  line-height: unitless-calc(52, 48);
}

.OgImage.OgImage--community .OgImage-title {
  max-width: 850px;
  margin-bottom: 35px;
  font-size: rem-calc(76);
  line-height: unitless-calc(70, 76);
  text-transform: uppercase;
}

.OgImage.OgImage--home .OgImage-title {
  max-width: 1000px;
  margin-bottom: 35px;
  font-size: rem-calc(86);
  line-height: unitless-calc(80, 86);
  text-transform: uppercase;
}

.OgImage-description {
  max-width: 850px;
  font-size: rem-calc(36);
  line-height: unitless-calc(45, 36);
}

.OgImage-author {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.OgImage-companyLogo {
  width: auto;
  height: 40px;
}

.OgImage-illustration {
  position: absolute;
  top: 100px;
  left: 670px;
  display: block;
  width: 640px;
}

.OgImage-figureImage {
  display: block;
  max-width: 100%;
  height: auto;
}

.OgImage-figure {
  position: relative;
  width: 100%;
  pointer-events: none;
}

.OgImage-figure::before {
  position: relative;
  display: block;
  width: 100%;
  padding-bottom: 66%;
  content: '';
}

.OgImage-figureLayer {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  transform-origin: 0 100%;
}

.OgImage-pattern {
  position: absolute;
  top: 0;
  right: 0;
  left: auto;
  width: 600px;
  height: 100%;
  opacity: 1;

  .Pattern {
    animation-duration: 0s;
  }
}
