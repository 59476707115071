.ImpactBlock {

  @include breakpoint(medium) {
    @include xy-grid-container();

    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-row-gap: 0;
    grid-column-gap: 40px;
    margin-bottom: 80px;
  }
}

.ImpactBlock-inner {
  margin: 100px 0 0;
  background-color: $mt-frost-blue;
  @include breakpoint(small down) {
    @include xy-grid-container();
  }

  @include breakpoint(medium) {
    grid-area: 1/1/2/13;
    margin: 135px 0 40px;
    border-radius: 10px;
  }

  @include breakpoint(large) {
    grid-area: 1/2/2/12;
  }
}

.ImpactBlock-wrapper {
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: repeat(12, 1fr);
  grid-row-gap: 0;
  grid-column-gap: 9px;

  @include breakpoint(medium) {
    // grid-template-columns: repeat(10, 1fr);
  }

  @include breakpoint(large) {
    grid-template-columns: repeat(10, 1fr);
    grid-column-gap: 40px;
  }
}

.ImpactBlock-content {
  grid-area: 1/1/2/13;

  @include breakpoint(medium) {
    grid-area: 1/2/2/12;
    text-align: center;
  }

  @include breakpoint(large) {
    grid-area: 1/3/2/9;
  }
}

.ImpactBlock-figure {
  width: 240px;
  height: 140px;
  margin: 35px auto 40px;

  @include breakpoint(large) {
    width: 390px;
    height: 220px;
    margin-top: -55px;
    margin-bottom: 75px;
  }
}

.ImpactBlock-svg {
  width: 100%;
  height: 100%;
}

.ImpactBlock-title {
  margin-top: 40px;
  margin-bottom: 20px;
  font-size: rem-calc(32);
  line-height: unitless-calc(44, 32);
  text-transform: uppercase;
  color: $mt-deep-purple;

  @include breakpoint(large) {
    margin-top: 90px;
    margin-bottom: 25px;
    font-size: rem-calc(60);
  }
}

.ImpactBlock-anchor {
  position: relative;
  display: block;
  width: 0;
  height: 140px;
  margin-top: -140px;
  content: '';
}

.ImpactBlock-copy {
  margin-bottom: 40px;
  font-size: rem-calc(16);
  line-height: unitless-calc(24, 16);
  color: $mt-deep-purple;

  @include breakpoint(large) {
    font-size: rem-calc(18);
    line-height: unitless-calc(32, 18);
  }

  p {
    margin: 0 0 25px;

    @include breakpoint(large) {
      margin: 0 0 30px;
    }
  }
}

.ImpactBlock-buttonGroup {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 25px 70px;

  @include breakpoint(medium) {
    flex-direction: row;
    padding: 10px 0 90px;
  }
}

.ImpactBlock-buttonGroup .Button:not(:first-child) {
  margin: 20px 0 0 0;
  @include breakpoint(medium) {
    margin: 0 0 0 25px;
  }
}
