.AnchorLink {
  position: absolute;
  z-index: 10;
  top: 0;
  overflow-x: hidden;
  transform: translateY(-60px);

  @include breakpoint(large) {
    transform: translateY(-70px);
  }
}
