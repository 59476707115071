html.PlaybookPdf {
  padding: 10mm 5mm 10mm 40px;
  background-color: $white !important;

  a[href] {
    &::after {
      content: " [" attr(href) "] ";
    }

    &.Button-link {
      &::after {
        display: flex;
        justify-content: center;
      }
    }
  }

  .has-scrolled {
    padding-top: 0;
  }

  // Hide components
  .Navigation,
  .Footer,
  .PlaybookLayout-aside,
  .SubscribeBlock,
  .CallToAction,
  .Carousel {
    display: none !important;
  }

  // PlaybookHeader
  .PlaybookHeader {
    height: auto;
    min-height: auto;
    max-height: none;
    margin-bottom: 0;
    -webkit-transform: none;
    transform: none;
    background-color: $white;
  }

  .PlaybookHeader-inner {
    display: flex;
    grid-column-gap: 0;
    margin: 0;
    padding: 0;
  }

  .PlaybookHeader-back {
    display: none;
  }

  .PlaybookHeader-tag {
    color: $mt-pigment-purple;
    background: transparent;
  }

  .PlaybookExcerpt {
    color: $mt-pigment-purple;
    background: transparent;
  }

  .PlaybookHeader-text {
    margin-bottom: 40px;
  }

  .PlaybookHeader-title {
    width: 530px;
    font-size: rem-calc(42);
    color: $mt-pigment-purple;
  }

  .PlaybookHeader-subTitle {
    margin-bottom: 20px;
    color: $mt-pigment-purple;
  }

  .Author--playbookHeader {
    float: left;
    margin-right: 40px;
    padding-right: 40px;
    border-right: 1px solid $mt-pigment-purple;
  }

  .Author-copy {
    color: $mt-pigment-purple;
  }

  .Avatar-image {
    border: 1px solid $mt-powder-blue !important;
    border-radius: 50px;
  }

  .PlaybookHeader-figure {
    display: none;
  }

  .PlaybookHeader-figureLogo--pdf {
    display: flex;
    align-items: center;
    height: 72px;

    .PlaybookHeader-figureLogo--frostBlue {
      display: none;
    }

    .PlaybookHeader-figureLogo--white {
      display: none;
    }

    img {
      max-height: 40px;
    }
  }

  // PlaybookLayout
  .PlaybookLayout-inner {
    margin-left: 0;
    padding: 0;
  }

  // SummaryBlock
  .SummaryBlock {
    display: block;
    min-height: 700px;
    margin: 0 !important;
  }

  .SummaryBlock-inner {
    border: none;
    border-top: 1px solid $mt-powder-blue;
    border-radius: 0;
    background: none;
  }

  .SummaryBlock-section {
    padding-left: 0;
  }

  .SummaryBlock-title {
    font-size: rem-calc(18);
  }

  .SummaryBlock-label {
    font-size: rem-calc(16);
  }

  .SummaryBlock-list {
    padding-left: 40px;
  }

  .SummaryBlock-bullet {
    width: 18px;
    height: 18px;
    border-color: $mt-pigment-purple;
  }

  .SummaryBlock-bulletIcon {
    width: 10px;
    height: 10px;
    color: $mt-pigment-purple;
  }

  .SummaryBlock-buttonGroup {
    display: none;
  }

  // PlaybookLayout
  .PlaybookLayout-main {
    grid-area: 1/1/2/13 !important;
  }

  // Chapters
  .Chapter {
    padding-left: 40px;
  }

  .Chapter-paragraph {
    font-size: rem-calc(16);
    page-break-inside: avoid;
  }

  .ImageBlock-figure {
    display: none;
  }

  .Chapter-subheading {
    page-break-inside: avoid;
  }

  .ImageBlock-image {
    display: none;
  }

  .ImageBlock-image--pdf {
    display: block;
    width: calc(100% - 120px);
  }

  .Chapter-inner {
    display: block;
    margin-top: 20px;
    margin-bottom: 20px;
    page-break-inside: avoid;
  }

  .CarouselItem--pdf {
    display: block;
    margin-bottom: 40px;
    page-break-inside: avoid;

    img {
      width: calc(100% - 120px);
    }
  }

  .Carousel-slideLabel {
    margin-bottom: 20px;
    color: $mt-deep-purple;
  }

  // ImpactBlock
  .ImpactBlock {
    display: block !important;
    margin-left: 0;
    padding: 0;
    page-break-inside: avoid;

    .Button {
      display: none;
    }
  }

  .ImpactBlock-wrapper {
    display: block;
  }

  .ImpactBlock-inner {
    margin-top: 40px;
    background: none;
  }

  .ImpactBlock-figure {
    display: none;
  }

  .ImpactBlock-copy {
    font-size: rem-calc(16);
  }

  .ImpactBlock-content {
    text-align: left;
  }

  .ImpactBlock-title {
    font-size: rem-calc(44);
    text-align: left;
  }
}
