.Wysiwyg {
  margin-bottom: 40px;
  color: $mt-deep-purple;

  @include breakpoint(large) {
    margin-bottom: 80px;
  }

  h1,
  h2,
  h3 {
    margin-bottom: 20px;
    padding-top: 20px;

    @include breakpoint(large) {
      margin-bottom: 40px;
    }

    &:first-child {
      padding-top: 0;
    }
  }

  h1 {
    font-size: rem-calc(60);
    line-height: unitless-calc(62, 60);
  }

  h2 {
    font-size: rem-calc(36);
    line-height: unitless-calc(38, 36);
  }

  h3 {
    font-size: rem-calc(28);
    line-height: unitless-calc(30, 28);
  }

  ul {
    margin-bottom: 15px;

    @include breakpoint(large) {
      margin-bottom: 30px;
    }
  }

  ul li {
    position: relative;
    margin-bottom: 30px;
    padding-left: 30px;

    &:last-child {
      margin-bottom: 0;
    }

    &::before {
      position: absolute;
      top: 0;
      left: 0;
      display: flex;
      content: '\2022';
      font-size: 1.5rem;
      line-height: 16px;
      color: $mt-electric-purple;

      @include breakpoint(large) {
        font-size: 2rem;
        line-height: 32px;
      }
    }
  }

  ul li,
  p {
    overflow-wrap: break-word;
    line-height: unitless-calc(24, 16);

    @include breakpoint(large) {
      font-size: rem-calc(20);
      line-height: unitless-calc(32, 20);
    }
  }

  a {
    color: $mt-electric-purple;

    &:hover {
      text-decoration: underline;
    }
  }
}

.Wysiwyg-inner {
  @include xy-grid-container();

  @include breakpoint(large) {
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: repeat(12, 1fr);
    grid-row-gap: 0;
    grid-column-gap: 40px;
  }
}

.Wysiwyg-text {
  @include breakpoint(large) {
    grid-area: 1 / 3 / 2 / 10;
  }
}
