.CaseHeader {
  @include xy-grid-container();

  padding-top: 20px;
  padding-bottom: 30px;
  color: $mt-deep-purple;

  @include breakpoint(large) {
    display: grid;
    grid-template-rows: 1fr auto;
    grid-template-columns: repeat(12, 1fr);
    grid-row-gap: 0;
    grid-column-gap: 40px;
    padding-top: 60px;
    padding-bottom: 40px;
  }
}

.CaseHeader-back {
  display: flex;
  align-items: center;
  padding: 20px 0;
  font-size: 0;

  @include breakpoint(large) {
    grid-area: 1 / 1 / 2 / 3;
    align-items: flex-start;
  }
}

.CaseHeader-title {
  margin-bottom: 20px;
  font-size: rem-calc(40);
  line-height: unitless-calc(34, 40);
  text-transform: uppercase;

  // The hyphens only works for English unfortunately
  // Note: The lang attribute also needs to be set for this work correctly
  // More: https://developer.mozilla.org/en-US/docs/Web/CSS/hyphens
  @include breakpoint(small down) {
    overflow-wrap: break-word;
    hyphens: auto;
  }

  @include breakpoint(large) {
    font-size: rem-calc(60);
  }
  @include breakpoint(xlarge) {
    margin-bottom: 50px;
    font-size: rem-calc(60);
  }

  &::selection {
    color: $mt-frost-blue;
    background-color: rgba($mt-electric-purple, .99);
  }
}

.CaseHeader-text {
  @include breakpoint(large) {
    grid-area: 1 / 3 / 2 / 11;
  }
}

.CaseHeader-description {
  font-family: $font-colfax-bold;
  font-size: rem-calc(16);
  line-height: unitless-calc(24, 16);

  a {
    color: $mt-electric-purple;

    &:hover {
      text-decoration: underline;
    }
  }

  @include breakpoint(large) {
    font-size: rem-calc(20);
    line-height: unitless-calc(32, 20);
  }
}

.CaseHeader-imageContainer {
  margin: 0 -30px 0;
  font-size: 0;

  @include breakpoint(large) {
    grid-area: 2 / 3 / 3 / 11;
    margin: 30px 0 0 0;
  }
}

.CaseHeader-image {
  width: 100%;
}
