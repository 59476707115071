.ImageBlock {
  display: block;
  margin-bottom: 40px;

  @include breakpoint(large) {
    margin-bottom: 60px;
  }
}

.ImageBlock-figure {
  display: block;
  margin: 0 calc(#{$gutter-small} * -1);
  padding: #{$gutter-small} 0 0 #{$gutter-small};
  background-color: $mt-lily-white;

  @include breakpoint(large) {
    margin: 0 calc(#{$gutter-large} * -1) 0 0;
    padding: #{$gutter-large} 0 0 #{$gutter-large};
  }

  @include breakpoint(xlarge) {
    width: calc(((100% + ((((100% - (6 * #{$gutter-large})) / 7) * 2) + (2 * #{$gutter-large}))) + #{$gutter-large}) - ((((100% - (6 * #{$gutter-large})) / 7) + #{$gutter-large})));
    margin: 0;
    padding: #{$gutter-large} 0 0 calc((((100% - (6 * #{$gutter-large})) / 7) + #{$gutter-large}));
  }
  @include breakpoint(1360px) {
    width: calc(((100% + ((((100% - (6 * #{$gutter-large})) / 7) * 2) + (2 * #{$gutter-large}))) + ((100vw - #{$global-width}) / 2)) - ((((100% - (6 * #{$gutter-large})) / 7) + #{$gutter-large})));
    padding: #{$gutter-large} 0 0 calc((((100% - (6 * #{$gutter-large})) / 7) + #{$gutter-large}));
  }
}

.ImageBlock-image {
  display: flex;
  width: 100%;
  height: auto;
}

.ImageBlock-caption {
  margin: 30px 0 24px;
  font-size: rem-calc(16);
  font-style: italic;
  line-height: unitless-calc(24, 16);
  color: $mt-deep-purple;

  @include breakpoint(large) {
    margin: 40px 0 32px;
  }
}
