.PlaybookCard {
  display: flex;
  width: 100%;
  text-align: left;
  border-radius: 7px;
  background: var(--Playbook-backgroundColor);
}

.PlaybookCard-inner {
  display: flex;
  flex-direction: column;
  padding: 40px 12.5%;

  @include breakpoint(medium only) {
    padding: 30px 12.5%;
  }
}

.PlaybookCard-header {
  display: flex;
  justify-content: flex-start;
  margin: 0 0 20px;
}

.PlaybookCard-tag {
  @include font-futura-demi();

  display: inline-flex;
  margin: 0 7px auto 0;
  padding: 2px 5px 2px;
  font-size: rem-calc(11);
  line-height: unitless-calc(16, 11);
  color: var(--Playbook-backgroundColor);
  border-radius: 4px;
  background-color: var(--Playbook-textColor);
}

.PlaybookCard-title {
  margin-bottom: 18px;
  font-size: rem-calc(22);
  line-height: unitless-calc(22, 22);
  color: var(--Playbook-textColor);
}

.PlaybookCard-figure {
  position: relative;
  width: 100%;
  height: auto;
  margin-top: auto;
  pointer-events: none;
}

.PlaybookCard-figure::before {
  position: relative;
  display: block;
  width: 100%;
  padding-bottom: 66%;
  content: '';
}

.PlaybookCard-figureLayer {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  transform-origin: 0 100%;
  transition: transform .5s cubic-bezier(.165, .84, .44, 1); // easeOutQuart
  will-change: transform;
}

.PlaybookFigure-colorOne {
  fill: var(--PlaybookFigure-colorOne);
}

.PlaybookFigure-colorTwo {
  fill: var(--PlaybookFigure-colorTwo);
}

.PlaybookFigure-colorThree {
  fill: var(--PlaybookFigure-colorThree);
}

.PlaybookFigure-colorFour {
  fill: var(--PlaybookFigure-colorFour);
}

.PlaybookFigure-colorFive {
  fill: var(--PlaybookFigure-colorFive);
}

.PlaybookCard-figureLayer:nth-child(1) {
  transition-delay: .05s;
}

.PlaybookCard-figureLayer:nth-child(2) {
  transition-delay: .07s;
}

.PlaybookCard-figureLayer:nth-child(3) {
  transition-delay: .09s;
}

.PlaybookCard-figureLogo {
  position: absolute;
  width: auto;
  height: 20%;
}

.PlaybookCard-figureImage {
  display: block;
  width: auto;
  max-width: 100%;
  height: auto;
}

//
// Color Modifiers
//
.PlaybookCard--electricPurple {
  --Playbook-backgroundColor: #{$mt-electric-purple};
  --Playbook-textColor: #{$white};
  --Playbook-textColor-hover: #{$mt-frost-blue};
}

.PlaybookCard--electricPurple.PlaybookCard--figureOne {
  --PlaybookFigure-colorOne: #{$mt-heart-purple};
  --PlaybookFigure-colorTwo: #{$mt-violet-purple};
  --PlaybookFigure-colorThree: #{$mt-indigo-purple};
  --PlaybookFigure-colorFour: #{$mt-violet-purple};
}

.PlaybookCard--electricPurple.PlaybookCard--figureTwo {
  --PlaybookFigure-colorOne: #{$mt-heart-purple};
  --PlaybookFigure-colorTwo: #{$mt-violet-purple};
  --PlaybookFigure-colorThree: #{$mt-indigo-purple};
  --PlaybookFigure-colorFour: #{$mt-violet-purple};
  --PlaybookFigure-colorFive: #{$mt-indigo-purple};
}

.PlaybookCard--electricPurple.PlaybookCard--figureThree {
  --PlaybookFigure-colorOne: #{$mt-heart-purple};
  --PlaybookFigure-colorTwo: #{$mt-violet-purple};
  --PlaybookFigure-colorThree: #{$mt-indigo-purple};
  --PlaybookFigure-colorFour: #{$mt-violet-purple};
}

.PlaybookCard--deepPurple {
  --Playbook-backgroundColor: #{$mt-deep-purple};
  --Playbook-textColor: #{$mt-frost-blue};
  --Playbook-textColor-hover: #{$mt-electric-purple};
}

.PlaybookCard--deepPurple.PlaybookCard--figureOne {
  --PlaybookFigure-colorOne: #{$mt-seance-purple};
  --PlaybookFigure-colorTwo: #{$mt-electric-purple};
  --PlaybookFigure-colorThree: #{$mt-violet-purple};
  --PlaybookFigure-colorFour: #{$mt-indigo-purple};
}

.PlaybookCard--deepPurple.PlaybookCard--figureTwo {
  --PlaybookFigure-colorOne: #{$mt-muave-purple};
  --PlaybookFigure-colorTwo: #{$mt-electric-purple};
  --PlaybookFigure-colorThree: #{$mt-violet-purple};
  --PlaybookFigure-colorFour: #{$mt-violet-purple};
  --PlaybookFigure-colorFive: #{$mt-indigo-purple};
}

.PlaybookCard--deepPurple.PlaybookCard--figureThree {
  --PlaybookFigure-colorOne: #{$mt-muave-purple};
  --PlaybookFigure-colorTwo: #{$mt-electric-purple};
  --PlaybookFigure-colorThree: #{$mt-violet-purple};
  --PlaybookFigure-colorFour: #{$mt-indigo-purple};
}

.PlaybookCard--frostBlue {
  --Playbook-backgroundColor: #{$mt-powder-blue};
  --Playbook-textColor: #{$mt-deep-purple};
  --Playbook-textColor-hover: #{$mt-electric-purple};
}

.PlaybookCard--frostBlue.PlaybookCard--figureOne {
  --PlaybookFigure-colorOne: #{$white};
  --PlaybookFigure-colorTwo: #{$white};
  --PlaybookFigure-colorThree: #{$mt-iceberg-blue};
  --PlaybookFigure-colorFour: #{$mt-powder-blue};
}

.PlaybookCard--frostBlue.PlaybookCard--figureTwo {
  --PlaybookFigure-colorOne: #{$white};
  --PlaybookFigure-colorTwo: #{$white};
  --PlaybookFigure-colorThree: #{$mt-iceberg-blue};
  --PlaybookFigure-colorFour: #{$mt-iceberg-blue};
  --PlaybookFigure-colorFive: #{$mt-powder-blue};
}

.PlaybookCard--frostBlue.PlaybookCard--figureThree {
  --PlaybookFigure-colorOne: #{$white};
  --PlaybookFigure-colorTwo: #{$white};
  --PlaybookFigure-colorThree: #{$mt-iceberg-blue};
  --PlaybookFigure-colorFour: #{$mt-powder-blue};
}

//
// Figure Type Modifiers
//
.PlaybookCard--figureOne .PlaybookCard-figureLogo {
  bottom: 6.75%;
  left: 7.5%;
}

.PlaybookCard--figureTwo .PlaybookCard-figureLogo {
  top: 6.75%;
  right: 7.5%;
}

.PlaybookCard--figureThree .PlaybookCard-figureLogo {
  right: 7.5%;
  bottom: 32.5%;
}

@media (hover: hover) {
  .PlaybookCard--figureOne:hover .PlaybookCard-figureLayer:nth-child(1) {
    transform: translateX(-65%);
  }

  .PlaybookCard--figureOne:hover .PlaybookCard-figureLayer:nth-child(2) {
    transform: translateX(-16%);
  }

  .PlaybookCard--figureOne:hover .PlaybookCard-figureLayer:nth-child(3) {
    transform: translateX(33%);
  }

  .PlaybookCard--figureTwo:hover .PlaybookCard-figureLayer:nth-child(1) {
    transform: translateX(-33%);
  }

  .PlaybookCard--figureTwo:hover .PlaybookCard-figureLayer:nth-child(2) {
    transform: translateX(34%);
  }

  .PlaybookCard--figureTwo:hover .PlaybookCard-figureLayer:nth-child(3) {
    transform: translateX(-33%);
  }

  .PlaybookCard--figureThree:hover .PlaybookCard-figureLayer:nth-child(1) {
    transform: translateX(41%);
  }

  .PlaybookCard--figureThree:hover .PlaybookCard-figureLayer:nth-child(2) {
    transform: translateX(74%);
  }

  .PlaybookCard--figureThree:hover .PlaybookCard-figureLayer:nth-child(3) {
    transform: translateX(-33%);
  }
}
