.SoundCloud {
  @include xy-grid-container();

  @include breakpoint(large) {
    display: grid;
    grid-template-rows: 1fr auto;
    grid-template-columns: repeat(12, 1fr);
    grid-row-gap: 0;
    grid-column-gap: 40px;
    margin-bottom: 50px;
  }
}

.SoundCloud-iframe {
  width: 100%;
  height: 260px;

  @include breakpoint(large) {
    grid-area: 2 / 3 / 3 / 11;
    height: 165px;
  }
}

.SoundCloud-inner {
  grid-area: 1 / 3 / 2 / 11;
}

.SoundCloud-title {
  margin-bottom: 30px;
  font-size: rem-calc(24);
  text-transform: uppercase;
  color: $mt-electric-purple;

  @include breakpoint(large) {
    display: flex;
    flex-direction: column;
  }
}
